import React, { useEffect } from 'react';
import { removeSuperAdminToken } from '../Utils/Common';

export default function Logout() {
    useEffect(() => {
        removeSuperAdminToken();
        window.location.replace('/superadmin/auth');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return ( 
        <div className="grid place-items-center h-screen">  
            <div>
                <h2 className='text-3xl 30px uppercase font-semibold'>logging out</h2>
                <h5 className='font-normal text-[#777]'>Please wait</h5>
            </div>
        </div>
    );
}
