import React, { useState} from "react";
import oceanapi, {setPassword  } from "../../Base/OceanConfig";
import Message from "../../Components/Message/Message";
import styles from "./login.module.css"
import { Navigate } from 'react-router-dom';

export default function ResetPassword(){
    const userId = sessionStorage.getItem("userId");
    const parentName =sessionStorage.getItem("parentName");
    const [ loading, setLoading ] = useState(false);
    const [ message, setMessage ] = useState('');
    const [ messageClass, setMessageClass ] = useState('');
    const [ newPassword, setNewPassword ] = useState('');
    const [ confirmPassword, setConfirmPassword ] = useState('');
    const [ showNewPassword, setShowNewPassword ] = useState(false);
    const [ showConfirmPassword, setShowConfirmPassword ] = useState(false);
    const [ passwordChanged, setPasswordChanged ] = useState(false);



    const checkPassword = (e) => {
        e.preventDefault();
        if(newPassword === confirmPassword) {
            updatePassword();
        } else {
            setMessageClass('error');
            setMessage('New Password and Confirm Password didnot match!');
            setTimeout(() => {
                setMessage('')
            }, 5000);
        }
    }

    const updatePassword = () => {
        setLoading(true);
        oceanapi.post(setPassword, {
            userId:userId,
            parentName:parentName,
            newPassword:newPassword,
            confirmNewPassword:confirmPassword
        })
        .then((res) => {
            setLoading(false);
            if(res.data.code === 200){
                setMessageClass('success');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('')
                }, 5000);
                setPasswordChanged(true);
            } else {
                setMessageClass('error');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            }
        })
        .catch((error) => {
            setLoading(false);
            console.error(error);
            setMessageClass('error');
            setMessage('Something went wrong, Try again.');
            setTimeout(() => {
                setMessage('');
            }, 5000);
        })
    }
    
    return (<>  
        {message && <Message message={message} messageClass={messageClass} closeMessage={() => setMessage('')}/>}
        {passwordChanged && <Navigate to="/auth" />}
        <div className="admin-dashboard">
            <h1 className="admin-dashboard-heading">Reset Password</h1>
            <div className="admin-dashboard-body">
                <div className={styles["reset-password-container"]}>
                    <div className={styles["reset-password-box"]}>
                        <div>
                            <form onSubmit={checkPassword}>
                                <div>
                                    <label>New Password</label>
                                    <input placeholder="Enter new password" type={showNewPassword ? 'text' : 'password'} value={newPassword} onChange={e => setNewPassword(e.target.value)} />
                                    <div className={styles['hide-show-password']}>
                                        <i className={showNewPassword ? "fa fa-eye" : "fa fa-eye-slash"} onClick={() => setShowNewPassword(!showNewPassword)}></i>
                                    </div>
                                </div>
                                <div>
                                    <label>Confirm Password</label>
                                    <input placeholder="Confirm password" type={showConfirmPassword ? 'text' : 'password'} value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} />
                                    <div className={styles['hide-show-password']}>
                                        <i className={showConfirmPassword ? "fa fa-eye" : "fa fa-eye-slash"} onClick={() => setShowConfirmPassword(!showConfirmPassword)}></i>
                                    </div>
                                </div>
                                <p className={styles['instructions']}>Password must have at least 8 characters and contain at least two of the following: uppercase letters, lowercase letters, numbers and symbols.</p>
                                <div className="mt-4">
                                    {
                                        loading
                                        ?<button className="py-2 px-4 btn" style={{backgroundColor: "#FF7D90",
                                            border: `1px solid #FF7D90`,color:"white"}} disabled>Wait...</button>
                                        :<button className="py-2 px-4 btn" style={{backgroundColor: "#FF7D90",
                                        border: `1px solid #FF7D90`,color:"white"}}>Reset Password</button>
                                    }
                                </div>
                            </form>
                        </div>
                        <div>
                            <img src="../../images/admin/resetpassword.svg" alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}