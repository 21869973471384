import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import oceanapi, { getSignupCustomization } from '../../../Base/OceanConfig';
import adminapi, { setSignupCustomization } from '../../../Base/AdminConfig';
import { getAdminUser } from '../../../Utils/Common';
import Message from '../../../Components/Message/Message';
import styles from "../my-account.module.css";

export default function TermAndConditions(props) {
    const css = props.css;
    const userId = getAdminUser();
    const userName = props.userName;
    const subdomain = props.subdomain;
    const [message, setMessage] = useState('');
    const [termCondition, setTermCondition] = useState('');
    const [forgotPassword, setForgotPassword] = useState(true);
    const [status, setStatus] = useState(true);
    const [createAccount, setCreateAccount] = useState(false);
    const [copyright, setCopyright] = useState('');
    const [id, setID] = useState("");
    const [messageClass, setMessageClass] = useState('');

    const handleSave = () => updateSignUpCustomization();

    const updateSignUpCustomization = () => {
        adminapi.post(setSignupCustomization, {
            id: id,
            userId: userId,
            userName: userName,
            subdomain: subdomain,
            termCondition: termCondition,
            status: status,
            forgotPass: forgotPassword,
            copyRight: copyright,
            createAccount: createAccount
        })
        .then((res) => {
            if(res.data.code === 200){
                setMessageClass('success');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('')
                }, 5000);
            } else {
                setMessageClass('error');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            }
        })
        .catch((error) => {
            console.error(error);
            setMessageClass('error');
            setMessage('Something went wrong, Try again.');
            setTimeout(() => {
                setMessage('');
            }, 5000);
        })
    }

    const getSignUpCustomization = () => {
		oceanapi.get(getSignupCustomization + subdomain)
		.then(res => {
			if(res.data.code === 200){
                setID(res.data.result._id)
				setStatus(res.data.result.status);
                setForgotPassword(res.data.result.forgotPass);
                setTermCondition(res.data.result.termCondition);
                setCreateAccount(res.data.result.createAccount);
                setCopyright(res.data.result.copyRight);
			}
		})
		.catch(err => {
			console.error(err);
		})
	}

    useEffect(() => {
        getSignUpCustomization();
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

    return (
        <>
            {message && <Message message={message} messageClass={messageClass} closeMessage={() => setMessage('')} />}
            <div className="my-custom-container">
                <h5 className={styles['my-account-details-heading']}>Signup Customization</h5>
                <div className='row'>
                    <div className='col-xs-12 my-2 col-md-4'>
                        <label className='font-semibold'>Show forgot password</label><br/>
                        <input type="radio" name="active" checked={forgotPassword && 'checked'} onClick={() => setForgotPassword(true)}/> Yes &nbsp; &nbsp;
                        <input type="radio" name="active" checked={!forgotPassword && 'checked'} onClick={() => setForgotPassword(false)}/> No
                    </div>
                    <div className='col-xs-12 my-2 col-md-4'>
                        <label className='font-semibold'>Status</label><br/>
                        <input type="radio" name="status" checked={status && 'checked'} onClick={() => setStatus(true)}/> Yes &nbsp; &nbsp;
                        <input type="radio" name="status" checked={!status && 'checked'} onClick={() => setStatus(false)}/> No
                    </div>
                    <div className='col-xs-12 my-2 col-md-4'>
                        <label className='font-semibold'>Signup</label><br/>
                        <input type="radio" name="createAccount" checked={createAccount && 'checked'} onClick={() => setCreateAccount(true)}/> Yes &nbsp; &nbsp;
                        <input type="radio" name="createAccount" checked={!createAccount && 'checked'} onClick={() => setCreateAccount(false)}/> No
                    </div>
                    <div className='col-xs-12 my-2 col-md-12'>
                        <label className='font-semibold'>Copyright</label><br/>
                        <input type="text" value={copyright} onChange={(e) => setCopyright(e.target.value)} placeholder="Enter copyright"/>
                    </div>
                </div>
                <label className='font-semibold'>Edit term and condition</label>
                <div>
                    <ReactQuill
                        style={{height:"420px", overflow:"scroll"}}
                        theme="snow"
                        value={termCondition}
                        onChange={(html) => setTermCondition(html)}
                    />
                </div>       
            </div>
            <br/>
            <button className="btn" style={css.primaryButton} onClick={handleSave}>Save</button>
        </>
    )
}