import React, { useState, useContext } from 'react';
import { ColorContext } from '../../Components/ColorContext/ColorContext';
import Message from '../../Components/Message/Message';
import oceanapi, { userRegistration } from "../../Base/OceanConfig";
import { getUserName } from "../../Utils/Common";
import styles from './add-user-manually.module.css';

export default function AddUserManually(){
    const userName = getUserName();
    const { css } = useContext(ColorContext);
    const [ loading, setLoading ] = useState(false);
    const [ message, setMessage ] = useState('');
    const [ messageClass, setMessageClass ] = useState('');
    const [ firstName, setFirstName ] = useState('');
    const [ lastName, setLastName ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ phoneNumber, setPhoneNumber] = useState('');
    const [ password, setPassword ] = useState('');
    const [ access, setAccess ] = useState(false);
    const [ accountCreated, setAccountCreated ] = useState(false);

    const addNewUser = () => {
        setLoading(true);
        oceanapi.post(userRegistration, {
            firstName: firstName,
            lastName: lastName,
            email: email,
            phoneNumber: phoneNumber,
            userName: userName,
            password: password,
            fullAccess: access
        })
        .then((res) => {
            setLoading(false);
            if(res.data.code === 200){
                setMessageClass('success');
                setMessage(res.data.message);
                setAccountCreated(true);
                setTimeout(() => {
                    setMessage('')
                }, 5000);
            } else {
                setMessageClass('error');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            }
        })
        .catch((error) => {
            setLoading(false);
            console.error(error);
            setMessageClass('error');
            setMessage('Something went wrong, Try again.');
            setTimeout(() => {
                setMessage('');
            }, 5000);
        })
    }

    function Validate(e) {
        e.preventDefault();
        //letters for Valid Characters i.e. Alphabets, Numbers and Space.
        var letters = /^[A-Za-z ]+$/
        var numbers = /^[0-9]+$/

        if(!firstName.match(letters)) {
            setMessage('No special character & numbers allowed in first name');
            setMessageClass('error');
            setTimeout(() => {
                setMessage('');
            }, 10000)
        } else if (!lastName.match(letters)) {
            setMessage('No special character & numbers allowed in last name');
            setMessageClass('error');
            setTimeout(() => {
                setMessage('');
            }, 10000)
        } else if(!phoneNumber.match(numbers)){
            setMessage('No special character & alphabets allowed in phone number.');
            setMessageClass('error');
            setTimeout(() => {
                setMessage('');
            }, 10000)
        } else {
            addNewUser();
        }
    }

    
    return (<>
        {message && <Message message={message} messageClass={messageClass} closeMessage={() => setMessage('')} />}
        {accountCreated && <div className="modal-container">
            <div className="modal-background"></div>
            <div className="modal-box">
                <div className={styles['account-created']}>
                    <i className="fa fa-user-circle-o" style={css.color}></i>
                    <h5>Account created successfully</h5>
                    <hr/>
                    <p>Mail has been sent to user for confirmation</p>
                    <button className="btn" style={css.primaryButton} onClick={() => setAccountCreated(false)}>Okay</button>
                </div>
            </div>
        </div>}
        <div className="admin-dashboard">
            <h1 className="admin-dashboard-heading">Add User Manually</h1>
            <div className="admin-dashboard-body">
                <div className={styles['add-user-manually-container']}>
                    <h5>Add User</h5>
                    <form onSubmit={Validate}> 
                        <div className={styles['add-user-manually-content']}>
                            <div>
                                <label className='font-barlow'>First Name</label>
                                <input type='text' placeholder='Enter first name' value={firstName} onChange={e => setFirstName(e.target.value)}  required/>
                            </div>
                            <div>
                                <label className='font-barlow'>Last Name</label>
                                <input type='text' placeholder='Enter last name' value={lastName} onChange={e => setLastName(e.target.value)} required/>
                            </div>
                            <div>
                                <label className='font-barlow'>Phone Number</label> 
                                <input type='text' placeholder='Enter phone number' value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} required/>
                            </div>
                            <div>
                                <label className='font-barlow'>E-mail</label> 
                                <input type='email' placeholder='Enter email' value={email} onChange={e => setEmail(e.target.value)} required />
                            </div>
                            <div>
                                <label className='font-barlow'>Password</label> 
                                <input type='password' autoComplete='new-password'  placeholder='Enter password' value={password} onChange={e => setPassword(e.target.value)}required />
                            </div>
                        </div>
                        <div className={styles['decide-access']}>
                            <h5>Access Control</h5>
                            <div>
                                <div>
                                    <input type="radio" name="decide_access" value={false} onChange={e => setAccess(e.target.value)} checked /> <label>Limited Access</label>
                                </div>  
                                <div>
                                    <input type="radio" name="decide_access" value={true} onChange={e => setAccess(e.target.value)} /> <label>Full Access</label>
                                </div>
                            </div>
                        </div>
                        <div>
                            {
                                loading
                                ?<button className="btn px-10 py-2" style={css.primaryButton} disabled>Wait...</button>
                                :<button className="btn px-10 py-2" style={css.primaryButton}>Create Account</button>
                            }
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>);
}