import React, { useState, useContext, useEffect } from 'react';
import { ColorContext } from '../../Components/ColorContext/ColorContext';
import APITransactions from '../MyTransactions/Components/APITransactions';
import ProductTransactions from '../MyTransactions/Components/ProductTransactions';
import adminapi, { getDefaultPrices } from '../../Base/AdminConfig';
import styles from './mytransactions.module.css';

export default function OverallTransactions() {
    const { css } = useContext(ColorContext);
    const [ tabName, setTabName ] = useState('api-transactions');
    const [ list, setList ] = useState([]);

    const changeTab = (x) => setTabName(x);

    const fetchDefaultFunction = () => {
        adminapi.get(getDefaultPrices)
        .then((res) => {
            if(res.data.code === 200){
                setList(res.data.result);
            } else {
                setList([]);
            }
        })
        .catch((error) => {
            console.error(error);
        })
    }

    useEffect(()=>{
        fetchDefaultFunction();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="admin-dashboard">
            <h1 className="admin-dashboard-heading">My Transactions</h1>
            <div className="admin-dashboard-body">
                <ul className={styles['transaction-tabs']}>
                    <li style={tabName === 'api-transactions' ? css.active : null} onClick={() => changeTab('api-transactions')}>API transactions</li>
                    <li style={tabName === 'product-transactions' ? css.active : null} onClick={() => changeTab('product-transactions')}>Product transactions</li>
                </ul>
                {tabName === 'api-transactions' && <APITransactions list={list} />}
                {tabName === 'product-transactions' && <ProductTransactions list={list}  />}
            </div>
        </div>
    );
}