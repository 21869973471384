import React, { useState } from "react";

export default function AddNewService(props) {
  const [planType, setPlanType] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [price, setPrice] = useState("");

  const handleInputChange = (e) => {
    if (e.target.name === "planType") setPlanType(e.target.value);
    if (e.target.name === "serviceName") setServiceName(e.target.value);
    if (e.target.name === "price") setPrice(e.target.value);
  };
  return (
    <>
      <div className="absolute left-0 top-0 w-full h-screen grid justify-items-center items-center font-['barlow']">
        <div
          className="absolute left-0 top-0 bg-[#00000060] z-[999] w-full h-screen"
          data-testid="background"
          onClick={() => props.popupController()}
        ></div>
        <div className="text-center absolute z-[9999999999] bg-white p-8 w-[1000px] max-[1200px]:w-[700px] max-[1200px]:p-4 max-md:w-full max-md:p-2 text-center grid grid-cols-1 justify-items-center">
          <div className="flex justify-between w-full">
            <h5 className="text-[#1C2347] font-[600] text-[24px] leading-[29px] pb-4">
              Add New Service
            </h5>
            <i
              onClick={() => props.popupController()}
              className="fa fa-times-circle text-[32px] text-[#C7C7C7] cursor-pointer"
            ></i>
          </div>
          <div className="w-full text-left grid pb-2">
            <label className="text-[#1C2347] text-[12px] font-[500] leading-[14px] pb-1">
              Plan Type&nbsp;<span className="text-red-500">*</span>
            </label>
            <select
              name="planType"
              value={planType}
              className="outline-none border-b-[1px] border-b-[#CFD3D4]"
              onChange={(e) => handleInputChange(e)}
            >
              <option value="" disabled selected>
                Select Plan Type
              </option>
              <option value="default">Default</option>
              <option value="standard">Standard</option>
              <option value="premium">Premium</option>
              <option value="pro">Pro</option>
            </select>
            <div className="w-full text-left grid pb-2">
              <label className="text-[#1C2347] text-[12px] font-[500] leading-[14px] pb-1">
                Service Name&nbsp;<span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="serviceName"
                placeholder="Enter Service Name (Ex: apiKycVehicleRcV6)"
                className="outline-none border-b-[1px] border-b-[#CFD3D4]"
                onChange={(e) => handleInputChange(e)}
              ></input>
            </div>
            <div className="w-full text-left grid pb-2">
              <label className="text-[#1C2347] text-[12px] font-[500] leading-[14px] pb-1">
                Price&nbsp;<span className="text-red-500">*</span>
              </label>
              <input
                type="number"
                name="price"
                placeholder="Enter Price (Ex: 2.6)"
                className="outline-none border-b-[1px] border-b-[#CFD3D4]"
                onChange={(e) => handleInputChange(e)}
              ></input>
            </div>
            <div>
              <button
                onClick={() => props.ammendPlans(planType, serviceName, price)}
                className="btn primary-button"
              >
                Add Service
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
