import React from "react";

const FocusCardSecondary = ({ imgLink, cardHeading, cardContent }) => {
  return (
    <div className="px-4 pt-4 pb-2 h-full  w-64 bg-white  rounded-md border border-focusCardBorderGrey">
      <div className="">
        <img src={imgLink} alt="" />
      </div>
      <p className="text-base text-secondaryColor font-semibold mt-8 mb-2">
        {cardHeading}
      </p>
      <p className="text-base text-tickListGreyColor font-medium">{cardContent}</p>{" "}
    </div>
  );
};

export default FocusCardSecondary;
