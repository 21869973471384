import React, { useState, useEffect, useRef } from 'react';
import jwt_decode from "jwt-decode";
import styles from "./login.module.css";
import Message from '../../Components/Message/Message';
import ReCAPTCHA from "react-google-recaptcha";
import oceanapi, { loginAdmin, fetchThemeDetails, mailSent } from "../../Base/OceanConfig";
import { setAdminToken } from '../../Utils/Common';

export default function Login() {
    const captchaRef = useRef(null);
    const [year, setYear] = useState('');
    const [email, setEmail] = useState(window.location.host === 'localhost:3000' ? 'naresh@emptra.com' : '');
    const [password, setPassword] = useState(window.location.host === 'localhost:3000' ? 'Code$123123' : '');
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [messageClass, setMessageClass] = useState('');
    const [forgotPassword, setForgotPassword] = useState(false);
    const [recoverEmail, setRecoverEmail] = useState('');
    const [parentName, setParentName] = useState('');
    const [extraction, setExtraction] = useState(false);
    const saveUserName = (x) => sessionStorage.setItem("userName", x);
    const saveSubdomain = (x) => sessionStorage.setItem("subdomain", x);

    useEffect(() => {
        if (sessionStorage.getItem('auth')) {
            window.location.replace('/dashboard');
        } else {
            const d = new Date();
            let year = d.getFullYear();
            setYear(year);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const loginFunction = async (e) => {
        e.preventDefault();
        setLoading(true);
        const token = captchaRef.current.getValue();
        oceanapi.post(loginAdmin, {
            email: email,
            password: password,
            recaptchaToken: token
        })
        .then((res) => {
            if (res.data.code === 200) {
                setLoading(false);
                setMessageClass('success');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('');
                }, 5000);
                setAdminToken(res.data.token);
                if (jwt_decode(res.data.token).subdomain) {
                    themeFunction(jwt_decode(res.data.token));
                } else {
                    saveUserName(jwt_decode(res.data.token).userName);
                    saveSubdomain(jwt_decode(res.data.token).subdomain);
                    window.location.replace('/complete-details');
                }
            } else {
                setLoading(false);
                setMessageClass('error');
                captchaRef.current.reset();
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            }
        })
        .catch((error) => {
            setLoading(false);
            captchaRef.current.reset();
            console.error(error);
            setMessageClass('error');
            setMessage('Something went wrong, Try again.');
            setTimeout(() => {
                setMessage('');
            }, 5000);
        })
    };

    const themeFunction = (x) => {
        oceanapi.get(fetchThemeDetails + x.subdomain)
        .then(res => {
            if (res.data.code === 200) {
                window.location.replace('/dashboard');
            } else {
                saveUserName(x.userName);
                saveSubdomain(x.subdomain);
                window.location.replace('/complete-details');
            }
        })
        .catch(err => {
            console.error(err);
        })
    }

    const passwordToggle = () => setShowPassword(!showPassword);

    const sentMail = (e) => {
        e.preventDefault();
        setLoading(true);
        oceanapi.post(mailSent, {
            email: recoverEmail,
            parentName: parentName
        })
        .then(res => {
            if (res.data.code === 200) {
                setLoading(false);
                setMessageClass('success');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('')
                }, 5000);
                setRecoverEmail('')
                setForgotPassword(false);
                setExtraction(true);
            } else {
                setLoading(false);
                setMessageClass('error');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            }
        }).catch(error => {
            setLoading(false);
            setMessageClass('error');
            console.log(error)
            setMessage("Something went wrong. try again later")
            setTimeout(() => {
                setMessage('')
            }, 5000);
        })
    }

    return (<>
        {message && <Message message={message} messageClass={messageClass} closeMessage={() => setMessage('')} />}
        {extraction && <div className={styles['custom-container']}>
            <div className={styles['custom-background']} onClick={() => setExtraction(false)}></div>
            <div className={styles['pop-up-form-custom']}>
                <img src='./images/verify-email.png' alt="extraction" />
                <h4>Verify email !</h4>
                <p>We will send you a link to verify your email address, click on it to confirm </p>
            </div>
        </div>}
        {forgotPassword && <div className="modal-container">
            <div className="modal-background" onClick={() => setForgotPassword(false)}></div>
            <div className="modal-box">
                <form className={styles["export-box"]} onSubmit={sentMail}>
                    {/* <div className={styles["back-btn"]}><i className="fa fa-arrow-left"><span onClick={()=>setForgotPassword(false)}> Back </span></i></div> */}
                    <div className={styles["back-btn"]} style={{ color: "#FF7D90" }} onClick={() => setForgotPassword(false)}><i className="fa fa-window-close" aria-hidden="true"></i></div>
                    <h4>Forgot Password?</h4>
                    <br />
                    <h4>Enter the email address associate with your account.</h4>
                    <p>We will email you a link to reset your password.</p>
                    <label>Email</label>
                    <input type="email" placeholder="Enter email" value={recoverEmail} onChange={e => setRecoverEmail(e.target.value)} onMouseEnter={e => setRecoverEmail(e.target.value.toLowerCase())} required />
                    <label>Username</label>
                    <input type="text" placeholder="Enter username" value={parentName} onChange={e => setParentName(e.target.value)} onMouseEnter={e => setParentName(e.target.value)} required />
                    {
                        loading
                        ? <button className="btn primary-button w-100 mt-3" style={{ backgroundColor: "#FF7D90", border: "1px solid #FF7D90", color: '#FFF' }} disabled> Wait... </button>
                        : <button type="submit" className="btn primary-button w-100 mt-3" style={{ backgroundColor: "#FF7D90", border: "1px solid #FF7D90", color: '#FFF' }}>Send</button>
                    }
                </form>
            </div>
        </div>}
        <div className={styles['admin-login-page']}>
            <div>
                <h1>AI-Driven Onboarding Solution</h1>
                <img src='../images/login-image.png' alt='logo-invincible' />
                <p>“Get your own customised ready to use whitelabel product in seconds”</p>
            </div>
            <div className={styles['admin-login-box']}>
                {/* <img src='../images/logo-dark.png' alt='logo-invincible' /> */}
                <div className={styles['login-form']}>
                    <p><span>Log in</span> to admin account</p>
                    <form onSubmit={loginFunction}>
                        <label>Email</label>
                        <input type="email" placeholder="Enter user name" value={email} onChange={e => setEmail(e.target.value)} />
                        <label>Password</label>
                        <input type={showPassword ? 'text' : 'password'} placeholder="password" value={password} onChange={e => setPassword(e.target.value)} />
                        <div className={styles['password-eye']}>
                            <i className={showPassword ? "fa fa-eye" : "fa fa-eye-slash"} onClick={() => passwordToggle()}></i>
                        </div>
                        <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY} ref={captchaRef} />
                        <div className={styles['terms-conditions']}>
                            <input type="checkbox" required /> I agree to <a target="_blank" rel="noreferrer" href="https://invincibleocean.com/terms-cancellation-policy/">terms & conditions</a>
                            <span className={styles["forgot-password"]} onClick={() => setForgotPassword(true)}>Forgot password</span>
                        </div>
                        {
                            loading
                            ? <button className="btn primary-button w-100" disabled>Wait ...</button>
                            : <button className="btn primary-button w-100">Login</button>
                        }
                    </form>
                    <div className={styles['all-right-reserved']}>
                        <p>© {year} Invincible Ocean Private Limited | All rights reserved</p>
                    </div>
                </div>
                <div className={styles['brand-logos']}>
                    <img src='../images/brands/dasweltauto.svg' alt='dasweltauto' />
                    <img src='../images/brands/droom.png' alt='droom' />
                    <img src='../images/brands/gromo.png' alt='gromo' />
                    <img src='../images/brands/tata.png' alt='tata' />
                </div>
            </div>
        </div>
    </>);
}