import React, { useContext, useState } from 'react'
import styles from './sidebar.module.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ColorContext } from '../../Components/ColorContext/ColorContext';

export default function Menu(props){
	const navigate = useNavigate();
	const location = useLocation().pathname;
    const { css } = useContext(ColorContext);
	const [ logoutModal, setLogoutModal ] = useState(false);

    return (<>
        {logoutModal && <div className="modal-container">
            <div className="modal-background" onClick={() => setLogoutModal(false)}></div>
            <div className="modal-box">
                <div className={styles['logout-modal']}>
                    <h4>Logout</h4>
                    <h5>Are you sure you want to logout?</h5>
                    <div className={styles["modal-action"]}>
                        <button className='btn' style={css.secondaryButton} onClick={() => setLogoutModal(false)}>Cancel</button>
                        <button className='btn' style={css.primaryButton} onClick={() => navigate('/logout')}>Logout</button>
                    </div>
                </div>
            </div>
        </div>}
        <ul className="sidemenu-items">
            {
                (location === "/dashboard")
                ?<li onClick={() => props.closeMenu()}><Link style={css.menuSelected} to="/dashboard"><span className="fa fa-dashboard"></span> Dashboard</Link></li>
                :<li onClick={() => props.closeMenu()}><Link to="/dashboard"><span className="fa fa-dashboard"></span> Dashboard</Link></li>
            }
            {
                (location === "/user-detail")
                ?<li onClick={() => props.closeMenu()}><Link style={css.menuSelected} to="/user-accounts"><span className="fa fa-users"></span> User accounts</Link></li>
                :<>
                    {
                        (location === "/user-accounts")
                        ?<li onClick={() => props.closeMenu()}><Link style={css.menuSelected} to="/user-accounts"><span className="fa fa-users"></span> User accounts</Link></li>
                        :<li onClick={() => props.closeMenu()}><Link to="/user-accounts"><span className="fa fa-users"></span> User accounts</Link></li>
                    }
                </>
            }
            {
                (location === "/add-user-manually")
                ?<li onClick={() => props.closeMenu()}><Link style={css.menuSelected} to="/add-user-manually"><span className="fa fa-user"></span> Add User Manually</Link></li>
                :<li onClick={() => props.closeMenu()}><Link to="/add-user-manually"><span className="fa fa-user"></span>Add User Manually</Link></li>
            }
            {
                (location === "/user-transactions")
                ?<li onClick={() => props.closeMenu()}><Link style={css.menuSelected} to="/user-transactions"><span className="fa fa-exchange"></span> User transactions</Link></li>
                :<li onClick={() => props.closeMenu()}><Link to="/user-transactions"><span className="fa fa-exchange"></span> User transactions</Link></li>
            }
            {
                (location === "/my-transactions")
                ?<li onClick={() => props.closeMenu()}><Link style={css.menuSelected} to="/my-transactions"><span className="fa fa-exchange"></span> My transactions</Link></li>
                :<li onClick={() => props.closeMenu()}><Link to="/my-transactions"><span className="fa fa-exchange"></span> My transactions</Link></li>
            }
            {
                (location === "/my-plan")
                ?<li onClick={() => props.closeMenu()}><Link style={css.menuSelected} to="/my-plan"><span className="fa fa-tasks"></span> My Plan</Link></li>
                :<li onClick={() => props.closeMenu()}><Link to="/my-plan"><span className="fa fa-tasks"></span> My Plan</Link></li>
            }
            {
                (location === "/trubank")
                ?<li onClick={() => props.closeMenu()}><Link style={css.menuSelected} to="/trubank"><span className="fa fa-user-o"></span> TruBank</Link></li>
                :<li onClick={() => props.closeMenu()}><Link to="/trubank"><span className="fa fa-user-o"></span> TruBank</Link></li>
            }
            {
                (location === "/my-account")
                ?<li onClick={() => props.closeMenu()}><Link style={css.menuSelected} to="/my-account"><span className="fa fa-user-o"></span> My Account</Link></li>
                :<li onClick={() => props.closeMenu()}><Link to="/my-account"><span className="fa fa-user-o"></span> My Account</Link></li>
            }
            {
                (location === "/whitelist-ip")
                ?<li onClick={() => props.closeMenu()}><Link style={css.menuSelected} to="/whitelist-ip"><span className="fa fa-user-o"></span> Whitelist IP</Link></li>
                :<li onClick={() => props.closeMenu()}><Link to="/whitelist-ip"><span className="fa fa-user-o"></span> Whitelist IP</Link></li>
            }
            {
                (location === "/ip-hits")
                ?<li onClick={() => props.closeMenu()}><Link style={css.menuSelected} to="/ip-hits"><span className="fa fa-user-o"></span>IP Hits</Link></li>
                :<li onClick={() => props.closeMenu()}><Link to="/ip-hits"><span className="fa fa-user-o"></span>IP Hits</Link></li>
            }
            {
                (location === "/reset-password")
                ?<li onClick={() => props.closeMenu()}><Link style={css.menuSelected} to="/reset-password"><span className="fa fa-user"></span>Reset Password</Link></li>
                :<li onClick={() => props.closeMenu()}><Link to="/reset-password"><span className="fa fa-user"></span>Reset Password</Link></li>
            }
            <li onClick={() => setLogoutModal(true)}><Link ><span className="fa fa-sign-out"></span> Logout</Link></li>
        </ul>
    </>)
}