import jwt_decode from "jwt-decode";

export const setAdminToken = (token) => {
    return localStorage.setItem("auth", token);
}

export const getAdminToken = () => {
    const token = localStorage.getItem("auth");
    if(token) return token;
    else return null;
}

export const getAdminUser = () => {
    var token = getAdminToken();
    var userId = token?jwt_decode(token).id:null;
    return userId;
}

export const getSubdomain = () => {
    var token = getAdminToken();
    var subdomain = token?jwt_decode(token).subdomain:null;
    return subdomain;
}

export const getUserName = () => {
    var token = getAdminToken();
    var userName = token?jwt_decode(token).userName:null;
    return userName;
}

export const removeAdminToken = () => {
    return localStorage.removeItem("auth");
}

export const setSuperAdminToken = (token) =>{
    return sessionStorage.setItem("superadminToken", token);
}

export const getSuperAdminToken = () => {
    const token = sessionStorage.getItem("superadminToken");
    if(token) return token;
    else return null;
}

export const getSuperAdminUser = () => {
    var token = getSuperAdminToken();
    var userId = token?jwt_decode(token).id:null;
    return userId;
}

export const removeSuperAdminToken = () => {
    return sessionStorage.removeItem("superadminToken");
}