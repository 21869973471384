import React, { useState, useEffect, useContext } from 'react';
import Loading from '../../Components/Loading/Loading';
// import oceanapi, { fetchUsersList } from '../../Base/OceanConfig';
import adminapi, { fetchUsersList } from '../../Base/AdminConfig';
import { Link, useNavigate } from 'react-router-dom';
import { ColorContext } from '../../Components/ColorContext/ColorContext';
import styles from "./user-accounts.module.css";

export default function UserAccount() {
    const navigate = useNavigate();
    const { userName } = useContext(ColorContext);
    const { css } = useContext(ColorContext);
    const [ users, setUsers ] = useState([]);
    const [ loading, setLoading ] = useState(true);

    const fetchUsers = () => {
        adminapi.get(fetchUsersList + userName)
        .then((res) => {
            setLoading(false);
            if(res.data.code === 200){
                setUsers(res.data.result.records);
            } else {
                setUsers([]);
            }
        })
        .catch((error) => {
            setLoading(false);
            console.log(error);
        })
    }

    useEffect(() => {
        if(userName){
            fetchUsers();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userName])

    const openDetail = (detail) => {
        const data = {
            fullName: detail.firstName + " " + detail.lastName,
            email: detail.email,
            phone: detail.phoneNumber,
            userId: detail.userId,
            active: detail.isActive,
            fullAccess: detail.fullAccess
        }
        sessionStorage.setItem("user-detail", JSON.stringify(data));
        navigate('/user-detail');
    }

    return (
        <div className="admin-dashboard">
            <h1 className="admin-dashboard-heading">User Accounts</h1>
            {
                loading
                ?<Loading/>
                :<div className="admin-dashboard-body">
                    {
                        users.length === 0
                        ?<div className="no-records">
                            <p>No user found, You can register a user manually <Link style={css.color} to="/add-user-manually">here</Link>.</p>
                        </div>
                        :users.map((user, i) => (
                            <div className={styles['user-detail-box']} key={i}>
                                <div>
                                    <span className={styles['id']}>#{(i + 1)}</span>
                                    <p>Date: <span>{`${new Date(user.createdAt)}`.substr(0, 24)}</span></p>
                                </div>
                                <div>
                                    <div>
                                        <img src ='../images/admin/user-account-logo.svg' alt='user-account-logo' />
                                    </div>
                                    <div className={styles['user-details']}>
                                        <p>User Id<span>{user.userId}</span></p>
                                        <p>Name<span>{user.firstName} {user.lastName}</span></p>
                                        <p>Status{user.isActive ? <span className={styles["green"]}>Active</span> : <span className={styles["red"]}>Inactive</span>}</p>
                                        <p>Phone no<span>{user.phoneNumber ? user.phoneNumber : "_"}</span></p>
                                        <p>Email<span>{user.email}</span></p>
                                        <div>
                                            <button className="btn" style={css.primaryButton} onClick={() => openDetail(user)}>View details</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            }
        </div>
    );
}