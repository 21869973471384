import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import TickList from "./components/TickList/TickList";
import BtnArrowPrimary from "./components/Buttons/BtnArrowPrimary/BtnArrowPrimary";
import FocusCardSecondary from "./components/FocusCards/FocusCardSecondary/FocusCardSecondary";
import FocusCardPrimary from "./components/FocusCards/FocusCardPrimary/FocusCardPrimary";
import BtnArrowSecondary from "./components/Buttons/BtnArrowSecondary/BtnArrowSecondary";
import BtnSecondary from "./components/Buttons/BtnSecondary/BtnSecondary";
import PriceCard from "./components/PriceCard/PriceCard";
import oceanApi, { postContactForm } from "../../Base/OceanConfig";
import Message from "../../Components/Message/Message";

const Home = () => {
  const postContactUsFormToken = process.env.REACT_APP_TOKEN;

  const myRef = useRef(null);

  const scrollToContactUs = () => {
    myRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const navigate = useNavigate();
  const loginFunction = () => {
    navigate("/auth");
  };
  const signupFunction = () => {
    navigate("/signup");
  };

  const footerData = {
    products: {
      truBgc: {
        name: "TruBGC",
        endPoint:
          "https://invincibleocean.com/background-check-solution-trubgc/",
      },
      // truBgv: {
      //   name: "TruBGV",
      //   endPoint:
      //     "https://invincibleocean.com/company-verification-solution-trubgv/",
      // },
      truCase: {
        name: "TruCase",
        endPoint:
          "https://invincibleocean.com/court-case-and-police-verification-solution-trucase/",
      },
      truAml: {
        name: "TruAML",
        endPoint:
          "https://invincibleocean.com/aml-solution-with-mca-blacklist-check-truaml/",
      },
      truBank: {
        name: "TruBank",
        endPoint:
          "https://invincibleocean.com/bank-statement-analyser-api-solution/",
      },
    },
    explore: {
      vehicleRegistrationCheck: {
        name: "Vehicle Registration Check",
        endPoint:
          "https://invincibleocean.com/vehicle-registration-check-rc-verification-api/",
      },
      gstApi: {
        name: "GST API",
        endPoint: "https://invincibleocean.com/gst-verification-api/",
      },
      panAndAdhaarVerificationApi: {
        name: "PAN and Aadhaar Verification API",
        endPoint: "https://invincibleocean.com/pan-aadhaar-verification-api/",
      },
      bankStatementAnalyser: {
        name: "Bank Statement Analyser",
        endPoint:
          "https://invincibleocean.com/bank-statement-analyser-api-solution/",
      },
      exploreIvincibleApis: {
        name: "Explore Invincible APIs",
        endPoint: "https://invincibleocean.com/explore-invincible-apis/",
      },
      productDevelopment: {
        name: "Product Development",
        endPoint: "https://invincibleocean.com/software-product-development/",
      },
      phoneKycApi: {
        name: "Phone KYC API",
        endPoint: "https://invincibleocean.com/phone-kyc/",
      },
      creditScoreCheckApi: {
        name: "Credit Score Check API",
        endPoint: "https://invincibleocean.com/credit-score-api/",
      },
      faceMatchApi: {
        name: "Face Match API",
        endPoint: "https://invincibleocean.com/face-match-api-solution/",
      },
    },
    others: {
      contactUs: {
        name: "Contact us",
        endPoint: "https://invincibleocean.com/contact-us/",
      },
      aboutUs: {
        name: "About us",
        endPoint: "https://invincibleocean.com/about-invincible/",
      },
      faqs: {
        name: "FAQs",
        endPoint: "https://invincibleocean.tawk.help/",
      },
    },
    privacyPolicy: {
      name: "Privacy policy",
      endPoint: "https://invincibleocean.com/privacy-policy/",
    },
    termsAndCancellationPolicy: {
      name: "Terms and cancellation policy",
      endPoint: "https://invincibleocean.com/terms-cancellation-policy/",
    },
  };

  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [userMessage, setUserMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [messageClass, setMessageClass] = useState("");

  const resetContactUsForm = () => {
    setName("");
    setMobile("");
    setEmail("");
    setUserMessage("");
  };

  const postData = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      const res = await oceanApi.post(
        postContactForm,
        {
          name,
          email,
          mobile,
          message: userMessage,
        },
        {
          headers: {
            token: postContactUsFormToken,
          },
        }
      );
      if (res.data.code === 200) {
        setIsLoading(false);
        setMessageClass("success");
        setMessage(res.data.message);
        resetContactUsForm();
        setTimeout(() => {
          setMessage("");
        }, 5000);
      } else {
        setIsLoading(false);
        setMessageClass("error");
        setMessage(res.data.message);
        setTimeout(() => {
          setMessage("");
        }, 5000);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      setMessageClass("error");
      setMessage("Something went wrong, Try again.");
      setTimeout(() => {
        setMessage("");
      }, 5000);
    }
  };

  return (
    <>
      {message && (
        <Message
          message={message}
          messageClass={messageClass}
          closeMessage={() => setMessage('')}
        />
      )}
      <div className="flex justify-center">
        <div className="font-barlow max-w-max-disp">
          <nav className="max-mob-break:px-4 flex items-center justify-between py-4 px-12">
            <div className="logo-cnt w-52 h-9 ">
              <img
                src="./images/landing_page/invincibleLogo.png"
                className="h-full"
                alt="logo"
              />
            </div>
            <div className="header_btns flex justify-end font-bold capitalize">
              <button
                className="mr-3 px-3 py-2 text-primaryColor"
                onClick={() => loginFunction()}
              >
                Login
              </button>
              <button
                className="text-base text-white px-3 py-2 rounded bg-primaryColor"
                onClick={() => signupFunction()}
              >
                Sign Up
              </button>
            </div>
          </nav>

          <section className=" max-lap-break:block grid grid-cols-2 mb-4">
            <div className="max-mob-break:p-4 max-mob-break:mt-0  max-tab-break:px-10 max-tab-break:mx-0 max-lap-break:mx-12  max-lap-break:mt-6 ml-12 mt-12">
              <div className="text-5xl font-bold font-poppins">
                <p className="text-secondaryColor ">Unleash Innovation with</p>
                <p className="mt-3">
                  <span className="text-secondaryColor">Our </span>
                  <span className="text-primaryColor">
                    White Label Platform
                  </span>
                </p>
              </div>
              <div className="mb-8">
                <TickList
                  heading={"Whitelabel Solution for"}
                  list={[
                    { name: "Background Check Companies", color: "grey" },
                    { name: "HR Managers", color: "grey" },
                    { name: "Charted accountants", color: "grey" },
                    { name: "Lawyers", color: "grey" },
                    { name: "Banks & Financial Institutions", color: "grey" },
                    { name: "Entrepreneurs", color: "grey" },
                  ]}
                  forCardPrice={false}
                />
              </div>
              <BtnArrowPrimary
                btnName={"Book a demo"}
                action={scrollToContactUs}
              />
              <div className="mt-16">
                <div className="flex">
                  <div className="mr-4">
                    <img
                      src="./images/landing_page/iso.png"
                      alt=""
                      className="h-14"
                    />
                  </div>
                  <div className="mr-4">
                    <img
                      src="./images/landing_page/gdpr.png"
                      alt=""
                      className="h-14"
                    />
                  </div>
                  <div className="mr-4">
                    <img
                      src="./images/landing_page/soc2.png"
                      alt=""
                      className="h-14"
                    />
                  </div>
                  <div className="mr-4">
                    <img
                      src="./images/landing_page/cisa.png"
                      alt=""
                      className="h-14"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="max-lap-break:hidden">
              <img
                src="./images/landing_page/demo_bg.png"
                className="w-full h-full object-contain"
                alt="demoBg"
              />
            </div>
          </section>

          <section className="max-mob-break:p-0  py-12  pr-10 bg-bgGrey">
            <div className="max-lap-break:block grid grid-cols-2">
              <div className="flex items-center">
                <div className="">
                  <img src="./images/landing_page/clock.png" alt="" />
                </div>
              </div>
              <div className="max-mob-break:p-4 max-mob-break:m-0 max-mob-break:mt-4    max-lap-break:mt-12 max-lap-break:mx-12 ml-20">
                <p className="text-primaryColor font-semibold	text-sm">
                  AI Driven Solution
                </p>
                <p className="text-secondaryColor font-bold text-3xl mt-6 font-poppins">
                  Lightning-Fast Digital Onboarding{" "}
                  <span className="block">Solution</span>
                </p>
                <p className="mt-6 text-base text-tickListGreyColor font-medium 	">
                  Require a rapid solution? No worries. Thanks to our 10-Minute
                  Delivery Guarantee, your selected white label product can be
                  operational swiftly.
                </p>
                <div className="text-tickListGreyColor mt-6 mb-6">
                  <div className="mt-3">
                    <span className="inline-block p-1 rounded-full bg-primaryColor mr-2"></span>

                    <span className="font-bold">API Reseller Platform-</span>
                    <span className="font-medium">
                      Access to 150+ Verification APIs
                    </span>
                  </div>
                  <div className="mt-3">
                    <span className="inline-block p-1 rounded-full bg-primaryColor mr-2"></span>
                    <span className="font-bold">
                      Background Check Solution -
                    </span>
                    <span className="font-medium">Consent & Non Consent</span>
                  </div>
                  <div className="mt-3">
                    <span className="inline-block p-1 rounded-full bg-primaryColor mr-2"></span>
                    <span className="font-medium">
                      Admin Dashboard to Manage Users
                    </span>
                  </div>
                  <div className="mt-3">
                    <span className="inline-block p-1 rounded-full bg-primaryColor mr-2"></span>
                    <span className="font-medium">Dashboard for users</span>
                  </div>
                  <div className="mt-3">
                    <span className="inline-block p-1 rounded-full bg-primaryColor mr-2"></span>
                    <span className="font-medium">Unlimited User accounts</span>
                  </div>
                </div>
                <BtnArrowPrimary
                  btnName={"Get Started"}
                  action={signupFunction}
                />
              </div>
            </div>
          </section>

          <section className="max-mob-break:px-4  pt-16">
            <p className="max-mob-break:px-0  max-lap-break:px-12 text-secondaryColor text-3xl font-bold text-center font-poppins">
              Unburdened: Focus on Business
            </p>
            <p className="max-mob-break:px-0  max-lap-break:px-12 text-tickListGreyColor font-medium text-xl text-center mt-2 ">
              Entrepreneurs can direct their focus on business operations, free
              from concerns about backend technology.
            </p>
            <div className="bg-focus_bg bg-no-repeat bg-right-top	">
              <div className="max-mob-break:px-0  grid grid-cols-responsiveColumnsFocusCard gap-4 justify-items-center justify-center pt-16 px-16">
                <FocusCardPrimary
                  imgLink={"./images/landing_page/hammer.svg"}
                  cardHeading={"150+ APIs"}
                  cardContent={
                    "Access to a versatile range of solutions designed to transform your business processes and drive growth."
                  }
                />
                <FocusCardSecondary
                  imgLink={"./images/landing_page/checkList.svg"}
                  cardContent={
                    "Our Background Verification product offers comprehensive insights to ensure a reliable and secure team."
                  }
                  cardHeading={"Background Check Solution"}
                />
                <FocusCardSecondary
                  imgLink={"./images/landing_page/cardTick.svg"}
                  cardContent={
                    " Our AML Verification Solution empowers your business to combat risks and maintain compliance with ease."
                  }
                  cardHeading={"Anti Money Laundering Solution"}
                />
                <FocusCardSecondary
                  imgLink={"./images/landing_page/pen.svg"}
                  cardContent={
                    "Seamlessly verify digital signatures with our innovative TruSign Verification product, making your transactions secure and efficient."
                  }
                  cardHeading={"E-Signing"}
                />
                <FocusCardSecondary
                  imgLink={"./images/landing_page/person.svg"}
                  cardContent={
                    "Access account statements from 250+ banks, offering diverse PDF formats, variable time periods, and AI-enhanced analysis for seamless financial insights."
                  }
                  cardHeading={"Bank Statement Analyser"}
                />
              </div>
            </div>
            <div className="flex items-center justify-center py-10">
              <BtnArrowSecondary
                btnName={"Book a demo"}
                action={scrollToContactUs}
              />
            </div>
          </section>

          <section className="max-mob-break:px-4  mt-4">
            <p className="text-secondaryColor text-3xl font-bold text-center font-poppins">
              Pricing
            </p>
            <p className="text-center text-xl font-medium ">
              <span className="text-tickListGreyColor ">
                We make money, only{" "}
              </span>
              <span className="text-primaryColor mt-3">when you do</span>
            </p>
            <div
              className="mt-36 bg-pricing_bg  bg-no-repeat"
              style={{ backgroundSize: "100% 100%" }}
            >
              <div className="mob-break:mx-4  0 grid grid-cols-responsivePriceCard gap-8 justify-items-center -translate-y-24 ">
                <PriceCard
                  imgIconLink={"./images/landing_page/standard_icon.svg"}
                  headerTitle={"Standard"}
                  headerContent={
                    "Start generating and emailing new leads, and measuring your success — for free"
                  }
                  monthlyPrice={"10,000"}
                  yearlyPrice={"8,000"}
                  cardColor={"standardCard"}
                  tickListDataHeader={"Includes"}
                  tickListDataList={[
                    {
                      name: "Reseller Whitelabelled API Platform",
                      color: "grey",
                    },
                    { name: "Access to 100+ Verification APIs", color: "grey" },
                    {
                      name: "Whitelabel Background Check Solution: Consent and Non-Consent: TruBGC",
                      color: "black",
                    },
                    {
                      name: ">25% Discount on Standard API pricing",
                      color: "grey",
                    },
                    {
                      name: "Customized Pricing Option for Clients",
                      color: "grey",
                    },
                    { name: "5000 Wallet Credits every month", color: "black" },
                    { name: "Standard Email and Phone Support", color: "grey" },
                  ]}
                  offDiscount={"10"}
                  buyNowAction={signupFunction}
                  talkToSalesAction={signupFunction}
                />
                <PriceCard
                  imgIconLink={"./images/landing_page/premium_icon.svg"}
                  headerTitle={"Premium"}
                  headerContent={
                    "Start generating and emailing new leads, and measuring your success — for free"
                  }
                  monthlyPrice={"20,000"}
                  yearlyPrice={"17,000"}
                  cardColor={"premiumCard"}
                  tickListDataHeader={"Includes"}
                  tickListDataList={[
                    { name: "Everything in Standard Plan", color: "grey" },
                    {
                      name: "Whitelabel Bank Statement Analyser with Stats- TruBank",
                      color: "black",
                    },
                    {
                      name: "40% Discount on Standard pricing for all products",
                      color: "grey",
                    },
                    {
                      name: ">25% Discount on Standard API pricing",
                      color: "grey",
                    },
                    {
                      name: "Whitelabelled Digital and Adhaar-Based Signing Solution- TruSign",
                      color: "grey",
                    },
                    { name: "10000 Wallet Credits every month", color: "grey" },
                    { name: "Premium Support- 19*7", color: "grey" },
                  ]}
                  offDiscount={"10"}
                  buyNowAction={signupFunction}
                  talkToSalesAction={signupFunction}
                />
                <PriceCard
                  imgIconLink={"./images/landing_page/pro_icon.svg"}
                  headerTitle={"Pro"}
                  headerContent={
                    "Start generating and emailing new leads, and measuring your success — for free"
                  }
                  monthlyPrice={"30,000"}
                  yearlyPrice={"21,000"}
                  cardColor={"proCard"}
                  tickListDataHeader={"Includes"}
                  tickListDataList={[
                    { name: "Everything in Premium Plan", color: "grey" },
                    {
                      name: "Whitelabel Credit Score Check Solution",
                      color: "black",
                    },
                    {
                      name: "Whitelabel Court Case Check Solution with Lawyer Signature",
                      color: "black",
                    },
                    { name: "55% Discount on Standard Pricing", color: "grey" },
                    {
                      name: "15000 Wallet Credits every month",
                      color: "black",
                    },
                    { name: "24*7 Email and Phone Suppor", color: "grey" },
                    { name: "Dedicated Account Manager", color: "grey" },
                  ]}
                  offDiscount={"10"}
                  buyNowAction={signupFunction}
                  talkToSalesAction={signupFunction}
                />
              </div>
            </div>
          </section>

          <section className="max-mob-break:mt-0  mt-16 ">
            <p className="max-mob-break:p-4 max-tab-break:px-12 text-center text-3xl font-bold text-priceCardBlue font-poppins">
              AI- Powered Digital Infrastructure
            </p>
            <p className="max-mob-break:px-4 max-tab-break:px-12 text-center text-sm font-medium text-priceCardGrey px-40  ">
              Is your business ready to embrace the advantages of tech? Speed up
              business processes, prevent fraud, lower costs and keep all your
              KYC data safe with our whitelabel Solution
            </p>
            <div className="mt-3 max-mob-break:p-4  max-lap-break:flex max-lap-break:items-center max-lap-break:justify-center  bg-infrastructureBg bg-no-repeat bg-cover bg-center  grid grid-cols-2 justify-center justify-items-center px-10 py-20">
              <div className="flex items-center max-lap-break:hidden">
                <div className="w-477 h-407 ">
                  <img
                    src="./images/landing_page/infrastructure.png"
                    alt="digitalInfrastructure"
                  />
                </div>
              </div>

              <div className=" lap-break:ml-4">
                <div className="max-tab-break:grid-cols-responsiveFocusCard grid grid-cols-2 grid-rows-2 gap-7">
                  <div className="justify-self-end">
                    <FocusCardPrimary
                      imgLink={"./images/landing_page/fintech.svg"}
                      cardContent={
                        "Our no-code solution means you can create the fintech software that works best for your business"
                      }
                      cardHeading={"Fintech solution"}
                    />
                  </div>
                  <div className="">
                    <FocusCardSecondary
                      imgLink={"./images/landing_page/banking.svg"}
                      cardContent={
                        "Build software products for your banking institution without writing a single line of code. DIY with our no-code platform."
                      }
                      cardHeading={"Banking Solution"}
                    />
                  </div>
                  <div className="justify-self-end">
                    <FocusCardSecondary
                      imgLink={"./images/landing_page/checkList.svg"}
                      cardContent={
                        "Our Background Verification product offers comprehensive insights to ensure a reliable and secure team."
                      }
                      cardHeading={"Background Check Solution"}
                    />
                  </div>

                  <div className="">
                    <FocusCardSecondary
                      imgLink={"./images/landing_page/scalibility.svg"}
                      cardContent={
                        "As your business grows, our solutions grow with you, ensuring a seamless experience for your customers."
                      }
                      cardHeading={"Scalability"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="max-mob-break:px-4 max-mob-break:mb-12 max-tab-break:px-12 px-24 py-40 mt-8 bg-contactUsBg bg-no-repeat bg-contain">
            <div className="max-lap-break:block grid grid-cols-2 border-1 border-focusCardBorderGrey">
              <div className="max-mob-break:p-0 bg-callBoy_bg bg-no-repeat bg-center bg-cover p-10">
                <div className="text-priceCardBlue bg-callBoy_blur max-mob-break:p-4 py-14 px-10  h-full flex items-center justify-center">
                  <div className="">
                    <p className=" text-3xl font-bold font-poppins">
                      One call away
                    </p>
                    <p className="text-xl font-normal">
                      We’re open for any suggestion or just to have a chat.
                    </p>
                    <div className="flex mt-9">
                      <div>
                        <img
                          src="./images/landing_page/location_landingPage.svg"
                          alt="location"
                        />
                      </div>
                      <div className="pl-9 ">
                        <p className="text-xl font-bold">Our location</p>
                        <div className="text-base font-normal">
                          <p>201-A, SAS Tower, SEC-38, Gurugram</p>
                          <p>122003, Haryana</p>
                        </div>
                      </div>
                    </div>
                    <div className="flex mt-9">
                      <div>
                        <img
                          src="./images/landing_page/phone.svg"
                          alt="location"
                        />
                      </div>
                      <div className="pl-9 ">
                        <p className="text-xl font-bold">Phone number</p>
                        <div className="text-base font-normal">
                          <p>+91 95996 67779 ,  0124 4034247</p>
                         
                        </div>
                      </div>
                    </div>
                    <div className="flex mt-9">
                      <div>
                        <img
                          src="./images/landing_page/mail.svg"
                          alt="location"
                        />
                      </div>
                      <div className="pl-9 ">
                        <p className="text-xl font-bold">Email address</p>
                        <div className="text-base font-normal">
                          <p>Contact@invincibleocean.com</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div ref={myRef} className=" max-mob-break:p-4  bg-white p-14">
                <p className="text-3xl font-bold text-priceCardBlue mb-4">
                  Contact us
                </p>
                <hr />
                <form onSubmit={postData} className="mt-4">
                  <div className="">
                    <label
                      htmlFor="name"
                      className="text-base font-medium block"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Enter Name"
                      className="w-full p-2 border-1 border-contactUsFormBorder rounded"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </div>
                  <div className="mt-4">
                    <label
                      htmlFor="email"
                      className="text-base font-medium block"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Enter email"
                      className="w-full p-2 border-1 border-contactUsFormBorder rounded"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                  <div className="mt-4">
                    <label
                      htmlFor="phone"
                      className="text-base font-medium block"
                    >
                      Phone number
                    </label>
                    <input
                      type="text"
                      name="mobile"
                      id="mobile"
                      maxLength={10}
                      placeholder="Enter number"
                      className="w-full p-2 border-1 border-contactUsFormBorder rounded"
                      value={mobile}
                      onChange={(e) => setMobile(e.target.value)}
                      required
                    />
                  </div>
                  <div className="mt-4">
                    <label
                      htmlFor="userMessage"
                      className="text-base font-medium block"
                    >
                      Message
                    </label>
                    <textarea
                      name="userMessage"
                      id="userMessage"
                      cols="30"
                      rows="6"
                      placeholder="Enter userMessage"
                      className="w-full p-2 border-1 border-contactUsFormBorder rounded"
                      value={userMessage}
                      onChange={(e) => setUserMessage(e.target.value)}
                    ></textarea>
                  </div>
                  <div className="mt-12">
                    {!isLoading ? (
                      <BtnSecondary
                        btnName={"Send message"}
                        action={null}
                        btnType="submit"
                      />
                    ) : (
                      <BtnSecondary
                        btnName={"...wait"}
                        action={null}
                        btnType="disable"
                      />
                    )}
                  </div>
                </form>
              </div>
            </div>
          </section>

          <section className="max-mob-break:p-4  max-lap-break:flex-col-reverse	 flex justify-center bg-white px-12">
            <div className=" flex items-center flex-1 flex-col justify-center">
              <div className="">
                <p className="text-priceCardBlue text-4xl font-bold mb-11 font-poppins">
                  Elevate Your Business Today
                </p>
                <p className="text-base text-priceCardGrey font-medium mb-11">
                  Ready to embrace innovation? Contact our team today to learn
                  how our White Label Platform Solutions can be customized to
                  match your business goals. Transform your operations, enhance
                  security, and drive growth like never before.
                </p>
                <div className="mb-11">
                  <BtnArrowPrimary
                    btnName={"Get started"}
                    action={signupFunction}
                  />
                </div>
              </div>
            </div>
            <div className="lap-break:ml-4 flex-1 ">
              <img src="./images/landing_page/business.png" alt="business" />
            </div>
          </section>

          <footer className="max-tab-break:px-4 max-tab-break:py-4 max-lap-break:px-12 max-lap-break:py-12 px-20 py-16  bg-footerBg">
            <div className=" max-lap-break:flex-col-reverse flex">
              <div className="max-lap-break:hidden max-lap-break:mt-12 max-lap-break:w-full w-2/5">
                <div className="logo-cnt w-52 h-9 ">
                  <img
                    src="./images/landing_page/invincibleLogo.png"
                    className="h-full"
                    alt="logo"
                  />
                </div>
                <div className="mt-10">
                <a target="_blank" rel="noreferrer"  href="https://in.linkedin.com/company/invincible-ocean">
                    <span className="inline-block mr-4">
                      <img
                        src="./images/landing_page/linkedin.svg"
                        alt="linkedin"
                      />
                    </span>
                    </a>
                    {/* <span className="inline-block mr-4">
                  {" "}
                  <img src="./images/landing_page/twitter.svg" alt="twitter" />
                </span>
                <span className="inline-block mr-4">
                  <img src="./images/landing_page/fb.svg" alt="fb" />
                </span> */}
                    <a
                      target="_blank"
                      rel="noreferrer" 
                      href="https://www.instagram.com/officialinvincible/"
                    >
                      <span className="inline-block ">
                        <img
                          src="./images/landing_page/instagram.svg"
                          alt="instagram"
                        />
                      </span>
                    </a>
                </div>
                <div className="mt-10 text-base text-priceCardBlue  font-medium">
                  <p>201A SAS Tower Sector 38 Gurgaon-122003</p>
                  <p className="mt-2"><a className="cursor-pointer" href="mailto: ">contact@invincibleocean.com</a></p>
                  <p className="mt-2">+91-9599066061, 0124 4034247</p>
                  <div className="mt-10">
                    <BtnSecondary
                      btnName={"Contact us"}
                      action={scrollToContactUs}
                      btnType="button"
                    />
                  </div>
                </div>
              </div>
              <div className="max-lap-break:w-full  flex flex-col justify-between w-3/5">
                <div className="max-tab-break:block grid grid-cols-3 w-full text-priceCardBlue">
                  <div className="">
                    <p className="text-xl font-semibold font-poppins">
                      Products
                    </p>
                    {Object.keys(footerData.products).map(
                      (productLink, ind) => {
                        return (
                          <div
                            key={ind + new Date().getTime}
                            className={
                              ind === 0
                                ? "text-base font-medium mt-7 cursor-pointer"
                                : "text-base font-medium mt-3 cursor-pointer"
                            }
                          >
                            <a
                              target="_blank"
                              rel="noreferrer" 
                              href={footerData.products[productLink].endPoint}
                            >
                              {footerData.products[productLink].name}
                            </a>
                          </div>
                        );
                      }
                    )}

                    {/* <p className="text-base font-medium mt-3 cursor-pointer">
                    TruBGC
                  </p>
                  <p className="text-base font-medium mt-3 cursor-pointer">
                    Bulk PAN verification
                  </p>
                  <p className="text-base font-medium mt-3 cursor-pointer">
                    Hybrid court case
                  </p>
                  <p className="text-base font-medium mt-3 cursor-pointer">
                    Products
                  </p> */}
                  </div>
                  <div className="max-tab-break:mt-12">
                    <p className="text-xl font-semibold font-poppins">
                      Explore
                    </p>
                    {Object.keys(footerData.explore).map((exploreLink, ind) => {
                      return (
                        <div
                          key={ind + new Date().getTime}
                          className={
                            ind === 0
                              ? "text-base font-medium mt-7 cursor-pointer"
                              : "text-base font-medium mt-3 cursor-pointer"
                          }
                        >
                          <a
                            target="_blank"
                            rel="noreferrer" 
                            href={footerData.explore[exploreLink].endPoint}
                          >
                            {footerData.explore[exploreLink].name}
                          </a>
                        </div>
                      );
                    })}

                    {/* <p className="text-xl font-semibold font-poppins">
                    Solutions
                  </p>
                  <p className="text-base font-medium mt-7 cursor-pointer">
                    Banking sector
                  </p>
                  <p className="text-base font-medium mt-3  cursor-pointer">
                    Medical sector
                  </p>
                  <p className="text-base font-medium mt-3  cursor-pointer">
                    e-commerce sector
                  </p>
                  <p className="text-base font-medium mt-3  cursor-pointer">
                    Financial sector
                  </p>
                  <p className="text-base font-medium mt-3  cursor-pointer">
                    Health sector
                  </p> */}
                  </div>
                  <div className="max-tab-break:mt-12 justify-self-center">
                    <p className="text-xl font-semibold font-poppins">Others</p>
                    {Object.keys(footerData.others).map((othersLink, ind) => {
                      return (
                        <div
                          key={ind + new Date().getTime}
                          className={
                            ind === 0
                              ? "text-base font-medium mt-7 cursor-pointer"
                              : "text-base font-medium mt-3 cursor-pointer"
                          }
                        >
                          <a
                            target="_blank"
                            rel="noreferrer" 
                            href={footerData.others[othersLink].endPoint}
                          >
                            {footerData.others[othersLink].name}
                          </a>
                        </div>
                      );
                    })}
                    {/* <p className="text-base font-medium mt-7 cursor-pointer">
                    Contact us
                  </p>
                  <p className="text-base font-medium mt-3 cursor-pointer">
                    About us
                  </p>
                  <p className="text-base font-medium mt-3 cursor-pointer">
                    FAOs
                  </p> */}
                  </div>
                </div>

                <div className="lap-break:hidden max-lap-break:mt-12 max-lap-break:w-full w-2/5">
                  <div className="logo-cnt w-52 h-9 ">
                    <img
                      src="./images/landing_page/invincibleLogo.png"
                      className="h-full"
                      alt="logo"
                    />
                  </div>
                  <div className="mt-4">
                    <a target="_blank" rel="noreferrer"  href="https://in.linkedin.com/company/invincible-ocean">
                    <span className="inline-block mr-4">
                      <img
                        src="./images/landing_page/linkedin.svg"
                        alt="linkedin"
                      />
                    </span>
                    </a>
                    {/* <span className="inline-block mr-4">
                  {" "}
                  <img src="./images/landing_page/twitter.svg" alt="twitter" />
                </span>
                <span className="inline-block mr-4">
                  <img src="./images/landing_page/fb.svg" alt="fb" />
                </span> */}
                    <a
                      target="_blank"
                      rel="noreferrer" 
                      href="https://www.instagram.com/officialinvincible/"
                    >
                      <span className="inline-block ">
                        <img
                          src="./images/landing_page/instagram.svg"
                          alt="instagram"
                        />
                      </span>
                    </a>
                  </div>
                  <div className="mt-4 text-base text-priceCardBlue  font-medium">
                    <p>201A SAS Tower Sector 38 Gurgaon-122003</p>
                    <p className="mt-2"><a className="cursor-pointer" href="mailto:contact@invincibleocean.com">contact@invincibleocean.com</a></p>
                    <p className="mt-2">+91-9599066061, 0124 4034247</p>
                  </div>
                  <div className="mt-4">
                    <BtnSecondary
                      btnName={"Contact us"}
                      action={scrollToContactUs}
                      btnType="button"
                    />
                  </div>
                </div>

                <div className="max-mob-break:block flex mt-8">
                  <div
                    className="mob-break:pr-3 max-mob-break:border-0 text-sm font-medium border-r-2 border-black cursor-pointer"
                    onClick={scrollToContactUs}
                  >
                    Get in touch
                  </div>
                  <div className="max-mob-break:mt-4 mob-break:px-3 max-mob-break:border-0 text-sm font-medium  border-r-2 border-black cursor-pointer">
                    <a target="_blank" rel="noreferrer"  href={footerData.privacyPolicy.endPoint}>
                      {footerData.privacyPolicy.name}
                    </a>
                  </div>
                  <div className="max-mob-break:mt-4 mob-break:px-3 max-mob-break:border-0 text-sm font-medium  border-r-2 border-black cursor-pointer">
                    <a
                      target="_blank"
                      rel="noreferrer" 
                      href={footerData.termsAndCancellationPolicy.endPoint}
                    >
                      {footerData.termsAndCancellationPolicy.name}
                    </a>
                  </div>
                  <p className="max-mob-break:mt-4 mob-break:px-3 max-mob-break:border-0 text-sm font-medium  border-black ">
                    © 2023 Invincible Ocean Private Limited | All rights reserved
                  </p>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};

export default Home;
