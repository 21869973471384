import React,{useEffect, useState} from 'react'
import adminapi, {setSecurity, getSecurity} from '../../../Base/AdminConfig';
import { getAdminUser } from '../../../Utils/Common';
import Message from '../../../Components/Message/Message';
import styles from "../my-account.module.css";

export default function Setting(props) {

    const [ serverSecurity, setServerSecurity ] = useState();
    const userName=props.userName;
    const userId = getAdminUser();
    const [message , setMessage] =useState("");
    const [messageClass , setMessageClass] =useState("");

    const UpdateSecurity =(value)=>{
        setServerSecurity(value)
        adminapi.post(setSecurity,{
            userId:userId,
            userName:userName,
            status:value
        })
        .then((res) => {
            if(res.data.code === 200){
                setMessageClass('success');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('')
                }, 5000);
            } else {
                setMessageClass('error');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            }
        })
        .catch((error) => {
            console.error(error);
            setMessageClass('error');
            setMessage('Something went wrong, Try again.');
            setTimeout(() => {
                setMessage('');
            }, 5000);
        })

    }

    const fetchSecurity =()=>{
        adminapi.get(getSecurity + userId)
        .then((res)=>{
            if(res.data.code === 200){
                setServerSecurity(res.data.result.status);
            }
            else {
                setMessageClass('error');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            }
        })
        .catch((error) => {
            console.error(error);
            setMessageClass('error');
            setMessage('Something went wrong, Try again.');
            setTimeout(() => {
                setMessage('');
            }, 5000);
        })
    }

    useEffect(()=>{
        fetchSecurity();
        // eslint-disable-next-line
    },[serverSecurity])

   

  return (
    <>
        {message && <Message message={message} messageClass={messageClass} closeMessage={() => setMessage('')} />}
        <div className="my-custom-container">
            <h5 className={styles['my-account-details-heading']}>Settings</h5>
            <div className='row'>
                <div className='col-xs-12 my-2 col-md-6'>
                    <label className='font-semibold'>Server Security</label><br/>
                    <input type="radio" name="active" checked={serverSecurity && 'checked'} onClick={() => UpdateSecurity(true)}/> Enable &nbsp; &nbsp;
                    <input type="radio" name="active" checked={!serverSecurity && 'checked'} onClick={() => UpdateSecurity(false)}/> Disable
                </div>
            </div>
        </div>
    </>
  )
}
