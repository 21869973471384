import React, { useState, useContext } from 'react';
import styles from "./sidebar.module.css";
import Menu from './Menu';
import { ColorContext } from '../../Components/ColorContext/ColorContext';

export default function Sidebar(){
    const { logo } = useContext(ColorContext);
    const { userName } = useContext(ColorContext);
    const [ menu, setMenu ] = useState(false);

    const closeMenu = () => {
        setMenu(false);
    }

    return (<>
        <div className={styles['left-menu-bar']}>
            <img src={logo} alt={userName} className={styles['brand-logo']} />
            <div className={styles["sidemenu-box"]}>
                <Menu closeMenu={closeMenu} />
            </div>
        </div>
        {
            menu
            ?<div className={styles['mobile-menu-items']}>
                <div>
                    <img src={logo} alt={userName} className={styles['brand-mobile-logo']} />
                    <div className={styles['menu-times']} onClick={() => setMenu(false)}>
                        <span></span>
                        <span></span>
                    </div>
                </div>
                <div className={styles["sidemenu-box"]}>
                    <Menu closeMenu={closeMenu} />
                </div>
            </div>
            :<div className={styles["mobile-menu"]}>
                <img src={logo} alt={userName} className={styles['brand-mobile-logo']} />
                <div className={styles['menu-bars']} onClick={() => setMenu(true)}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        }
    </>)
}