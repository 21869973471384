import React from 'react';
import ApiWallet from './Credits/ApiWallet';
import ProductWallet from './Credits/ProductWallet';

export default function Credits(props){
    const userId = props.userId;

    return (<>
        <ApiWallet userId={userId} adminUsername={props?.adminUsername} superAdminToken={props?.superAdminToken} />
        <br/>
        <ProductWallet userId={userId} adminUsername={props?.adminUsername} superAdminToken={props?.superAdminToken} />
    </>);
}