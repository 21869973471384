import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import oceanapi, { verifyAdminEmail } from '../../Base/OceanConfig';
import styles from './verify-email.module.css';

export default function VerifyEmail () {
    const navigate = useNavigate();
    const encyption = useParams().id;
    const [ status, setStatus ] = useState('loading');

    useEffect(() => {
        verifyEmail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

   const verifyEmail = () => {
    oceanapi.get(verifyAdminEmail + encyption)
        .then((res) => {
            if(res.data.code === 200){
                setStatus('success');
                setTimeout(() => {
                    navigate('/complete-details');
                }, 2000);
            } else {
                setStatus('error');
            }
        })
        .catch((error) => {
            console.error(error);
        })
    }

    return(<>
        <div className={styles["verify-mail"]}>
            {status === 'loading' && <>
                <img src="../images/loading.gif" alt="loading" />
                <h4>Updating Status</h4>
            </>}
            {status === 'error' && <>
                <img src="../images/times.gif" alt="times" />
                <h4>Error Updating Status</h4>
            </>}
            {status === 'success' && <>
                <img src="../images/check.gif" alt="check" />
                <h4>Your email is verified successfully, You can login to your dashboard.</h4>
            </>}
        </div>
    </>);
}
