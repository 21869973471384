import axios from "axios";
import { getSuperAdminToken } from "../Utils/Common";
export const baseurl = process.env.REACT_APP_WHITELABEL_URL;

const superadminapi = axios.create({
    baseURL: baseurl,
    headers: {
        'token': getSuperAdminToken(),
        'versionnumber': process.env.REACT_APP_VERSION
    },
});

export default superadminapi;

superadminapi.interceptors.response.use(
    function (response) {
        if(response.data.code === 401) {
            window.location.replace('/superadmin/logout');
        }
        return response;
    },
    async function (error) {
        if(error.response.status === 401) {
            window.location.replace('/superadmin/logout');
        }
        return Promise.reject(error);
    }
);


// Fetch api wallet credits of admin
export const fetchAdminCredits = "/admin/credits/";

// Fetch product wallet credits of admin
export const fetchAdminProductCredits = "/admin/productCredits/";

// Superadmin admin add Product credit
export const adminAddProductCredit = "/superAdmin/admin/productAddCredits";

// Superadmin admin add Api credit
export const adminAddApiCredit = "/superAdmin/admin/addCredits";

// Get  total users count 
export const adminuserscount = "/superAdmin/totalAdminCount";

// Get  total api transaction 
export const adminapitransaction = "/superAdmin/totalApiTransaction";

// Get  total product transaction 
export const adminproducttransaction = "/superAdmin/totalProductTransaction";

// Get  current credits - distributed in admins 
export const admincurrentcredits= "/superAdmin/currentCredits";

// All Admin
export const allAdmin = "/getAllWlAdmin/";

// Superadmin Clients filter 
export const clientsFilter = "/search/whiteLabelUsers";

//Superadmin get sandbox status
export const getSandboxStatus = "/superAdmin/getSendBoxStatus/";

// Superadmin update sandbox status
export const updateSandbox = "superAdmin/sendBoxStatus";

// Change the Documentation Link
export const setDocumentation = "/admin/documentLink";

// get SandBox api for admin
export const getSendBoxforAdmin="/superAdmin/getSendBoxforAdmin/";

// add SandBox api for admin
export const addSendBoxForAdmin ="/superAdmin/addCustomApiSendBox";

// update SandBox api for admin
export const updateSendBoxForAdmin ="/superAdmin/updateCustomApiSendBox/";

// delete SandBox api for admin
export const deleteSendBoxForAdmin = "/superAdmin/deleteCustomApiSendBox/";

// Fetch plan details
export const planDetail = "/superAdmin/getAdminPlan/";

// fetch customized plan price
export const getCustomizeAdminPlan = "/superAdmin/getCustomizeAdminPlan/";

// Change plan price
export const changeCustomizeAdminPlan = "/superAdmin/customizeAdminPlan";

//get default sandbox plan details for admin
export const getApiDefaultSendBox = "/superAdmin/getApiDefaultSendBox";

//User Password reset API
export const passReset = "/admin/changePassword";

//Get default price (For PID)
export const defaultPrice ="/admin/getDefaultPrices";

// Fetch list of users of admin
export const fetchUsersList = "/admin/WhitelistUserlist/";

// Fetch default price
export const fetchDefaultPrice = "/superAdmin/getDefaultPrices";

//Add update api from superadmin
export const addUpdateDefaultPrices = "/superAdmin/allUserPrice/services";

//Fetch the admin plan according to the type of the plan
export const fetchSuperAdminPlans = "/superAdmin/getPlans/";

// Add or update the superadmin plans
export const addOrUpdateServiceAndPrice =
  "/superAdmin/admin/plan/addOrUpdateServiceAndPrice";