import React, { useState, useEffect, useContext} from 'react';
import styles from "./iphits.module.css";
import { getUserName } from '../../Utils/Common';
import Message from "../../Components/Message/Message";
import { ColorContext } from '../../Components/ColorContext/ColorContext';
import api,{listIpHits, blockIpList, filteringData, getBlockIpList, unblockips} from "../../Base/AdminConfig"


export default function IPHits() {
    const userName = getUserName();

    const { css } = useContext(ColorContext);
    const [ tabName, setTabName ] = useState('all-ips');
    const [ hits, setHits ] = useState('');
    const [ filterModal, setFilterModal ] = useState(false);
    const [ modal, setModal ] = useState(false);
    const [ totalRecords, setTotalRecords ] = useState(false);
    const [ pageNumber, setPageNumber ] = useState(1);
    const [ pageSize, setPageSize ] = useState('');
    const [ singleDetail, setSingleDetail ] = useState('');
    const [ filterData, setFilterData ] = useState('');
    const [ clientData, setClientData ] = useState('');
    const [ comments, setComments ] = useState('');
    const [ timestamp, setTimestamp ] = useState('');
    const [ userId, setUserId ] = useState('');
    const [ filterList, setFilterList ] = useState('');
    const [ message, setMessage ] = useState('');
    const [ messageClass, setMessageClass ] = useState('');
    const [ blockIps, setBlockIps ] = useState([]);
    const [ unBlockIp, setUnBlockIp ] = useState(false);
    const [ objectId, setObjectId ] = useState('');
    const [ loading, setLoading ] = useState(false);

    const FetchIphitsList = (page) => {
        setPageNumber(page?page:pageNumber);
        api.get(listIpHits + `?pagenumber=${page?page:pageNumber}`)
        .then(res => {
            if(res.data.code === 200){
            setHits(res.data.result.records);
            setPageSize(res.data.result.pageSize);
            setTotalRecords(res.data.result.totalRecords);
        } else{
            setHits([]);
        }
        })
        .catch((error) => {
            console.error(error);
        })
    }

    const fetchSingle = (x) => {
        setModal(true);
        setSingleDetail(x);
    }

    const blockedIP = (e) => {
        e.preventDefault();
        api.post(blockIpList, {
            userId: singleDetail.userId,
            ip: singleDetail.clientData,
            userName: userName,
            comments: comments
        })
        .then((res) => {
            if(res.data.code === 200){
                FetchIphitsList();
                getBlockedIps();
                setModal(false);
                setMessageClass('success');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            } else {
                setMessageClass('error');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            }
        })
        .catch((error) => {
            console.error(error);
            setMessageClass('error');
            setMessage('Something went wrong, Try again.');
            setTimeout(() => {
                setMessage('');
            }, 5000);
        })
    }

    const getBlockedIps = () => {
        api.get(getBlockIpList)
        .then(res => {
            if(res.data.code === 200){
                setBlockIps(res.data.result.records)
            } else {
                setBlockIps([])
            }
        })
        .catch((error) => {
            console.error(error);
            setMessageClass('error');
            setMessage('Something went wrong, Try again.');
            setTimeout(() => {
                setMessage('');
            }, 5000);
        })
    }

    useEffect(() => {
        getBlockedIps();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        FetchIphitsList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber])


    const filterFunction = (e) => {
        e.preventDefault();
        api.get(filteringData + `?userId=${userId}&clientData=${clientData}&timestamp=${timestamp}`)
        .then(res => {
            setFilterData(true);
            if(res.data.code === 200){
            setFilterList(res.data.result.records);
            setFilterModal();
            } else {
                setFilterList([]);
            }
        })
        .catch((error) => {
            console.error(error);
        })
    }

    const clearFilter = () => {
        setFilterList([]);
        setFilterModal(false);
        setFilterData(false);
        setClientData('');
        setTimestamp('');
        setUserId('');
    }

    const unBlockedModel = (x) => {
        setUnBlockIp(true);
        setObjectId(fetchBlockId(x));
    }

    const checkBlockedIp = (x) => {
        const newblockedList = blockIps.filter((blockIps) => blockIps.ip === x);
        return newblockedList.length > 0 ? 'Blocked' : 'Active';
    }

    const fetchBlockId = (x) => {
        const newblockedList = blockIps.filter((blockIps) => blockIps.ip === x);
        return newblockedList.length > 0 ? newblockedList[0]._id : null;
    }

    const unblockedIp = (e) => {
        e.preventDefault();
        setLoading(true);
        api.delete(unblockips + objectId)
        .then((res) => {
            setLoading(false);
            if (res.data.code === 200) {
                getBlockedIps();
                setUnBlockIp(false);
                FetchIphitsList();
                setMessageClass('success');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            } else {
                setMessageClass('error');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            }
        })
        .catch((error) => {
            console.error(error);
            setLoading(false);
            setMessageClass('error');
            setMessage('Something went wrong, Try again.');
            setTimeout(() => {
                setMessage('');
            }, 5000);
        })
    }
   
    return (<>
    {message && <Message message={message} messageClass={messageClass} closeMessage={() => setMessage('')}/>}
    {filterModal && 
            <div className={styles['filter-container']}>
                <div className={styles['filter-background']} onClick={() => setFilterModal(false)}></div>
                <div className={styles['filter-form']}>   
                    <div className={styles['filter-icon']}>
                        <span><i class="fa fa-filter"></i>Filter<span><img src="../images/cancel.svg" alt="times" onClick ={() => setFilterModal(false)}/></span></span>
                        <br/>
                        <form onSubmit={filterFunction}>
                            <label>User ID</label>
                            <input type="text" placeholder="User ID" value={userId} onChange={(e) => setUserId(e.target.value)} required/>
                            <label>IP</label>
                            <input type="text" placeholder="IP" value={clientData} onChange={(e) => setClientData(e.target.value)}/>
                            <label>Date</label>
                            <input type="date" placeholder="Date" value={timestamp} onChange={(e) => setTimestamp(e.target.value)}/>
                            <p className={styles["clear-filter"]} style={css.color} onClick={() => clearFilter()}>Clear</p>
                            { <button type="submit" className="btn mt-3 px-5" style={css.primaryButton} >Apply</button>}
                        </form>
                    </div>
                </div>
            </div>
        }
        {modal && <>
            <div className={styles["modal-container"]}>
                <div className={styles["modal-background"]} onClick={() => setModal(false)}></div>
                <div className={styles["modal-box"]}>
                    <form onSubmit={blockedIP}>
                        <div className={styles["modal-header"]}>
                            Block this IP?
                        </div>
                        <div className={styles["modal-body"]}>
                            <label>User ID</label>
                            <input type="text" value={singleDetail.userId} disabled/>
                            <label>IP address</label>
                            <input type="text" value={singleDetail.clientData} disabled />
                            <label>Hits</label>
                            <input type="text" value={singleDetail.hits} disabled/>
                            <label>Timestamp</label>
                            <input type="text" value={`${new Date(singleDetail.timestamp)}`.substr(0, 24)} disabled/>
                            <label>Updated at</label>
                            <input type="text" value={singleDetail.updatedAt ? `${new Date(singleDetail.updatedAt)}`.substr(0, 24) : `${new Date(singleDetail.timestamp)}`.substr(0, 24)} disabled/>
                            <label>Comments</label>
                            <input type="text" value={comments} onChange={(e) => setComments(e.target.value)} required/>
                        </div>
                        <div className={styles["modal-footer"]}>
                            <div>
                                    <button className="btn" style={css.primaryButton}>Block</button>
                            </div>
                            <div>
                                <button className="btn" style={css.secondaryButton} onClick={() => setModal(false)}>Cancel</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>}
        {unBlockIp &&
            <div className={styles['blockip-container']}>
                <div className={styles['blockip-background']} onClick={() => setUnBlockIp(false)}></div>
                <div className={styles['blockip-box']}>
                <form onSubmit={unblockedIp}>
                    <div className={styles['heading-icon']}>
                        <h4>Unblock Ip</h4>
                        <img src='../images/cancel.svg' alt='cancel' onClick={() => setUnBlockIp(false)} />
                    </div>
                    <div className={styles['image-content']}>
                        <img src='../images/unblock-ip.svg' alt='unblock-ip' />
                    </div>
                    <h3>Are you sure, you want to Unblock the IP?</h3>
                    <div className={styles['bottom-buttons']}>
                        <button className='btn' style={css.secondaryButton} onClick={() => setUnBlockIp(false)}>Cancel</button>
                        {loading
                            ? <button className='btn' style={css.primaryButton} disabled>loading...</button>
                            : <button className='btn' style={css.primaryButton}>Sure</button>
                        }
                    </div>
                </form>
                </div>
            </div>
        }
        <div className="admin-container">
            <div className="admin-dashboard">
                <h1 className="admin-dashboard-heading">IP Hits</h1>
                <div className="admin-dashboard-body">
                    {<>
                        
                            <div className={styles['tab-menu']}>
                                <ul className={styles['tabs']}>
                                    <li style={tabName ===  'all-ips' ? css.active : null} onClick={() => setTabName('all-ips')}>All IPs</li>
                                    <li style={tabName === 'blocked-ips' ? css.active : null} onClick={() => setTabName('blocked-ips')}>Blocked IPs</li>
                                </ul>
                                {
                                    tabName === 'blocked-ips'
                                    ?<div></div>
                                    :<div onClick={() => setFilterModal(true)} className={styles['filter-action']} style={css.color}><span><i class="fa fa-filter"></i></span> Filter</div>
                                }
                            </div>
                            {tabName === 'all-ips' && <>
                            {
                                    filterData 
                                    ?<div className={styles['transactions-box']}>
                                        <div className={styles['all-list-head']}>
                                            <p>#</p>
                                            <p>Created at</p>
                                            <p>Updated at</p>
                                            <p>User ID</p>
                                            <p>IP address</p>
                                            <p>Hits</p>
                                            <p>Status</p>
                                            {/* <p>Details</p> */}
                                            <p>Action</p>
                                        </div>
                                        {
                                            filterList.length >= 1
                                            ?filterList.map((user, i) => (
                                                <div className={styles['all-list-body']} key={i}>
                                                    <p><span>#</span>{i + 1}</p>
                                                    <p><span>Created at</span>{user.timestamp}</p>
                                                    <p><span>Updated at</span>{user.updatedAt}</p>
                                                    <p><span>User ID</span>{user.userId}</p>
                                                    <p><span>IP address</span>{user.clientData}</p>
                                                    <p><span>Hits</span>{user.hits}</p>
                                                    <p className={styles['active-block']}>
                                                        <span>Status:</span>
                                                        {checkBlockedIp(user.clientData) === "Active" && <span className={styles["active"]}>Active</span>}
                                                        {checkBlockedIp(user.clientData) === "Blocked" && <span className={styles["blocked"]}>Blocked</span>}
                                                    </p>
                                                    {/* <p>Status{user.isActive ? <span className={styles["green"]}>Active</span> : <span className={styles["red"]}>Inactive</span>}</p> */}
                                                    <p className={styles['active-block']}>
                                                        {checkBlockedIp(user.clientData) === "Active" && <button className='btn' style={css.primaryButton} onClick={() => fetchSingle(user)}>Block</button>}
                                                        {checkBlockedIp(user.clientData) === "Blocked" && <button className='btn' style={css.secondaryButton} onClick={() => unBlockedModel(user.clientData)}>Unblock</button>}
                                                    </p>
                                                </div>
                                            ))
                                            :<div className={styles['no-data']}>
                                                <p>No data</p>
                                            </div>
                                        }
                                    </div>
                                    :<div className={styles['transactions-box']}>
                                            <div className={styles['all-list-head']}>
                                                <p>#</p>
                                                <p>Created at</p>
                                                <p>Updated at</p>
                                                <p>User ID</p>
                                                <p>IP address</p>
                                                <p>Hits</p>
                                                <p>Status</p>
                                                {/* <p>Details</p> */}
                                                <p>Action</p>
                                            </div>
                                            {
                                                hits.length > 0
                                                ?hits.map((item, i) => (
                                                    <div className={styles['all-list-body']} key={i}>
                                                        <p><span>#</span> {((pageNumber - 1) * pageSize) + i + 1}</p>
                                                        <p><span>Created at:</span> {`${new Date(item.timestamp)}`.substr(0, 16)}</p>
                                                        <p><span>Updated at:</span> {item.updatedAt ? `${new Date(item.updatedAt)}`.substr(0, 16) : `${new Date(item.timestamp)}`.substr(0, 24)}</p>
                                                        <p><span>User ID:</span> {item.userId}</p>
                                                        <p><span>IP address:</span> {item.clientData}</p>
                                                        <p><span>Hits:</span> {item.hits}</p>
                                                        <p className={styles['active-block']}>
                                                            <span>Status:</span>
                                                            {checkBlockedIp(item.clientData) === "Active" && <span className={styles["active"]}>Active</span>}
                                                            {checkBlockedIp(item.clientData) === "Blocked" && <span className={styles["blocked"]}>Blocked</span>}
                                                        </p>
                                                        {/* <p className={styles['view-detail-box']}></p> */}
                                                        <p className={styles['active-block']}>
                                                            {checkBlockedIp(item.clientData) === "Active" && <button className='btn' style={css.primaryButton} onClick={() => fetchSingle(item)}>Block</button>}
                                                            {checkBlockedIp(item.clientData) === "Blocked" && <button className='btn' style={css.secondaryButton}  onClick={() => unBlockedModel(item.clientData)}>Unblock</button>}
                                                        </p>
                                                    </div>
                                                ))
                                                :<div className={styles['no-data']}>
                                                    <p>No data</p>
                                                </div>
                                            }
                                            {totalRecords/pageSize > 1 &&<div className={"pagination-box"}>
                                                {
                                                    pageNumber <= 1
                                                    ?<span className="fa fa-angle-left pagination-arrow-disabled"></span>
                                                    :<span className="fa fa-angle-left pagination-arrow" onClick={() => FetchIphitsList(pageNumber-1)}  ></span>
                                                }
                                                <span> {pageNumber} of {Math.ceil(totalRecords/pageSize)} pages </span>
                                                {
                                                    pageNumber < Math.ceil(totalRecords/pageSize)
                                                    ?<span className="fa fa-angle-right pagination-arrow" onClick={() => FetchIphitsList(pageNumber + 1)} ></span>
                                                    :<span className="fa fa-angle-right pagination-arrow-disabled"></span>
                                                }
                                            </div>}
                                            
                                        </div>
                                    }
                            </>}
                            {tabName === 'blocked-ips' && <div className={styles['transactions-box']}>
                                <div className={styles['blocked-list-head']}>
                                    <p>#</p>
                                    <p>Updated at</p>
                                    <p>User ID</p>
                                    <p>IP address</p>
                                    <p>Comments</p>
                                    {/* <p>Details</p> */}
                                </div>
                                {
                                    blockIps.length > 0
                                    ?blockIps.map((item, i) => (
                                        <div className={styles['blocked-list-body']} key={i}>
                                            <p><span>#</span> {i + 1}</p>
                                            <p><span>Updated at:</span> {`${new Date(item.updatedAt)}`.substr(0, 16)}</p>
                                            <p><span>User ID:</span> {item.userId}</p>
                                            <p><span>IP address:</span> {item.ip}</p>
                                            <p><span>Comments:</span> {item.comments}</p>
                                            {/* <p className={styles['view-detail-box']} >View Details</p> */}
                                        </div>
                                    ))
                                    :<div className={styles['no-data']}>
                                        <p>No data</p>
                                    </div>
                                }
                            </div>}
                    </>}
                </div>
            </div>
        </div>
    </>);
}