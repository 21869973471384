import React, { useState, useEffect,} from 'react';
import ApiLogs from "../../../Pages/UserAccounts/Components/ApiLogs";
import Credits from '../../../Pages/UserAccounts/Components/Credits';
import APITransactions from '../../../Pages/UserAccounts/Components/APITransactions';
import ApiPrice1 from "../../../Pages/UserAccounts/Components/ApiPrice1";
import Settings from "../../../Pages/UserAccounts/Components/Settings"
import oceanapi, { trulogAddressUrl }  from '../../../Base/OceanConfig';
import styles from "../../../Pages/UserAccounts/user-accounts.module.css";
import ProductTransactions from '../../../Pages/UserAccounts/Components/ProductTransactions';
import ProductLogs from '../../../Pages/UserAccounts/Components/ProductLogs';
import { getSuperAdminToken } from '../../../Utils/Common';
import superadminapi , {fetchDefaultPrice} from '../../../Base/SuperAdminConfig';


export default function UserDetail(props) {
    const [ trulogAddressName, setTrulogAddressName ] = useState('');
    const [ trulogAddress, setTrulogAddress ] = useState('');
    const [ defaultPriceList, setDefaultPriceList ] = useState([]);
    const [ transactions, setTransaction ] = useState(true);
    const [ credits, setCredits ] = useState(false);
    const [ settings, setSettings ] = useState(false);
    const [ apiLogs, setApiLogs ] = useState (false);
    const [ price, setPrice ] = useState(false);
    const [ productTransactions, setProductTransactions] = useState(false);
    const [ productLogs, setProductLogs] = useState(false);
    const userDetail = JSON.parse(sessionStorage.getItem('admin-user-detail'));
    const superAdminToken = getSuperAdminToken();

    const getAddress = () => {
        oceanapi.get(trulogAddressUrl + userDetail.userId)
        .then((res) => {
            if(res.data.code === 200){
                setTrulogAddress(res.data.result);
                setTrulogAddressName(res.data.result.addressName)
            } else {
                // Do nothing
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    const fetchDefaultPrices = () => {
        superadminapi.get(fetchDefaultPrice)
        .then((res) => {
            if(res.data.code === 200){
                setDefaultPriceList(res.data.result);
            }
        })
        .catch((error) => {
            console.error(error);
        })
    }

    useEffect(() => {
        getAddress();
        fetchDefaultPrices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const changeTab = (v1, v2, v3, v4, v5, v6,v7) => {
        setTransaction(v1);
        setApiLogs(v2);
        setProductTransactions(v3);
        setProductLogs(v4);
        setPrice(v5);
        setCredits(v6);
        setSettings(v7);
    }

    return (
        <div className="admin-dashboard">
            <h1 className="admin-dashboard-heading">User Detail</h1>
            <div className="admin-dashboard-body">
                <p className={styles['back-link']} style={{cursor:'pointer'}} onClick={()=>props.setUserClientDetails(false)}><i className="fa fa-angle-double-left"></i> Back</p>
                <div className={styles['user-detail-main-box']}>
                    <div className={styles['image-content-box']}>
                        <div>
                            <img src='../images/admin/user-account-logo.svg' alt='user-logo' />
                        </div>
                        <div className={styles['user-information']}>
                            <p>User id: <span>{userDetail.userId}</span></p>
                            <p>Name: <span>{userDetail.fullName}</span></p>
                            <p>Email: <span>{userDetail.email}</span></p>
                            <p>Phone no: <span>{userDetail.phone}</span></p>
                            {trulogAddressName && <p>Address name: <span>{trulogAddressName}</span></p>}
                            {trulogAddress.address1 && <p>Address: <span>
                                {trulogAddress.address1?<>{trulogAddress.address1 + ", "}</>:null}
                                {trulogAddress.address2?<>{trulogAddress.address2 + ", "}</>:null}
                                {trulogAddress.address3?trulogAddress.address3:null}
                            </span></p>}
                        </div>
                    </div>
                    <div className={styles['menu-points']}>
                        <p onClick={() => changeTab(true, false, false, false, false, false, false)} style={transactions?{color:"#FF7D90",borderColor:"#FF7D90"}:null}>API Transactions</p>
                        <p onClick={() => changeTab(false, true, false, false, false, false, false)} style={apiLogs?{color:"#FF7D90",borderColor:"#FF7D90"}:null}>API Logs</p>
                        <p onClick={() => changeTab(false, false, true, false, false, false, false)} style={productTransactions?{color:"#FF7D90",borderColor:"#FF7D90"}:null}>Product Transactions</p>
                        <p onClick={() => changeTab(false, false, false, true, false, false, false)} style={productLogs?{color:"#FF7D90",borderColor:"#FF7D90"}:null}>Product Logs</p>
                        <p onClick={() => changeTab(false, false, false, false, true, false, false)} style={price?{color:"#FF7D90",borderColor:"#FF7D90"}:null}>API Price</p>
                        <p onClick={() => changeTab(false, false, false, false, false, true, false)} style={credits?{color:"#FF7D90",borderColor:"#FF7D90"}:null}>Credits</p>
                        <p onClick={() => changeTab(false, false, false, false, false, false, true)} style={settings?{color:"#FF7D90",borderColor:"#FF7D90"}:null}>Settings</p>
                    </div>
                    {transactions && <APITransactions userId={userDetail.userId} defaultPriceList={defaultPriceList} />}
                    {apiLogs && <ApiLogs userId={userDetail.userId} defaultPriceList={defaultPriceList} />}
                    {productTransactions && <ProductTransactions userId={userDetail.userId} defaultPriceList={defaultPriceList} />}
                    {productLogs && <ProductLogs userId={userDetail.userId} defaultPriceList={defaultPriceList} />}
                    {price && <ApiPrice1 userId={userDetail.userId}  defaultPriceList={defaultPriceList} adminUsername={props?.userName} superAdminToken={superAdminToken} parentId={props.parentId} />}
                    {credits && <Credits userId={userDetail.userId} adminUsername={props?.userName} superAdminToken={superAdminToken}/>}
                    {settings && <Settings details={userDetail} /> }
                </div>
            </div>
        </div>
    );
}