import React from 'react'

const TickList = ({heading,list,forCardPrice}) => {
  return (
    <div className='mt-6'>
        <p className={(forCardPrice?"text-xs font-medium text-tickListGreyColor mb-3":"text-base font-bold text-tickListGreyColor mb-3")}>{heading}: </p>
        <div className="">
        {
            list.map(({name,color},ind)=>{
                return <p key={ind+new Date().getTime} className={((forCardPrice?"text-xs font-medium grid grid-cols-tickCardForPriceCard mb-2":'font-medium grid grid-cols-tickCard'))}><span className={forCardPrice?"mr-1 w-10 translate-y-1":'mr-1 w-11 translate-y-2'}><img src="../images/landing_page/tick.svg" alt="tick" /></span  > <span className={color.toLowerCase()==="grey"?"text-tickListGreyColor":"text-tickListBlackColor"}> {name} </span></p>
            })
        }
        </div>
    </div>
  )
}

export default TickList