import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CopyToClipboard } from "react-copy-to-clipboard";
import Message from '../../../../Components/Message/Message';
import style from '../../signup.module.css';

export default function Congratulations(){
    const navigate = useNavigate();
    const subdomain = sessionStorage.getItem("subdomain");
    const [ message, setMessage ] = useState('');
    const [ messageClass, setMessageClass ] = useState('');
    const gotoLogin = () => navigate('/auth');

    const copyKey = () => {
        setMessageClass('success');
        setMessage("Copied to clipboard");
        setTimeout(() => {
            setMessage('')
        }, 5000);
    }
    
    return (<>
        {message && <Message message={message} messageClass={messageClass} closeMessage={() => setMessage('')} />}
        <div className={style['whitelabel-link']}>
            <h3>Whitelabel link</h3>
            <p>Congratulations on generating your whitelabel link!. here you get link for admin & User side dashboard. With our whitelabel solution, you have the power to inject your brand's unique identity into our premium products/services.</p>
            <div className={style['admin-dashboard']}>
                <h3>Admin dashboard</h3>
                <div className={style['admin-box']}>
                    <div>
                        <span>Here is your admin side dashboard link</span>
                    </div>
                    <div className={style['dashboard-button']}>
                        <button className='btn primary-button' onClick={() => gotoLogin()}>Go to dashboard <img src="./images/arrow.svg" alt="arrow"/></button>
                    </div>
                </div>
            </div> 
        </div>    
        <div className={style['user-dashboard']}>
            <h3>User dashboard</h3>
            <div className={style['user-box']}>
                <div>
                    <span>{localStorage.getItem("subdomain")}</span>
                </div>
                <div className={style['image-icon']}>
                    <CopyToClipboard text={`https://${subdomain}`}>
                        <img src='./images/copy-icon.png' onClick={copyKey} alt=''/>
                    </CopyToClipboard>
                </div>
            </div>
        </div>
        <p className={style['note']}>Note : you can also access this ‘user dashboard’ link in Admin side dashboard later</p>
    </>);
}