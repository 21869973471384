import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import APIPrice from "./Components/APIPrice"
import UserAccounts from "./Components/UserAccounts";
import AddCredits from './Components/AddCredits';
import ApiPricing from './Components/ApiPricing';
import DocumentationLink from './Components/DocumentationLink';
import Settings from './Components/Settings';
import styles from "./client-details.module.css";

export default function ClientDetails() {
    const navigate = useNavigate();
    const details = JSON.parse(sessionStorage.getItem("client"));
    const [ btnName, setBtnName ] = useState("Plan details");
 
    return (<>
        <p className={styles['back-to-clients']}onClick={() => navigate('/superadmin/clients')}> <span className="fa fa-angle-double-left"></span> Back to clients </p>
        <div className={styles['client-details']}>
            <p><span>Name</span> {details.firstName + " " + details.lastName}</p>
            <p><span>Email</span> {details.email}</p>
            <p><span>Phone</span> {details.phoneNumber}</p>
            <p><span>User name</span> {details.userName}</p>
            <p><span>User id</span> {details.userId}</p>
            <p><span>Plan</span> {details.currentPlan.plan}</p>
        </div>
        <div className={styles['UserContainer']}>
            <div className={styles['sideMenu']}>
                <div className={styles['menu-option']}>
                    <p onClick={() => setBtnName("Plan details")} className={styles[btnName === "Plan details" ? "menu-active" : ""]}>PlanDetails { btnName === "Plan details" && <span className="fa fa-angle-right"></span> }</p>
                    <p onClick={() => setBtnName("User accounts")} className={styles[btnName === "User accounts" ? "menu-active" : ""]}>Users { btnName === "User accounts" && <span className="fa fa-angle-right"></span> }</p>
                    <p onClick={() => setBtnName("Add credits")} className={styles[btnName === "Add credits" ? "menu-active" : ""]}>AddCredits { btnName === "Add credits" && <span className="fa fa-angle-right"></span> }</p>
                    <p onClick={() => setBtnName("Api pricing")} className={styles[btnName === "Api pricing" ? "menu-active" : ""]}>ApiPricing { btnName === "Api pricing" && <span className="fa fa-angle-right"></span> }</p>
                    <p onClick={() => setBtnName("Documentation link")} className={styles[btnName === "Documentation link" ? "menu-active" : ""]}>Documentation { btnName === "Documentation link" && <span className="fa fa-angle-right"></span> }</p>
                    <p onClick={() => setBtnName("Settings")} className={styles[btnName === "Settings" ? "menu-active" : ""]}>Settings { btnName === "Settings" && <span className="fa fa-angle-right"></span> }</p>
                    {/* <p onClick={() => setBtnName("Services details")} className={styles[btnName === "Services details" ? "menu-active" : ""]}>Services { btnName === "Services details" && <span className="fa fa-angle-right"></span> }</p>
                    <p onClick={() => setBtnName("Overall transactions")} className={styles[btnName === "Overall transactions" ? "menu-active" : ""]}>Transactions { btnName === "Overall transactions" && <span className="fa fa-angle-right"></span> }</p>
                    <p onClick={() => setBtnName("Trubank")} className={styles[btnName === "Trubank" ? "menu-active" : ""]}>Trubank { btnName === "Trubank" && <span className="fa fa-angle-right"></span> }</p> */}
                </div>
            </div>
            <div className={styles['detail-card']}>
                {btnName === "Plan details" && <APIPrice/>}
                {btnName === "User accounts" && <UserAccounts details={details}/>}
                {btnName === "Add credits" && <AddCredits details={details}/>}
                {btnName === "Api pricing" && <ApiPricing details={details}/>}
                {btnName === "Documentation link" && <DocumentationLink details={details}/>}
                {btnName === "Settings" && <Settings details={details}/>}
                {/* {btnName === "Services details" && <h4>Service Details</h4>}
                {btnName === "Overall transactions" && <h4>Overall Transaction</h4>}
                {btnName === "Trubank" && <h4>Trubank</h4>} */}
            </div>
        </div>
    </>)
}