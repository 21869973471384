import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import oceanapi, { adminDetails } from '../../Base/OceanConfig';
import Steps from './Components/Steps/Steps';
import Message from '../../Components/Message/Message';
import style from './signup.module.css';

export default function Whitelabel(){
    const navigate = useNavigate();
    const [ firstname, setFirstName ] = useState('');
    const [ lastname, setLastName ] = useState('');
    const [ phoneNumber, setPhoneNumber ] = useState('');
    const [ email, setEmail] = useState('');
    const [ userName, setUserName] = useState('');
    const [ password, setPassword] = useState('');
    const [ extraction, setExtraction ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ message, setMessage ] = useState('');
    const [ messageClass, setMessageClass ] = useState('');

    const loginFunction = () => {
        navigate("/auth");
    }

    const adminBasicDetail = (e) => {
        e.preventDefault();
        setLoading(true);
        oceanapi.post(adminDetails, {
            firstName: firstname,
            lastName: lastname,
            email: email,
            phoneNumber: phoneNumber,
            userName: userName,
            password: password
        })
        .then((res) => {
            setLoading(false);
            if(res.data.code === 200){
                setExtraction(true);
                setMessageClass('success');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('')
                }, 5000);
            } else {
                setMessageClass('error');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('')
                }, 5000);
            }
        })
        .catch((error) => {
            setLoading(false);
            console.error(error);
            setMessageClass('error');
            setMessage("Something went wrong, Try again!.");
            setTimeout(() => {
                setMessage('')
            }, 5000);
        })
    }
    
    const closeModal = () => {
        setExtraction(false);
        setFirstName('');
        setLastName('');
        setPhoneNumber('');
        setEmail('');
        setUserName('');
        setPassword('');
    }

    return (<>
        {message && <Message message={message} messageClass={messageClass} closeMessage={() => setMessage('')} />}
        {extraction &&
            <div className={style['whitelabel-container']}>
                <div className={style['whitelabel-background']} onClick={() => closeModal()}></div>
                <div className={style['pop-up-form-whitelabel']}>
                    <img src='images/verify-email.png' alt="extraction" />
                    <h4>Verify email !</h4>
                    <p>We will send you a link to verify your email address, click on it to confirm </p>
                </div>
            </div>
        }
        <section id={style['banner-image']}>
            <div className='container'>
                <div className={style['already-account']}>
                    <div>
                        <img src='./images/logo.png' alt='' />
                    </div>
                    <div>
                        <p>Already have an account? <span onClick={() => loginFunction()}>Log In</span></p>
                    </div>
                </div> 
                <div className={style['own-platform']}>
                    <h4>Want to own the platform ?</h4>
                    <h6> Get your own whitelabel platform within less than <span>10 minutes</span></h6>
                </div>
            </div>
        </section>
        <section id={style['signup-form']}>
            <div className='container'>
                <div className={style['details']}>
                    <h2 className={style['whitelabel-heading']}>Signup for whitelabel</h2>
                    {/* Pass step number in props */}
                    <Steps step={1} signup={true} />
                    <div className={style['basic-details']}>
                        <h5 className={style['step-heading']}>Basic details</h5>
                        <form onSubmit={adminBasicDetail}>
                            <div>
                                <label>First Name</label>
                                <input type="text" placeholder='Enter Name' value={firstname} onChange={e => setFirstName(e.target.value)} required/>
                            </div>
                            <div>
                                <label>Last Name </label>
                                <input type="text" placeholder='Enter Last Name' value={lastname} onChange={e => setLastName(e.target.value)} required/>
                            </div>
                            <div>
                                <label>Phone no</label>
                                <input type="text" placeholder='Enter phone no.' value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} required/>
                            </div>
                            <div>
                                <label>Email</label>
                                <input type="text" placeholder='Enter email' value={email} onChange={e => setEmail(e.target.value)} required/>
                            </div>
                            <div>
                                <label>user name</label>
                                <input type="text" placeholder='Enter Username' value={userName} onChange={e => setUserName(e.target.value)} required/>
                            </div>
                            <div>
                                <label>Set Password</label>
                                <input type="text" placeholder='Enter password' value={password} onChange={e => setPassword(e.target.value)} required/>
                            </div>
                            <div></div>
                            <div className={style['signup-button']}>
                                {
                                    loading
                                    ?<button className='btn primary-button' disabled>Wait...</button>
                                    :<button type="submit" className='btn primary-button'>Next <img src="./images/arrow.svg" alt="arrow" /></button>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </>);
}