import React, { useState } from 'react'
import TickList from '../TickList/TickList';
import styles from "./priceCard.module.css"
import { useLocation } from "react-router-dom";
const PriceCard = ({imgIconLink,headerTitle,headerContent,monthlyPrice,yearlyPrice,cardColor,tickListDataHeader,tickListDataList,offDiscount,buyNowAction,talkToSalesAction}) => {
    const [cardPrice,setCardPrice]=useState(monthlyPrice);
    const [isMonthlyPrice,setIsMonthlyPrice] = useState(true);
    let location = useLocation();
  return (      
        <div className={styles[`${cardColor+"Cnt"}`]}>
            <div className="flex flex-col justify-between h-full">
            <div className="h-full">
                <p className='flex items-center text-xl font-bold'><span className='mr-2'> <img src={imgIconLink} alt="icon" /> </span><span> {headerTitle} </span></p>
                <p className='mt-3 text-xs font-medium text-priceCardGrey'>{headerContent}</p>
                <p className='mt-4 text-xs font-medium text-priceCardGrey'>starts at</p>
                <p><span className='text-priceCardBlue text-2xl'>Rs {cardPrice}</span><span className='text-xs font-medium text-priceCardGrey'> /mo</span></p>
                <p className='text-xs font-medium text-priceCardGrey'>Billed monthly</p>
                <div className="mt-3 text-xs rounded-md	grid grid-cols-2 cursor-pointer bg-priceCardBgGrey">
                    <div className={styles[isMonthlyPrice?`${cardColor+"MonthlyPrice"}`:"priceSelection"]} onClick={()=>{setCardPrice(monthlyPrice); setIsMonthlyPrice(true);}}>
                    <div className="p-3" >
                        <p className='text-priceCardBlue'>Pay monthly</p>
                        <p className='text-priceCardGrey'>commit monthly</p>   
                    </div>
                    </div>
                    <div  className={styles[!isMonthlyPrice?`${cardColor+"YearlyPrice"}`:"priceSelection"]} onClick={()=>{setCardPrice(yearlyPrice); setIsMonthlyPrice(false);}}>
                    <div className="p-3" >
                        <p className='text-priceCardBlue'>Pay yearly</p>
                        <p className='text-priceCardGrey'>Commit monthly</p>
                        <p className='text-priceCardGreen'>Save {offDiscount}%</p>
                    </div>
                    </div>
                </div>
                <TickList heading={tickListDataHeader} list={tickListDataList} forCardPrice={true} />
            </div>
                <div className="text-base font-bold mt-20">
                    {/* changes done for speradmin */}
                    {location.pathname==="/superadmin/planDetails"?
                        <>
                     
                            <button className={styles[`${cardColor+"BuyBtn"}`]} >
                            {cardColor==="standardCard"?"Activated": "Upgrade"}
                            </button>
                 
                        </>
                        // this part was already there in code
                        :   <>
                            <button className={styles[`${cardColor+"BuyBtn"}`]} onClick={buyNowAction}>
                            Buy now
                            </button>
                            <button className={styles[`${cardColor+"TalkBtn"}`]} onClick={talkToSalesAction}>
                            Talk to sales
                            </button>
                            </>
                    }
                </div>
            </div>
        </div>
  )
}

export default PriceCard