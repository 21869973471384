import React, { useContext, useState } from 'react';
import styles from "../../user-accounts.module.css";
import api, { exportUserproductionTransactions } from '../../../../Base/OceanConfig';
import Message from '../../../../Components/Message/Message';
import { ColorContext } from '../../../../Components/ColorContext/ColorContext';

export default function Export(props) {
    const { userName } = useContext(ColorContext);
    const { css } = useContext(ColorContext);
    const [ fromDate, setFromDate ] = useState("");
    const [ toDate, setToDate ] = useState("");
    const [ exportModal, setExportModal ] = useState(false);
    const [ exportData, setExportData ] = useState(false);
    const [ totalCount, setTotalCount ] = useState("");
    const [ url, setUrl ] = useState("");
    const [ loading, setLoading ] = useState(false);
    const [ messageClass, setMessageClass ] = useState('');
    const [ message, setMessage ] = useState('');

    const exportFunction = (e) => {
        e.preventDefault();
        setLoading(true);
        api.get(exportUserproductionTransactions + `userId=${props.userId}&userName=${userName}&fromDate=${fromDate}&toDate=${toDate}`)
        .then((res) => {
            setLoading(false);
            if(res.data.code === 200){
                setExportData(true);
                setTotalCount(res.data.totalRecords);
                setUrl(res.data.url);
                setMessageClass('success');
                setMessage('CSV is ready to download');
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            } else {
                setExportData(false);
                setTotalCount('');
                setUrl('');
                setMessageClass('error');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            }
        })
        .catch((error) => {
            setLoading(false);
            console.error(error);
            setMessageClass('error');
            setMessage("Something went wrong, Try again!.");
            setTimeout(() => {
                setMessage('');
            }, 5000);
        })
    }

    const changeDate = () => {
        setExportData(false);
        setTotalCount("");
        setUrl("");
    }

    const closeModal = () => {
        setExportData(false);
        setExportModal(false);
        setTotalCount("");
        setUrl("");
        setFromDate("");
        setToDate("");
    }

    const getCurrentDate = () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const getTomorrowDate = () => {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        const year = tomorrow.getFullYear();
        const month = String(tomorrow.getMonth() + 1).padStart(2, '0');
        const day = String(tomorrow.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const closeMessage = () => { setMessage('') }

    return (<>
        {message && <Message message={message} messageClass={messageClass} closeMessage={closeMessage}/>}
        {exportModal && <div className={styles['export-csv-container']}>
            <div className={styles['export-csv-background']} onClick={() => closeModal()}></div>
            <div className={styles['export-csv-box']}>
                {
                    exportData
                    ?<div className="text-center">
                        <img src="../images/download.svg" alt="download" />
                        <p>Transactions from: <span>{`${new Date(fromDate)}`.substr(0, 15)}</span></p>
                        <p>Transactions to: <span>{`${new Date(toDate)}`.substr(0, 15)}</span></p>
                        <p>Transactions count: <span>{totalCount}</span></p>
                        <div className={styles["export-buttons"]}>
                            <button className="btn" style={css.secondaryButton} onClick={() => changeDate()}>Back</button>
                            <a href={url} target="_blank" rel="noreferrer"><button className="btn" style={css.primaryButton}>Download</button></a>
                        </div>
                    </div>
                    :<>
                        <form onSubmit={exportFunction}>
                            <img src="../images/cancel.svg" alt="times" onClick={() => closeModal()}/>
                            <h3>Export CSV</h3>
                            <label>From date</label>
                            <input type="date" value={fromDate} max={getCurrentDate()} onChange={(e) => setFromDate(e.target.value)} required/>
                            <label>To date</label>
                            <input type="date" value={toDate} max={getTomorrowDate()} onChange={(e) => setToDate(e.target.value)} required/>
                            {
                                loading
                                ?<button className="btn" style={css.primaryButton} disabled>Submit</button>
                                :<button type="submit" className="btn" style={css.primaryButton}>Submit</button>
                            }
                        </form>
                    </>
                }
            </div>
        </div>}
        <div className="text-right p-3">
            <button className="btn" style={css.primaryButton} onClick={() => setExportModal(true)}>Export to CSV</button>
        </div>
    </>)
}