import React, { useState, useEffect, useContext } from 'react';
import { getAdminToken, getAdminUser } from '../../../Utils/Common';
import oceanapi, {customDefaultPrice, adminPlan, getUserPrices, changePrice,} from '../../../Base/OceanConfig';
import Loading from '../../../Components/Loading/Loading';
import styles from "../user-accounts.module.css";
import Message from '../../../Components/Message/Message';
import { ColorContext } from '../../../Components/ColorContext/ColorContext';

export default function ApiPrice(props){
    const userId = props.userId;
    const { userName } = useContext(ColorContext);
    // const defaultPriceList = props.defaultPriceList;
    const token = getAdminToken();
    const parentId = getAdminUser();
    const [ userList, setUserList ] = useState([]);
    const [ loading, setLoading ] = useState(true);
    const [ planPrice, setPlanPrice ] = useState(null);
    const [ price, setPrice ] = useState('');
    const [ selectedService, setSelectedService ] = useState('');
    const [ message, setMessage ] = useState('');
    const [ messageClass, setMessageClass ] = useState('');
    const [ defaultPriceList, setDefaultPriceList ] = useState();
    let count = 1;

    const config = {
        headers: {
            'token': props?.superAdminToken? props?.superAdminToken :token
        }
    }

    useEffect(() => {
        fetchCustomDefaultPrice();
        fetchPlanDetails();
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchCustomDefaultPrice =()=>{
        oceanapi.get(customDefaultPrice + `${props.adminUsername ? props.adminUsername : userName}`)
        .then((res)=>{
            setLoading(false);
            if(res.data.code === 200){
               setDefaultPriceList(res.data.result)
            } else {
                // setPlanDetails('no-plan');
            }
        })
        .catch((error) => {
            setLoading(false);
            console.log(error);
        })
    }

    const fetchPlanDetails = () => {
        oceanapi.get(adminPlan + `${props.adminUsername ? props.adminUsername : userName}`)
        .then((res) => {
            setLoading(false);
            if(res.data.code === 200){
                apiPriceFunction(res.data.result[res.data.result.type], res.data.result.products);
            } else {
                // setPlanDetails('no-plan');
            }
        })
        .catch((error) => {
            setLoading(false);
            console.log(error);
        })
    }

    const apiPriceFunction = (a, b) => {
        var newArray = [];
        Object.keys(a).map((x, j) => (
            newArray.push({
                id: j,
                price: a[x],
                name: x
            })
        ))
        // productPriceFunction(newArray, b);
        setPlanPrice(newArray);
        fetchUserFunction();
    }

    // const productPriceFunction = (a, b) => {
    //     var newArray = a;
    //     Object.keys(b).map((item, i) => (
    //         Object.keys(b[item]).map((x, j) => (
    //             newArray.push({
    //                 id: j,
    //                 price: b[item][x],
    //                 name: x
    //             })
    //         ))
    //     ))
    //     setPlanPrice(newArray);
    //     fetchUserFunction();
    // }

    const fetchUserFunction = () => {
        oceanapi.get(getUserPrices + userId)
        .then((res) => {
            setLoading(false);
            if(res.data.code === 200){
                setUserList(res.data.result);
            } else {
                setUserList([]);
            }
        })
        .catch((error) => {
            setLoading(false);
            console.error(error);
        })
    }

    const fetchDefault =(x)=>{
        const filterArray = defaultPriceList.filter(item => item.services === x);
        if(filterArray.length > 0){
            return filterArray[0].amount;
        }
    }

    const fetchPID =(x)=>{
        const filterArray = defaultPriceList.filter(item => item.services === x);
        if(filterArray.length > 0){
            return filterArray[0].PID;
        }
    }

    const fetchPlanAmount = (x, y) => {
        const filterArray = planPrice?.filter(item => item.name === x);
        if(filterArray?.length > 0){
            if(!y){
                count ++;
            }
            return filterArray[0].price;
        } else {
            return 'NA'
        }
    }

    const fetchAmount = (x) => {
        const filterArray = userList.filter(item => item.services === x);
        if(filterArray.length > 0){
            return filterArray[0].amount;
        } else {
            return '-'
        }
    }

    const fetchSelectionDetails = (x) => {
        changePriceFunction(x);
    }

    const changePriceFunction = (x) => {

        oceanapi.post(changePrice, {
            userId: userId,
            parentId: props.parentId? props.parentId : parentId,
            apiName: x.apiName,
            services: x.services,
            PID: x.PID,
            amount: price
        }, config)
        .then((res) => {
            setLoading(false);
            if(res.data.code === 200){
                setPrice('');
                setSelectedService('');
                fetchUserFunction();
                setMessageClass('success');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            } else {
                setMessageClass('error');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            }
        })
        .catch((error) => {
            setLoading(false);
            console.error(error);
            setMessageClass('error');
            setMessage("Something went wrong, Try again!.");
            setTimeout(() => {
                setMessage('');
            }, 5000);
        })
    }

    const changeAmount = (x, y) => {
        setPrice(x);
        setSelectedService(y);
    }

    return (<>
        {message && <Message message={message} messageClass={messageClass} closeMessage={() => setMessage('')}/>}
        <div className={styles['api-price-head']}>
            <p><span>#</span></p>
            <p><span>Date</span></p>
            <p><span>Category</span></p>
            <p><span>PID</span></p>
            <p><span>API name</span></p>
            <p><span>My plan</span></p>
            <p><span>Default prices</span></p>
            <p><span>Current price</span></p>
            <p><span>Change price</span></p>
        </div>
        {
            loading
            ?<Loading />
            :<>
                {
                    defaultPriceList?.length > 0
                    ?<>
                        {defaultPriceList.map(item => (
                            <>
                                {fetchPlanAmount(item.services, 'checking') !== 'NA' && <div className={styles['api-price-entries']} key={item.services}>
                                    <p><span># </span> {count}</p>
                                    <p><span>Date: </span> {`${new Date(item.createdAt)}`.substr(0, 24)}</p>
                                    <p><span>Category: </span> {item.category}</p>
                                    <p><span>PID: </span> {fetchPID(item.services)}</p>
                                    <p><span>API name: </span> {item.apiName}</p>
                                    <p><span>My plan: </span> {fetchPlanAmount(item.services)}</p>
                                    <p><span>Default prices: </span> {fetchDefault(item.services)}</p>
                                    <p><span>Current Price: </span> {fetchAmount(item.services)}</p>
                                    <p className={styles['input']}><span>Change Price:</span><input min="0" type="number" value={selectedService === item.services ? price : ''} onChange={(e) => changeAmount(e.target.value, item.services)} /></p>
                                    {
                                        selectedService === item.services
                                        ?<p><span className={styles['save']} style={{color:"#FF7D90"}} onClick={() => fetchSelectionDetails(item)}>Save</span></p>
                                        :null
                                    }
                                </div>}
                            </>
                        ))}
                    </>
                    :<div className="no-records">
                        <p>No apis available</p>
                    </div>
                }
            </>
        }
    </>);
}
