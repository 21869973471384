import React, { useEffect, useState } from 'react';
import oceanapi, { getdetailsmtp, smtpDetails } from '../../../Base/OceanConfig';
import { getAdminUser,  getUserName } from '../../../Utils/Common';
import Message from '../../../Components/Message/Message';
import styles from '../my-account.module.css';

export default function SMTP(props){
    const css = props.css;
    const userId = getAdminUser();
    const userName = getUserName();
    const [ port, setPort ] = useState('');
    const [ host, setHost ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ active, setActive ] = useState(true);
    const [ from, setFrom ] = useState('');
    const [ companyName, setCompanyName ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ secureConnection, setSecureConnection ] = useState(true);
    const [ showPassword, setShowPassword ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ message, setMessage ] = useState('');
    const [ messageClass, setMessageClass ] = useState('');

    const getsmtpDetails = () => {
        oceanapi.get(getdetailsmtp + userId)
        .then((res) => {
            if(res.data.code === 200){
                setPort(res.data.result.port);
                setHost(res.data.result.host);
                setEmail(res.data.result.user);
                setPassword(res.data.result.pass);
                setSecureConnection(res.data.result.secureConnection);
                setActive(res.data.result.active);
                setFrom(res.data.result.from);
                setCompanyName(res.data.result.companyName);
            } else {
                setPort('');
                setHost('');
                setEmail('');
                setPassword('');
                setSecureConnection('');
            }
        })
        .catch((error) => {
            console.error(error);
        })
    }

    useEffect(() => {
        getsmtpDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const addSMTPDetails = (e) => {
        e.preventDefault();
        setLoading(true);
        oceanapi.post(smtpDetails, {
            userId: userId,
            host: host,
            port: port,
            secureConnection: secureConnection,
            user: email,
            userName: userName,
            pass: password,
            from: from,
            companyName: companyName,
            active: active
        })
        .then((res) => {
            setLoading(false);
            if(res.data.code === 200){
                setMessageClass('success');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('')
                }, 5000);
            } else {
                setMessageClass('error');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('')
                }, 5000);
            }
        })
        .catch((error) => {
            console.error(error);
            setLoading(false);
            setMessageClass('error');
            setMessage("Something went wrong, Try again!.");
            setTimeout(() => {
                setMessage('')
            }, 5000);
        })
    }

    const passwordToggle = () => setShowPassword(!showPassword);

    return(<>
        {message && <Message message={message} messageClass={messageClass} closeMessage={() => setMessage('')} />}
        <h5 className={styles['my-account-details-heading']}>SMTP details</h5>
        <form onSubmit={addSMTPDetails}>
            <div className='row'>
                <div className='col-xs-12 col-md-6'>
                    <label>Host</label>
                    <input type='text' placeholder='Enter host' value={host} onChange={(e) => setHost(e.target.value)} required/>
                    <label>E-mail or Username</label>
                    <input type='text' placeholder='Enter email' value={email} onChange={(e) => setEmail(e.target.value)} required/>
                    <label>From</label>
                    <input type='text' placeholder='email' value={from} onChange={e => setFrom(e.target.value)} required/>
                </div>
                <div className='col-xs-12 col-md-6'>
                    <label>Port</label>
                    <input type='text' placeholder='Enter port' value={port} onChange={(e) => setPort(e.target.value)} required/>
                    <label>Password</label>
                    <input type={showPassword ? 'text' : 'password'} placeholder='Enter password' value={password} onChange={(e) => setPassword(e.target.value)} required/>
                    <div className={styles['password-eye']}>
                        <i className={showPassword ? "fa fa-eye" : "fa fa-eye-slash"} onClick={() => passwordToggle()}></i>
                    </div>
                    <label>Company Name </label>
                    <input type='text' placeholder='Company Name ' value={companyName } onChange={e => setCompanyName (e.target.value)} required/>
                </div>
                <div className='col-xs-12 col-md-6'>
                    <label>Secure connection</label><br/>
                    <input type="radio" name="option" checked={secureConnection && 'checked'} onClick={() => setSecureConnection(true)}/> Yes &nbsp; &nbsp;
                    <input type="radio" name="option" checked={!secureConnection && 'checked'} onClick={() => setSecureConnection(false)}/> No
                </div>
                <div className='col-xs-12 col-md-6'>
                    <label>Active</label><br/>
                    <input type="radio" name="active" checked={active && 'checked'} onClick={() => setActive(true)}/> Yes &nbsp; &nbsp;
                    <input type="radio" name="active" checked={!active && 'checked'} onClick={() => setActive(false)}/> No
                </div>
                <div className='col-xs-12 col-md-6'></div>
                <div className='col-xs-12 col-md-6 text-right'>
                    {
                        loading
                        ?<button className="btn" style={css.primaryButton} disabled>wait ...</button>
                        :<button type="submit" className="btn" style={css.primaryButton}>Save</button>
                    }
                </div>
            </div>
        </form>
    </>);
}