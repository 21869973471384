import React, { useState } from 'react';
import { getAdminUser, setAdminToken } from '../../../../Utils/Common';
import oceanapi, { getUserName, subdomainUpdation } from '../../../../Base/OceanConfig';
import Message from '../../../../Components/Message/Message';
import style from '../../signup.module.css';

export default function UpdateDomain(props){
    const userId = getAdminUser();
    const userName = sessionStorage.getItem("userName");
    const [ subdomain, setSubdomain] = useState(sessionStorage.getItem("userName"));
    const [ loader, setLoader ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ message, setMessage ] = useState('');
    const [ messageClass, setMessageClass ] = useState('');

    const saveSubdomain = (x) => sessionStorage.setItem("subdomain", x);

    const checkDomainName = () => {
        setLoader(true);
        oceanapi.get(getUserName + subdomain + '.invincibleocean.io')
        .then((res) => {
            setLoader(false);
            if(res.data.code === 400){
                setMessageClass('success');
                setMessage('This username is available.');
                setTimeout(() => {
                    setMessage('')
                }, 5000);
            } else {
                setMessageClass('error');
                setMessage('This username is not available.');
                setTimeout(() => {
                    setMessage('')
                }, 5000);
            }
        })
        .catch((error) => {
            console.error(error);
            setLoader(false);
            setMessageClass('error');
            setMessage("Something went wrong, Try again!.");
            setTimeout(() => {
                setMessage('')
            }, 5000);
        })
    }

    const domainUpdate = () => {
        setLoading(true);
        oceanapi.post(subdomainUpdation, {
            userId: userId,
            userName: userName,
            subdomain: subdomain
        })
        .then((res) => {
            setLoading(false);
            if(res.data.code === 200){
                saveSubdomain(`${subdomain.toLowerCase()}.invincibleocean.io`);
                setAdminToken(res.data.token);
                props.changeStep(4);
            } else {
                setMessageClass('error');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('')
                }, 5000);
            }
        })
        .catch((error) => {
            console.error(error);
            setLoading(false);
            setMessageClass('error');
            setMessage("Something went wrong, Try again!.");
            setTimeout(() => {
                setMessage('')
            }, 5000);
        })
    }
    
    return (<>
        {message && <Message message={message} messageClass={messageClass} closeMessage={() => setMessage('')} />}
        <h5 className={style['step-heading']}>Upload domain details</h5>
        <div className={style['domain-details']}>
            <label>User name</label>
            <input type='text' placeholder='Enter username' defaultValue={userName} disabled/>
            <label>Website name</label>
            <div className={style['website-mention']}>
                <input type='text' placeholder='Enter name' value={subdomain} onChange={(e) => setSubdomain(e.target.value)} />
                <span className={style['invincibleocean-domain']}>.invincibleocean.io</span>
            </div>
        </div>
        <ul className={style['suggestions']}>
            <li onClick= {() => checkDomainName()}>Check username</li>
            <li>{loader ? <i className="fa fa-circle-o-notch fa-spin"></i> : ''}</li>
            {/* <li>Suggested username : <span>abcd.invincibleocean.io</span></li> */}
        </ul>
        <div className={style['domain-button']}>
            {
                loading
                ?<button className='btn primary-button' disabled>Wait...</button>
                :<button type="submit" className='btn primary-button' onClick= {() => domainUpdate()}>Next <img src="./images/arrow.svg" alt="arrow"/></button>
            }
        </div>
    </>);
}