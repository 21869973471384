import React, { useState, useEffect, useContext } from 'react';
import { getAdminToken } from '../../../../Utils/Common';
import oceanapi, { fetchCredits, addCredits, } from '../../../../Base/OceanConfig';
import Message from '../../../../Components/Message/Message';
import Loading from '../../../../Components/Loading/Loading';
import styles from "../../user-accounts.module.css";
import { ColorContext } from '../../../../Components/ColorContext/ColorContext';

export default function ApiWallet(props){
    const userId = props.userId;
    const { userName } = useContext(ColorContext);
    const { css } = useContext(ColorContext);
    const token = getAdminToken();
    const [ userCredits, setUserCredits ] = useState(0);
    const [ messageClass, setMessageClass ] = useState('');
    const [ message, setMessage ] = useState('');
    const [ amount, setAmount ] = useState('');
    const [ loading, setLoading ] = useState(true);

    const config = {
        headers: {
            'token': props?.superAdminToken? props?.superAdminToken :token
        }
    }

    const getCredits = () => {
        oceanapi.get(fetchCredits + userId, config)
        .then((res) => {
            setLoading(false);
            if(res.data.code === 200){
                setUserCredits(res.data.response.amount);
            } else {
                setUserCredits(0);
            }
        }).catch((error) => {
            console.log(error);
            setLoading(false);
        })
    }
    
    useEffect(() => {
        getCredits();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const addCreditsFunction = (e) => {
        e.preventDefault();
        oceanapi.post(addCredits, {
            userId: userId,
            amount: amount,
            userName: props?.adminUsername ? props?.adminUsername : userName
        }, config)
        .then((res) => {
            if(res.data.code === 200){
                setAmount('');
                getCredits();
                setMessageClass('success');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            } else {
                setMessageClass('error');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            }
        })
        .catch((error) => {
            console.log(error);
            setMessageClass('error');
            setMessage("Something went wrong, Try again!.");
            setTimeout(() => {
                setMessage('');
            }, 5000);

        })
    }

    return(<>
        {message && <Message message={message} messageClass={messageClass} closeMessage={() => setMessage('')}/>}
        <div className={styles['api-wallet']}>API Wallet</div>
        {
            loading
            ?<Loading />
            :<div className={styles['api-credit-tab']}>
                <div className={styles['balance-tab']}>
                    <h3>Balance</h3>
                    <span title={userCredits}>₹{userCredits.toLocaleString("en-IN")}</span>
                </div>
                <div className={styles['credit-tab']}>
                    <h3>Add Credits</h3>
                    <form onSubmit={addCreditsFunction}>
                        <input type='text' placeholder='Enter Amount here' value={amount} onChange={e => setAmount(e.target.value)}/>
                        <button className='btn' style={css.primaryButton}>Add</button>
                    </form>
                </div>
            </div>
        }
    </>);
}