import React, { createContext, useState, useEffect } from 'react';
import oceanapi, { fetchThemeDetails } from '../../Base/OceanConfig';
import { getSubdomain, getUserName } from '../../Utils/Common';

const ColorContext = createContext();

const ColorProvider = ({ children }) => {
	const userName = getUserName();
    const subdomain = getSubdomain();
	const [ logo, setLogo ] = useState('');
	const [ primaryColor, setPrimaryColor ] = useState('');
	const [ secondaryColor, setSecondaryColor ] = useState('');

	useEffect(() => {
		themeFunction();
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const themeFunction = () => {
		oceanapi.get(fetchThemeDetails + subdomain)
		.then(res => {
			if(res.data.code === 200){
				setLogo(res.data.result.logo);
				setPrimaryColor(res.data.result.theme.primaryColor);
				setSecondaryColor(res.data.result.theme.secondColor);
				changeFavicon(res.data.result.favIcon);
			}
		})
		.catch(err => {
			console.error(err);
		})
	}

	const changeFavicon = (link) => {
        // Change favicon
        const favIcon = document.querySelector("link[rel~='icon']");
        favIcon.href = link;
	}

    const css = {
        primaryButton: {
            backgroundColor: primaryColor,
            border: `1px solid ${primaryColor}`,
            color: '#FFF'
        },
        secondaryButton: {
            color: primaryColor,
            border: `1px solid ${primaryColor}`
        },
        active: {
            color: secondaryColor,
            borderColor: secondaryColor
        },
        color: {
            color: primaryColor
        },
		menuSelected: {
			background: primaryColor,
            color: '#FFF'
		},
        border: {
            border: `1px solid ${primaryColor}`
        },
        secondaryColor: {
            color: secondaryColor
        }
    };

    return (
        <ColorContext.Provider value={{ logo, primaryColor, setPrimaryColor, setLogo, setSecondaryColor, changeFavicon, userName, subdomain, css }}>
            {children}
        </ColorContext.Provider>
    );
};

export { ColorContext, ColorProvider };