import React from 'react';

const BtnSecondary = ({btnName,action,btnType}) => {
  return (
    <div>
       <button className="bg-white py-3.5 px-11 rounded border-1   border-primaryColor " type={btnType} onClick={action}>
        <span className="text-primaryColor font-bold">{btnName}</span>{" "}
      </button>
  </div>
  )
}

export default BtnSecondary