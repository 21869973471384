import React, { useState, useEffect, useContext } from 'react';
import { getAdminUser } from '../../Utils/Common';
import oceanapi, { countUsers, countTransaction } from '../../Base/OceanConfig';
import { ColorContext } from '../../Components/ColorContext/ColorContext';
import Loading from '../../Components/Loading/Loading';
import styles from "./dashboard.module.css";

export default function Dashboard(){
    const { userName } = useContext(ColorContext);
    const userId = getAdminUser();
    const [ usersCount, setUsersCount ] = useState(0);
    const [ transactionsCount, setTransactionCount ] = useState(0);
    const [ loading, setLoading ] = useState(true);

    const fetchUsersCount = () => {
        oceanapi.get(countUsers + userName)
        .then((res) => {
            if(res.data.code === 200){
                setUsersCount(res.data.count.totalRecords);
                fetchTransactionsCount();
            } else {
                setLoading(false);
                setUsersCount(0);
            }
        })
        .catch((error) => {
            setLoading(false);
            console.log(error);
        })
    }

    useEffect(() => {
        if(userName){
            fetchUsersCount();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userName])

    const fetchTransactionsCount = () => {
        oceanapi.get(countTransaction + userId)
        .then((res) => {
            setLoading(false);
            if(res.data.code === 200){
                setTransactionCount(res.data.count.totalRecords);
            } else {
                setTransactionCount(0);
            }
        })
        .catch((error) => {
            setLoading(false);
            console.log(error);
        })
    }
    
    return (<>
        <div className="admin-dashboard">
            <h1 className="admin-dashboard-heading">Dashboard</h1>
            <div className="admin-dashboard-body">
                {
                    loading
                    ?<Loading />
                    :<div className={styles['dashboard-items']}>
                        <div>
                            <img src="../images/admin/users.png" alt="" />
                            <p>Users</p>
                            <h5>{usersCount.toLocaleString("en-IN")}</h5>
                        </div>
                        <div>
                            <img src="../images/admin/transactions.png" alt="" />
                            <p>Transactions</p>
                            <h5>{transactionsCount.toLocaleString("en-IN")}</h5>
                        </div>
                    </div>
                }
            </div>
        </div>
    </>);
}
