import React from 'react';
import style from './loading.module.css';

function Loading() {
    return (
        <div className={style["loading-container"]}>
            <div>
                <img src="../images/loading.gif" alt="loading"/>
            </div>
        </div>
    );
}

export default Loading;