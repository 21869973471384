import React, { useState, useEffect } from 'react';
import CryptoJS from 'crypto-js';
import { getAdminUser } from '../../../Utils/Common';
import adminapi, { setRazorpayDetails, fetchRazorpayDetails } from '../../../Base/AdminConfig';
import Message from '../../../Components/Message/Message';
import styles from "../my-account.module.css";

export default function Razorpay(props){
    const css = props.css;
    const subdomain = props.subdomain;
    const userId = getAdminUser();
    const [ message, setMessage ] = useState('');
    const [ messageClass, setMessageClass ] = useState('');
    const [ loading, setLoading ] = useState(false);
    const [ razorpayResult, setRazorpayResult ] = useState({});
    const [ razorpayKeyId, setRazorpayKeyId ] = useState('');
    const [ razorpayKeySecret, setRazorpayKeySecret ] = useState('');
    const [ paymentName, setPaymentName ] = useState('');
    const [ paymentDescription, setPaymentDescription ] = useState('');
    const [ paymentLogo, setPaymentLogo ] = useState('');
    const [ minimumApiAmount, setMinimumApiAmount ] = useState('');
    const [ minimumProductAmount, setMinimumProductAmount ] = useState('');
    const [ showPassword, setShowPassword ] = useState(false);
    const [ editModal, setEditModal ] = useState(false);
    
    useEffect(() => {
		getDocumentationLink();
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

    const getDocumentationLink = () => {
		adminapi.get(fetchRazorpayDetails + subdomain)
		.then(res => {
			if(res.data.code === 200){
                setRazorpayResult(res.data.result);
                setRazorpayKeyId(decryptFunction(res.data.result.razorpayKeyId));
                setRazorpayKeySecret(decryptFunction(res.data.result.razorpayKeySecret));
                setPaymentName(res.data.result.paymentName);
                setPaymentDescription(res.data.result.paymentDescription);
                setPaymentLogo(res.data.result.paymentLogo);
                setMinimumApiAmount(res.data.result.minimumApiAmount);
                setMinimumProductAmount(res.data.result.minimumProductAmount);
			}
		})
		.catch(err => {
			console.error(err);
		})
	}

    const addDocumentation = (e) => {
        e.preventDefault();
        setLoading(true);
        adminapi.post(setRazorpayDetails, {
            userId: userId,
            subdomain: subdomain,
            razorpayKeyId: razorpayKeyId,
            razorpayKeySecret: razorpayKeySecret,
            paymentName: paymentName,
            paymentDescription: paymentDescription,
            paymentLogo: paymentLogo,
            minimumApiAmount: minimumApiAmount,
            minimumProductAmount: minimumProductAmount,
            paymentThemeColor: "#123456",
            paymentCurrency: "INR"
        })
        .then((res) => {
            setLoading(false);
            if(res.data.code === 200){
                setMessageClass('success');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('')
                }, 5000);
            } else {
                setMessageClass('error');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            }
        })
        .catch((error) => {
            setLoading(false);
            console.error(error);
            setMessageClass('error');
            setMessage('Something went wrong, Try again.');
            setTimeout(() => {
                setMessage('');
            }, 5000);
        })
    }

    function decryptFunction(text) {
        const textParts = text.split(':');
        const iv = CryptoJS.enc.Hex.parse(textParts.shift());
        const encryptedText = textParts.join(':');
        const decrypted = CryptoJS.AES.decrypt(encryptedText, CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPTION_KEY), {
            iv: iv,
            padding: CryptoJS.pad.Pkcs7,
            mode: CryptoJS.mode.CBC
        });

        return decrypted.toString(CryptoJS.enc.Utf8);
    }

    const passwordToggle = () => setShowPassword(!showPassword);

    const handleEdit = () => setEditModal(true);

    return(<>
        {message && <Message message={message} messageClass={messageClass} closeMessage={() => setMessage('')} />}
        {editModal && 
        <div className="modal-container">
                <div className="modal-background" onClick={() => setEditModal(false)}></div>
                <div className="modal-box">
                    <form onSubmit={addDocumentation}>
                        <div className={styles["razorpay-modal-box"]}>
                            <h5 className={styles['edit-modal-details-heading']}>Razorpay</h5>
                            <div className='row'>
                                <div className='col-xs-12 col-md-6'>
                                    <label>Name</label>
                                    <input  type='text' placeholder='Enter payment name' value={paymentName} onChange={e => setPaymentName(e.target.value)} required/>
                                </div>
                                <div className='col-xs-12 col-md-6'>
                                    <label>Description</label>
                                    <input  type='text' placeholder='Enter payment description' value={paymentDescription} onChange={e => setPaymentDescription(e.target.value)} required/>
                                </div>
                                <div className='col-xs-12 col-md-6'>
                                    <label>Razorpay Key Id</label>
                                    <input  type= 'text' placeholder='Enter razorpay key id' value={razorpayKeyId} onChange={e => setRazorpayKeyId(e.target.value)} required/>
                                    
                                </div>
                                <div className='col-xs-12 col-md-6'>
                                    <label>Razorpay Key Secret</label>
                                    <input  type= 'text' placeholder='Enter razorpay key id' value={razorpayKeySecret} onChange={e => setRazorpayKeySecret(e.target.value)} required/>
                                    
                                </div>
                                <div className='col-xs-12 col-md-12'>
                                    <label>Payment Logo</label>
                                    <input  type='text' placeholder='Enter payment logo' value={paymentLogo} onChange={e => setPaymentLogo(e.target.value)} required/>
                                </div>
                                <div className='col-xs-12 col-md-6'>
                                    <label>Minimum API Wallet Amount</label>
                                    <input  type='number' placeholder='Enter payment logo' value={minimumApiAmount} onChange={e => setMinimumApiAmount(e.target.value)} required/>
                                </div>
                                <div className='col-xs-12 col-md-6'>
                                    <label>Minimum Product Wallet Amount</label>
                                    <input  type='number' placeholder='Enter payment logo' value={minimumProductAmount} onChange={e => setMinimumProductAmount(e.target.value)} required/>
                                </div>
                                <div className='col-xs-12 col-md-12'>
                                    {
                                        loading
                                        ?<button className="btn" style={css.primaryButton} disabled>wait ...</button>
                                        :<button type="submit" className="btn" style={css.primaryButton}>Save</button>
                                    }
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
        </div>
        }
        <h5 className={styles['my-account-details-heading']}>Razorpay</h5>
        <>
            <div className='row'>
                <div className='col-xs-12 col-md-6'>
                    <label>Name</label>
                    <input readOnly type='text' placeholder='Enter payment name' value={razorpayResult.paymentName}  />
                </div>
                <div className='col-xs-12 col-md-6'>
                    <label>Description</label>
                    <input readOnly type='text' placeholder='Enter payment description' value={razorpayResult.paymentDescription}  />
                </div>
                <div className='col-xs-12 col-md-6'>
                    <label>Razorpay Key Id</label>
                    <input readOnly type={showPassword ? 'text' : 'password'} placeholder='Enter razorpay key id' value={razorpayResult.razorpayKeyId && decryptFunction(razorpayResult.razorpayKeyId)}/>
                    <div className={styles['password-eye']}>
                        <i className={showPassword ? "fa fa-eye" : "fa fa-eye-slash"} onClick={() => passwordToggle()}></i>
                    </div>
                </div>
                <div className='col-xs-12 col-md-6'>
                    <label>Razorpay Key Secret</label>
                    <input readOnly type='password' placeholder='Enter razorpay key id' value={razorpayResult.razorpayKeySecret && decryptFunction(razorpayResult.razorpayKeySecret)} />
                   
                </div>
                <div className='col-xs-12 col-md-12'>
                    <label>Payment Logo</label>
                    <input readOnly type='text' placeholder='Enter payment logo' value={razorpayResult.paymentLogo} />
                </div>
                <div className='col-xs-12 col-md-6'>
                    <label>Minimum API Wallet Amount</label>
                    <input readOnly type='number' placeholder='Enter payment logo' value={razorpayResult.minimumApiAmount} />
                </div>
                <div className='col-xs-12 col-md-6'>
                    <label>Minimum Product Wallet Amount</label>
                    <input readOnly type='number' placeholder='Enter payment logo' value={razorpayResult.minimumProductAmount} />
                </div>
                <div className='col-xs-12 col-md-12'>         
                        <button type="submit" className="btn" style={css.primaryButton} onClick={()=>handleEdit()}>Edit</button>
                </div>
            </div>
        </>
    </>);
}