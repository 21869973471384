import axios from "axios";
import { getAdminToken } from "../Utils/Common";
export const baseurl = process.env.REACT_APP_WHITELABEL_URL;

const adminapi = axios.create({
    baseURL: baseurl,
    headers: {
        'token': getAdminToken(),
        'versionnumber': process.env.REACT_APP_VERSION
    },
});

export default adminapi;

adminapi.interceptors.response.use(
    function (response) {
        if(response.data.code===401){
            window.location.replace('/logout');
        }
        return response;
    },
    async function (error) {
        if(error.response.status === 401 ) {
            window.location.replace('/logout');
        }
        return Promise.reject(error);
    }
);

// Fetch api wallet credits of admin
export const fetchAdminCredits = "/admin/credits/";

// Fetch product wallet credits of admin
export const fetchAdminProductCredits = "/admin/productCredits/";

// Change the Documentation Link
export const setDocumentation = "/admin/documentLink";

//User Password reset API
export const passReset = "/admin/changePassword";

//access control for the admin
export const adminAccessControl ="/user/accessControl";

//status control for the admin
export const adminStatusControl ="/user/statusControl";

// Save razorpay details
export const setRazorpayDetails = "/admin/keysUpdate";

// Fetch razorpay details
export const fetchRazorpayDetails = "/admin/keys/";

//Get Signup customisation
export const setSignupCustomization = "/superAdmin/updateSignUpCustomization";

// Fetch User(My) Api transaction
export const fetchMyApiTransaction = "/admin/myApiTransactions/";

// Fetch User(My) Product Api transaction
export const fetchMyProductApiTransaction = "/admin/myProductTransactions/";

//Add ip from admin
export const addWhitlabelIp = "/admin/whiteLabelIp";

//Whitelabel Ip List
export const getIpList = "admin/getWhiteLabelIp/";

//Whitlist ip filter (Admin)
export const whitelistIpFilter = "/admin/searchWhiteListIP";

//Server Security  Settings
export const setSecurity = "/admin/serverSecurity";

//Server Security  Settings
export const getSecurity = "/admin/serverSecurity/";

// Get IPHits List
export const listIpHits = "/admin/clientsHits"

// Get IPBlock
export const blockIpList = "/admin/blockIPs"

// Get Filter IPHits Data
export const filteringData = "/admin/searchClientHits/"

// Get Blocked Ips
export const getBlockIpList = "/admin/getBlockIPs"

// UnBlocked Ips in IP HITS
export const unblockips = "/admin/unBlockIps/";

//Whitlist Ip of users 
export const userWhitlistIp = "/admin/getWhiteLabelIp/";

//Ip details of users 
export const userIpDetails = "/admin/getIpHIts/";

//Filter Transaction Logs
export const searchApiTransaction ="/admin/searchApiTransaction?";

//Filter Product Trnsaction Logs
export const searchProductTransaction ="/admin/searchProductTransaction?";

//Filter Api Logs
export const searchApiLogs ="/admin/searchApiLogs?";

//Filter Product Logs
export const searchProductLogs ="/admin/searchProductLogs?";

// fetch default api prices
export const getDefaultPrices = "/admin/getDefaultPrices";

//Admin Api Transaction filter
export const adminApiTransaction ="/admin/search/MyApiTransaction?"

//Admin Product Transaction filter
export const adminProductTransaction ="/admin/search/MyProductTransaction?"

// Fetch list of users of 
export const fetchUsersList = "/admin/WhitelistUserlist/";
