import React, { useState } from 'react';
import { getAdminUser } from '../../../../Utils/Common';
import oceanapi, { uploadThemeDetails } from '../../../../Base/OceanConfig';
import Message from '../../../../Components/Message/Message';
import style from '../../signup.module.css';

export default function UpdateTheme(props){
    const userId = getAdminUser();
    const subdomain = sessionStorage.getItem("subdomain");
    const [ primaryColor, setPrimaryColor ] = useState('');
    const [ secondaryColor, setSecondaryColor ] = useState('');
    const [ tertiaryColor, setTertiaryColor ] = useState('');
    const [ uploadLogo, setUploadLogo ] = useState('');
    const [ loading, setLoading ] = useState(false);
    const [ message, setMessage ] = useState('');
    const [ messageClass, setMessageClass ] = useState('');

    const logoTheme = (e) => {
        e.preventDefault();
        setLoading(true);
        const formData = new FormData();
        formData.append("userId", userId);
        formData.append("subdomain", subdomain);
        formData.append("file", uploadLogo);
        formData.append("primaryColor", primaryColor);
        formData.append("secondColor", secondaryColor);
        formData.append("thirdColor", tertiaryColor);
        oceanapi.post(uploadThemeDetails, formData)
        .then((res) => {
            setLoading(false);
            if(res.data.code === 200){
                props.changeStep(5);
            } else {
                setMessageClass('error');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('')
                }, 5000);
            }
        })
        .catch((error) => {
            console.error(error);
            setLoading(false);
            setMessageClass('error');
            setMessage("Something went wrong, Try again!.");
            setTimeout(() => {
                setMessage('')
            }, 5000);
        })
    }
    
    return (<>
        {message && <Message message={message} messageClass={messageClass} closeMessage={() => setMessage('')} />}
        <form onSubmit={logoTheme}>
            <h5 className={style['step-heading']}>Add Logo details</h5>
            <div className={style['upload-logo']}>
                <label>Upload Logo <span>(Recommended logo size : Width=130-135 px, Height = 20-25 px)</span></label>
                <input type='file' accept=".png, .jpg, .jpeg" onChange={(e) => setUploadLogo(e.target.files[0])} required/>
            </div>
            <div>
                <label>Add colour <span>(Add colour code in HEX format)</span></label>
                <div className={style['color-type']}>
                    <div className={style['primary']}>
                        <label>primary color:</label>
                        <input type='color' value={primaryColor} onChange={e => setPrimaryColor(e.target.value)}/>
                        <input type='text' placeholder='Enter primary colour' value={primaryColor} onChange={e => setPrimaryColor(e.target.value)} required/>
                    </div>
                    <div className={style['secondary']}>
                        <label>Secondary color:</label>
                        <input type='color' value={secondaryColor} onChange={e => setSecondaryColor(e.target.value)}/>
                        <input type='text' placeholder='Enter secondary' value={secondaryColor} onChange={e => setSecondaryColor(e.target.value)} required/>
                    </div>
                    <div className={style['Tertiary']}>
                        <label>Tertiary color:</label>
                        <input type='color' value={tertiaryColor} onChange={e => setTertiaryColor(e.target.value)}/>
                        <input type='text' placeholder='Enter tertiary' value={tertiaryColor} onChange={e => setTertiaryColor(e.target.value)} required/>
                    </div>
                </div>
            </div>
            <div className={style['upload-button']}>
                {
                    loading
                    ?<button className='btn primary-button' disabled>Wait...</button>
                    :<button type="submit" className='btn primary-button'>Next <img src="./images/arrow.svg" alt="arrow"/></button>
                }
            </div>
        </form>
    </>);
}