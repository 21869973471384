import React, { useState, useEffect, useContext } from 'react';
import styles from "./trubank.module.css";
import Message  from '../../Components/Message/Message';
import { ColorContext } from '../../Components/ColorContext/ColorContext';
import oceanapi, { trubankgetdatalist, changestatusManualVerification } from '../../Base/OceanConfig';

export default function MyAccount(){
    const [ bankList, setBankList ] = useState([]);
    const [ changeStatus, setChangeStatus ] = useState(false);
    const [ comment, setComment ] = useState('');
    const [ file, setFile ] = useState('');
    const [ selectStatus, setSelectStatus ] = useState('');
    const [ messageClass, setMessageClass ] = useState('');
    const [ message, setMessage ] = useState('');
    const [ requestId, setRequestId ] = useState('');
    const { css } = useContext(ColorContext);

    const getlistdata = () => {
        oceanapi.get(trubankgetdatalist)
        .then((res) => {
            if(res.data.code === 200){
                setBankList(res.data.records);
            } else {
                setBankList([]);
            }
        })
        .catch((error) => {
            console.error(error);
        })
    }

    useEffect(() => {
        getlistdata();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const changestatus = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("file", file);
        formData.append("comments", comment);
        formData.append("manualVerificationStatus", selectStatus );
        formData.append("requestId", requestId );
        oceanapi.post(changestatusManualVerification, formData)
        .then((res) => {
            if(res.data.code === 200){
                setChangeStatus(false);
                setMessageClass('success');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            } else {
                setMessageClass('error');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            }
        })
        .catch((error) => {
            console.log(error);
            setMessageClass('error');
            setMessage('Something went wrong, Try again.');
            setTimeout(() => {
                setMessage('');
            }, 5000);
        })
    }

    const openChangeStatusModel = (x) => {
        setRequestId(x.requestId);
        setChangeStatus(true);
    }

    return(
        <div className="admin-dashboard">
            {message && <Message message={message} messageClass={messageClass} />}
            <h1 className="admin-dashboard-heading">TruBank</h1>
            <div className="admin-dashboard-body">
                {changeStatus && <div className={styles['change-status-container']}>
                    <div className={styles['change-status-background']} onClick={() => setChangeStatus(false)}></div>
                    <div className={styles['change-status-box']}>
                        <img src="./images/cancel.svg" alt="times" onClick={() => setChangeStatus(false)} />
                        <div className={styles['image-status']}>
                            <div className={styles['change-status-icon']}>
                            <img src="./images/change-status-icon.png" alt="change-status" />
                            </div>
                            <form onSubmit={changestatus}>
                                <div className={styles['right-content']}>
                                    <h5>Change status</h5>
                                    <div>
                                    <label>Select type</label>
                                    <select className='form-control' onChange={e => setSelectStatus(e.target.value)} required>
                                        <option value="">-- Select status -- </option>
                                        <option value="Verified">Verified</option>
                                        <option value="Not verified">Not verified</option>
                                    </select>
                                    </div>
                                    <div>
                                        <label>Upload File</label>
                                        <input type = "file" placeholder='No file Chosen' onChange={e => setFile(e.target.files[0])} />
                                    </div>
                                    <div>
                                        <label>Add comment</label>
                                        <textarea className='form-control' value={comment} onChange={e => setComment(e.target.value)} ></textarea>
                                    </div>
                                    <button className='btn' style={css.primaryButton}>Continue</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>}
                {
                    bankList.length === 0
                    ?<div className="no-records">
                        <p>No manual bank account verification request found.</p>
                    </div>
                    :bankList.map((item, i) => (
                        <div className={styles['statement-verification-card']} key={i}>
                            <div>
                                <img src ='../images/admin/user-account-logo.svg' alt='user-account-logo' />
                            </div>
                            <div className={styles['statement-details']}>
                                <p>Account holder <span>{item.summaryData.bank_account.accountName}</span></p>
                                <p>Request Id <span>{item.requestId}</span></p>
                                <p>Account Number <span>{item.summaryData.bank_account.accountNumber}</span></p>
                                <p>Bank Name <span>{item.summaryData.bank_account.bankName}</span></p>
                            </div>
                            <div className={styles['statement-buttons']}>
                                <a href={item.fileUrl} target='_blank' rel="noreferrer"><button className='btn' style={css.primaryButton} >View statement</button></a>
                                <span onClick={() => openChangeStatusModel(item)}><button className='btn' style={css.primaryButton}>Change Status</button></span>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}