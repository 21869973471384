import React, { useEffect, useState } from 'react';
import superadminapi, { fetchUsersList } from '../../../Base/SuperAdminConfig';
import UserDetails from './UserDetails';
import styles from '../client-details.module.css';

export default function UserAccounts(props) {
    const [ list, setList ] = useState([])
    const [ tablePage, setTablePage ] = useState(1);
    const [ tableArrayStart, setTableArrayStart ] = useState(0);
    const [ tableArrayEnd, setTableArrayEnd ] = useState(8);
    const [ userClientDetails, setUserClientDetails] = useState(false);

    useEffect(() => {
        getAdminList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getAdminList = () => {
        superadminapi.get(fetchUsersList+props.details.userName)
        .then((res) => {
            if(res.data.code === 200) {
                setList(res.data.result.records)
            }
        })
        .catch((error) => {
            console.log(error);
        })
    }

    const previousFunction = () => {
        if(tablePage > 1) {
            setTablePage(tablePage - 1);
            setTableArrayEnd(tableArrayStart);
            setTableArrayStart(tableArrayStart - 8);
        }
    };

    const nextFunction = () => {
        if(tablePage !== Math.ceil(list.length / 8)) {
            setTablePage(tablePage + 1);
            setTableArrayStart(tableArrayEnd);
            if (list.length >= tableArrayEnd + 8) {
                setTableArrayEnd(tableArrayEnd + 8);
            } else {
                setTableArrayEnd(list.length);
            }
        }
    };

    const openDetail = (detail) => {
        const data = {
            fullName: detail.firstName + " " + detail.lastName,
            email: detail.email,
            phone: detail.phoneNumber,
            userId: detail.userId,
            active: detail.isActive,
            fullAccess: detail.fullAccess
        }
        sessionStorage.setItem("admin-user-detail", JSON.stringify(data));
        setUserClientDetails(true)
    }

    return (<>
        {!userClientDetails &&<>
        <h4>User Accounts <span>({list.length})</span></h4>
        <div className={styles["allAdminInfo"]}>
            {list.slice(tableArrayStart, tableArrayEnd).map((item, i) =>
                <div className={styles['admin-users']} key={tableArrayStart + i}>
                    <p>#{tableArrayStart + i + 1}</p>
                    <div className={styles['admin-users-details']}>
                        <p><span>Name</span> {item.firstName} {item.lastName}</p>
                        <p><span>Email</span> {item.email}</p>
                        <p><span>Phone</span> {item.phoneNumber}</p>
                        <p><span>User ID</span> {item.userId}</p>
                        <p className={styles[item.isActive ? "status_active" : "status_inactive"]}><span>Status</span> {item.isActive ? "Active" : "Inactive"}</p>
                        <p><span>User name</span> {item.userName}</p>
                    </div>
                    <button className="btn primary-button" onClick={() => openDetail(item)}>View Details</button>
                </div>
            )}
            {Math.ceil(list.length / 8) > 1 && (
                <div className="pagination-box">
                    {
                        tablePage <= 1
                        ?<span className="fa fa-angle-left pagination-arrow-disabled"></span>
                        :<span className="fa fa-angle-left pagination-arrow" onClick={() => previousFunction(tablePage - 1)}></span>
                    }
                    <span>{tablePage} of {Math.ceil(list.length / 8)} pages</span>
                    {
                        tablePage !== Math.ceil(list.length / 8)
                        ?<span className="fa fa-angle-right pagination-arrow" onClick={() => nextFunction(tablePage + 1)}></span>
                        :<span className="fa fa-angle-right pagination-arrow-disabled"></span>
                    }
                </div>
            )}
        </div>
        </>}
        {userClientDetails && <UserDetails setUserClientDetails={setUserClientDetails} userName={props.details.userName} parentId={props.details.userId}/>}
    </>)
}