import React, { useState, useRef } from "react";
import Message from "../../Components/Message/Message";
import ReCAPTCHA from "react-google-recaptcha";
import oceanapi, { loginSuperAdmin } from "../../Base/OceanConfig";
import { setSuperAdminToken } from "../../Utils/Common";
import styles from './login.module.css';

export default function SuperAdminLogin() {
    const captchaRef = useRef(null);
    const [email, setEmail] = useState(window.location.host === 'localhost:3000' ? 'naresh@invincibleocean.com' : '');
    const [password, setPassword] = useState(window.location.host === 'localhost:3000' ? 'Code$123123' : '');
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [messageClass, setMessageClass] = useState('');

    const loginFunction = async (e) => {
        e.preventDefault();
        setLoading(true);
        const token = captchaRef.current.getValue();
        oceanapi.post(loginSuperAdmin, {
            email: email,
            password: password,
            recaptchaToken: token
        })
        .then((res) => {
            if (res.data.code === 200) {
                setLoading(false);
                setMessageClass('success');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('');
                }, 5000);
                setSuperAdminToken(res.data.token);
                window.location.replace('/superadmin/dashboard');
            } else {
                setLoading(false);
                setMessageClass('error');
                captchaRef.current.reset();
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            }
        })
        .catch((error) => {
            setLoading(false);
            console.error(error);
            setMessageClass('error');
            setMessage('Something went wrong, Try again.');
            setTimeout(() => {
                setMessage('');
            }, 5000);
        })
    };

    const passwordToggle = () => setShowPassword(!showPassword);

    return (<>
        {message && <Message message={message} messageClass={messageClass} closeMessage={() => setMessage('')} />}
        <div className={styles['login-page']}>
            <div className={styles['login-box']}>
                <div>
                    <img src="../images/superadmin/loginpage_img.svg" alt="" />
                </div>
                <div>
                    <img src="../images/logo-dark.png" alt="" />
                    <h5>Log In</h5>
                    <form onSubmit={loginFunction}>
                        <label>User Name</label>
                        <input type="text" placeholder="Enter user name" value={email} onChange={e => setEmail(e.target.value)} required />
                        <label>Password</label>
                        <input type={showPassword ? 'text' : 'password'} placeholder="Enter password" value={password} onChange={e => setPassword(e.target.value)} required />
                        <div className={styles['hide-show-password']}>
                            <i className={showPassword ? "fa fa-eye" : "fa fa-eye-slash"} onClick={() => passwordToggle()}></i>
                        </div>
                        <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY} ref={captchaRef} />
                        {
                            loading
                            ? <button className="btn primary-button w-100 mt-3" disabled> Login</button>
                            : <button className="btn primary-button w-100 mt-3"> Login</button>
                        }
                    </form>
                </div>
            </div>
        </div>
    </>);
}