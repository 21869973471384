import React, { useState, useEffect } from 'react';
import styles from "../user-accounts.module.css";
import Loading from '../../../Components/Loading/Loading';
import oceanapi, { fetchApiTransactions} from '../../../Base/OceanConfig';
import Export from './Transactions/Export';

export default function APITransactions(props) {
    const [ loading, setLoading ] =useState(true);
    const [ pageNumber, setPageNumber ] = useState(1);
    const [ record, setRecord ] = useState('');
    const [ results, setResults ] = useState([]);
    const defaultPriceList =props.defaultPriceList

    


    const fetchTransactionsPagination = () => {
        oceanapi.get(fetchApiTransactions + props.userId + `?pageNumber=${pageNumber}`)
        .then((res) => {
            setResults(res.data.records);
            setRecord(res.data.totalRecords);
            setLoading(false);
        })
        .catch((error) => {
            console.log(error);
        })
    }

    useEffect (() => {
        fetchTransactionsPagination();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber])

    const changeDescription = (x) => {
        const array = defaultPriceList.filter(item => item.services === x);
        if(array.length >= 1){
            return array[0].apiName
        } else {
            return "NA"
        }
    }

    return (<>
        {loading
            ?<Loading />
            :<>
                <Export userId={props.userId} />
                <div className={styles['services-menu']}>
                    <p><span>#</span></p>
                    <p><span>Date</span></p>
                    <p><span>PID</span></p>
                    <p><span>Services</span></p>
                    <p><span>Amount</span></p>
                    <p><span>Balance</span></p>
                    <p><span>Type</span></p>
                </div>
                {results.length !== 0
                    ?<>
                        {results.map((item, i) => (
                            <div className={styles['transaction-data-menu']} key={i}>
                                <p><span>#</span> {((pageNumber - 1) * 50) + i + 1}</p>
                                <p><span>Date: </span> {`${new Date(item.createdAt)}`.substr(0, 24)}</p>
                                <p><span>PID:</span> {item.PID ? item.PID : 'NA'}</p>
                                <p><span>Services: </span>{item.amountType === 'Credit'?"Credit":changeDescription(item.services)}</p>
                                {
                                    item.amountType === 'Credit'
                                    ?<>
                                        <p><span>Amount:</span> ₹{item.remainingAmount && item.remainingAmount.toLocaleString('en-IN')}</p>
                                        <p><span>Balance:</span> NA</p>
                                    </>
                                    :<>
                                        <p><span>Amount:</span> ₹{item.amount}</p>
                                        <p><span>Balance:</span> ₹{item.remainingAmount && item.remainingAmount.toLocaleString('en-IN')}</p>
                                    </>
                                }
                                <p className={styles[item.amountType === "Debit" ? "red" : "green"]}><span>Type: </span> {item.amountType}</p>
                            </div>
                        ))}
                        {Math.ceil(record/50) > 1 &&
                            <div className="pagination-box">
                                {
                                    pageNumber <= 1
                                    ?<span className="fa fa-angle-left pagination-arrow-disabled"></span>
                                    :<span className="fa fa-angle-left pagination-arrow" onClick={() => setPageNumber(pageNumber - 1)}></span>
                                }
                                <span> {pageNumber} of {Math.ceil(record/50)} pages </span>
                                {
                                    pageNumber === Math.ceil(record/50)
                                    ?<span className="fa fa-angle-right pagination-arrow-disabled"></span>
                                    :<span className="fa fa-angle-right pagination-arrow" onClick={() => setPageNumber(pageNumber + 1)}></span>
                                }
                            </div>
                        }
                    </>
                    :<div className="no-records">
                        <p>No transactions available</p>
                    </div>
                }
            </>
        }
    </>)
}