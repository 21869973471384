import React, { useEffect, useState } from 'react';
import superadminapi, { getSandboxStatus, updateSandbox } from '../../../Base/SuperAdminConfig';
import Message from '../../../Components/Message/Message';
import styles from "../client-details.module.css";

export default function Settings(props) {
    const details = props.details;
    const [message, setMessage] = useState('');
    const [messageClass, setMessageClass] = useState('');
    const [sandBoxStatus, setSandBoxStatus] = useState(true);

    const fetchSandBoxStatus = () => {
        superadminapi.get(getSandboxStatus +`${details.userId}/${details.userName}`)
        .then((res)=>{
            if(res.data.code === 200) {
                setSandBoxStatus(res.data.doesExist.sendBoxStatus);
            } else {
                setMessageClass('error');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            }
        })
    }

    const handleOnchange = (val)=>{
        setSandBoxStatus(val);
        UpdateSandBoxStatus(val);
    }

    const UpdateSandBoxStatus = (val) => {
        superadminapi.post(updateSandbox,{
            userId: details.userId,
            userName: details.userName,
            sendBoxStatus: val
        })
        .then((res)=>{
            if(res.data.code === 200) {
                setMessageClass('success');
                setMessage(res.data.message);
                fetchSandBoxStatus();
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            } else {
                setMessageClass('error');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            }
        })
    }
    useEffect(() => {
		fetchSandBoxStatus();
        // eslint-disable-next-line
	}, [])

    return (
        <>
            {message && <Message message={message} messageClass={messageClass} closeMessage={() => setMessage('')} />}
            <h5 className={styles['documentation-heading']}>Settings</h5>
            <div className={styles['setting-grid']}>
                <span>Test api</span>
                <div className= {styles['setting-radio-btn']}>
                    <span><input type="radio" name='status' checked={sandBoxStatus && true} onChange={() => handleOnchange(true)} value='active'/> Enable</span>
                    <span><input type="radio" name='status' checked={!sandBoxStatus && true} onChange={() => handleOnchange(false)} value='inactive'/> Disable</span>
                </div>
            </div>
        </>
    )
}