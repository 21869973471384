import React, { useState, useEffect,} from 'react';
// import { ColorContext } from '../../../../Components/ColorContext/ColorContext';
import styles from "./iphits.module.css";
import Loading from '../../../../Components/Loading/Loading';
import { getAdminUser } from '../../../../Utils/Common';
import adminapi,{userIpDetails} from '../../../../Base/AdminConfig';

export default function IPHits(props) {
    // const { css } = useContext(ColorContext);
    const [ loading, setLoading ] = useState(true);
    const [ hits, setHits ] = useState(''); 
    const adminId=getAdminUser() ;
    
    const FetchIphitsList = () => {
        adminapi.get(userIpDetails+ `${props.userId}/${adminId}`)
        .then(res => {
            setLoading(false);
            if(res.data.code === 200){
            setHits(res.data.result);

        } else{
            setHits([]);
        }
        })
        .catch((error) => {
            setLoading(false);
            console.error(error);
        })
    }


    useEffect(() => {
        FetchIphitsList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (<>    
    <div className="admin-container">
        <div className="admin-dashboard">
            <div className="admin-dashboard-body">
                {
                <>
                    <div className={styles['transactions-box']}>
                        <div className={styles['all-list-head']}>
                            <p>#</p>
                            <p>Created at</p>
                            <p>Updated at</p>
                            <p>IP</p>
                            <p>Hits</p>
                            {/* <p>Details</p> */}
                        </div>
                        {
                        loading ?
                        <Loading />
                        :<>
                            {
                                hits.length > 0
                                ?hits.map((item, i) => (
                                    <div className={styles['all-list-body']} key={i}>
                                        <p><span>#</span> {i + 1}</p>
                                        <p><span>Timestamp:</span> {`${new Date(item.timestamp)}`.substr(0, 16)}</p>
                                        <p><span>Updated at:</span> {item.updatedAt ? `${new Date(item.updatedAt)}`.substr(0, 16) : `${new Date(item.timestamp)}`.substr(0, 24)}</p>
                                        <p><span>IP:</span> {item.clientData}</p>
                                        <p><span>Hits:</span> {item.hits}</p>
                                        
                                        {/* <p className={styles['view-detail-box']} style={css.color}>View details</p> */}
                                        
                                    </div>
                                ))
                                :<div className={styles['no-data']}>
                                    <p>No data</p>
                                </div>
                            }

                        </>
                        }
                                        
                    </div>
                </>
                }
            </div>
        </div>
    </div>
    </>);
}