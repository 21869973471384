import React, { useState, useContext, useEffect } from 'react';
import { ColorContext } from '../../Components/ColorContext/ColorContext';
import APILogs from './Components/APILogs';
import APITransactions from './Components/APITransactions';
import ProductTransactions from './Components/ProductTransactions';
import ProductLogs from './Components/ProductLogs';
import adminapi, { getDefaultPrices } from '../../Base/AdminConfig';
import styles from './user-transactions.module.css';

export default function UserTransactions() {
    const { css } = useContext(ColorContext);
    const [ tabName, setTabName ] = useState('api-transactions');
    const [ list, setList ] = useState([]);

    const changeTab = (x) => setTabName(x);

    const fetchDefaultFunction = () => {
        adminapi.get(getDefaultPrices)
        .then((res) => {
            if(res.data.code === 200){
                setList(res.data.result);
            } else {
                setList([]);
            }
        })
        .catch((error) => {
            console.error(error);
        })
    }

    useEffect(()=>{
        fetchDefaultFunction();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="admin-dashboard">
            <h1 className="admin-dashboard-heading">User Transactions</h1>
            <div className="admin-dashboard-body">
                <ul className={styles['transaction-tabs']}>
                    <li style={tabName === 'api-transactions' ? css.active : null} onClick={() => changeTab('api-transactions')}>API transactions</li>
                    <li style={tabName === 'api-logs' ? css.active : null} onClick={() => changeTab('api-logs')}>API logs</li>
                    <li style={tabName === 'product-transactions' ? css.active : null} onClick={() => changeTab('product-transactions')}>Product transactions</li>
                    <li style={tabName === 'product-logs' ? css.active : null} onClick={() => changeTab('product-logs')}>Product logs</li>
                </ul>
                {tabName === 'api-transactions' && <APITransactions list={list} color={css.color}/>}
                {tabName === 'api-logs' && <APILogs list={list} color={css.color}/>}
                {tabName === 'product-transactions' && <ProductTransactions list={list}  color={css.color}/>}
                {tabName === 'product-logs' && <ProductLogs list={list} color={css.color}/>}
            </div>
        </div>
    );
}