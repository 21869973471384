import React from 'react';
import Sidebar from '../Sidebar/Sidebar';
import Navbar from '../Navbar/Navbar';
import styles from './layout.module.css';

export default function Layout({ children }) {
    return (<>
        <div className={styles['admin-container']}>
            <Sidebar />
            <div>
                <Navbar />
                <main>{children}</main>
            </div>
        </div>
    </>);
}
