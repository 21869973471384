import React, { useState, useEffect } from 'react';
import superadminapi, { getSendBoxforAdmin, updateSendBoxForAdmin, addSendBoxForAdmin, deleteSendBoxForAdmin } from '../../../Base/SuperAdminConfig';
import Loading from '../../../Components/Loading/Loading';
import Message from '../../../Components/Message/Message';
import styles from "./ApiPricing.module.css";

export default function ApiPricing( props) {
    const [ defaultList, setDefaultList ] = useState([]);
    const [ list, setList ] = useState([]);
    const [ addModal, setAddModal ] = useState(false);
    const [ categoryArray, setCategoryArray ] = useState([]);
    const [ editModal, setEditModal ] = useState(false);
    const [ selectCategory, setSelectCategory ] = useState("");
    const [ id, setId ] = useState("");
    const [ name, setName ] = useState("");
    const [ category, setCategory ] = useState("");
    const [ endPoint, setEndPoint ] = useState("");
    const [ services, setServices ] = useState("");
    const [ documentationLink, setDocumentationLink ] = useState("");
    const [ curlCode, setCurlCode ] = useState("");
    const [ responseCode, setResponseCode ] = useState("");
    const [ description, setDescription ] = useState("");
    const [ tablePage, setTablePage ] = useState(1);
    const [ tableArrayStart, setTableArrayStart ] = useState(0);
    const [ tableArrayEnd, setTableArrayEnd ] = useState(10);
    const [ loading, setLoading ] = useState(true);
    const [ message, setMessage ] = useState("");
    const [ messageClass, setMessageClass ] = useState("");
    const [ methodValue, setMethodValue ] = useState("get");
    const [ statusValue, setStatusValue ] = useState(true)
    const [ paramName,setParamName]  = useState("");
    const [ headerParamName,setHeaderParamName]= useState("");
    const [ deleteApi,setDeleteApi]  = useState(false);

    const fetchPricing = () => {
        superadminapi.get(getSendBoxforAdmin+props.details.userId+`/${props.details.userName}`)
        .then((res) => {
            if (res.data.code === 200) {
                setLoading(false);
                setDefaultList(res.data.apiData);
                setList(res.data.apiData);
                let categoryArr = [];
                for (let i = 0; i < res.data.apiData.length; i++) {
                    if (categoryArr.indexOf(res.data.apiData[i].category.toLowerCase()) === -1) {
                        categoryArr.push(res.data.apiData[i].category.toLowerCase());
                    }
                }
                setCategoryArray(categoryArr);
            } else {
                setLoading(false);
                setList([]);
            }
        })
        .catch((error) => {
            console.error(error);
            setLoading(false);
        });
    };

    useEffect(() => {
        fetchPricing();
        // eslint-disable-next-line
    }, []);

    const strToObj = (obj) => {
        // console.log(obj)
        return new Promise((res, rej) => {
            try {
                res(JSON.parse(obj));
            } catch (err) {
                console.log(err)
                rej("not valid object");
            }
        });
    };

    const editFunction = async (e) => {
        try {
            e.preventDefault();
            // console.log(responseCode)
            const responseCodeObj = await strToObj(JSON.stringify(responseCode));
            //console.log(paramName)
            //  return;
            superadminapi.post(updateSendBoxForAdmin+id,{
                userId:props.details.userId,
                userName:props.details.userName,
                status:statusValue,
                name: name,
                category: category,
                endPoint: endPoint,
                documentationLink: documentationLink,
                curlCode: curlCode,
                responseCode: responseCodeObj,
                method: methodValue,
                services: services,
                paramName: paramName === null ? "" : paramName,
                headerParamName: headerParamName === null ? "" : headerParamName,
                description: description,
            })
            .then((res) => {
                if (res.data.code === 200) {
                    setMessage(res.data.message);
                    setMessageClass("success");
                    fetchPricing();
                    resetValues();
                    setTimeout(() => {
                        setMessage("");
                    }, 800);
                } else {
                    setMessage(res.data.message);
                    setMessageClass("error");
                    setTimeout(() => {
                        setMessage("");
                    }, 5000);
                }
            })
            .catch((error) => {
                console.error(error);
                setMessage("Something went wrong. Try again.");
                setMessageClass("error");
                setTimeout(() => {
                    setMessage("");
                }, 5000);
            });
        } catch (err) {
            console.log(err);
        }
    };

    const addFunction = async (e) => {
        try {
            e.preventDefault();
            const responseCodeObj = await strToObj(responseCode);
            superadminapi.post(addSendBoxForAdmin, {
                userId:props.details.userId,
                userName:props.details.userName,
                name: name,
                status:statusValue,
                category: category,
                endPoint: endPoint,
                documentationLink: documentationLink,
                curlCode: curlCode,
                responseCode: responseCodeObj,
                method: methodValue,
                services: services,
                paramName: paramName,
                headerParamName: headerParamName,
                description: description,
            })
            .then((res) => {
                if (res.data.code === 200) {
                    setMessage(res.data.message);
                    setMessageClass("success");
                    fetchPricing();
                    resetValues();
                    setTimeout(() => {
                        setMessage("");
                    }, 800);
                } else {
                    setMessage(res.data.message);
                    setMessageClass("error");
                    setTimeout(() => {
                        setMessage("");
                    }, 5000);
                }
            })
            .catch((error) => {
                console.error(error);
                setMessage("Something went wrong. Try again.");
                setMessageClass("error");
                setTimeout(() => {
                    setMessage("");
                }, 5000);
            });
        } catch (err) {
            console.error(err);
            setMessage('please enter response code as object ex: {"key":"value"}');
            setMessageClass("error");
            e.preventDefault();
            setTimeout(() => {
                setMessage("");
            }, 5000);
        }
    };

    const deleteApiByObjectId = (objectId) => {
        setLoading(true);
        superadminapi.delete(deleteSendBoxForAdmin + objectId)
        .then(res => {
            if(res.data.code === 200) {
                setMessage(res.data.message);
                setMessageClass("success");
                fetchPricing();
                setDeleteApi(false);
                setEditModal(false);
                setLoading(false);
                setTimeout(() => {
                    setMessage("");
                }, 800);
            } else {
                setLoading(false);
                setMessage(res.data.message);
                setMessageClass("error");
                setTimeout(() => {
                    setMessage("");
                }, 5000);
            }
        })
        .catch(err => {
            console.log(err);
        })
    }

    const previousFunction = () => {
        if (tablePage > 1) {
            setTablePage(tablePage - 1);
            setTableArrayEnd(tableArrayStart);
            setTableArrayStart(tableArrayStart - 10);
        }
    };

    const nextFunction = () => {
        if (tablePage !== Math.ceil(list.length / 10)) {
            setTablePage(tablePage + 1);
            setTableArrayStart(tableArrayEnd);
            if (list.length >= tableArrayEnd + 10) {
                setTableArrayEnd(tableArrayEnd + 10);
            } else {
                setTableArrayEnd(list.length);
            }
        }
    };

    const resetValues = () => {
        setAddModal(false);
        setEditModal(false);
        setId("");
        setName("");
        setCategory("");
        setEndPoint("");
        setDocumentationLink("");
        setCurlCode("");
        setResponseCode("");
        setMethodValue("get");
        setParamName("");
        setDescription("");
    };

    const openEditModal = (x) => {
        setEditModal(true);
        setId(x._id);
        setName(x.name);
        setCategory(x.category);
        setEndPoint(x.endPoint);
        setDocumentationLink(x.documentationLink);
        setCurlCode(x.curlCode);
        setResponseCode(x.responseCode);
        setMethodValue(x.method);
        setServices(x.services);
        setParamName(x.paramName);
        setHeaderParamName(x.headerParamName);
        setDescription(x.description);
    };

    const changeCategory = (x) => {
        setSelectCategory(x);
        if (x) {
            setList(defaultList.filter((item) => item.category.toLowerCase() === x.toLowerCase()));
        } else {
            setList(defaultList);
        }
    };

    return (
        <>
            {message && <Message message={message} messageClass={messageClass} closeMessage={() => setMessage('')} />}
            {addModal && (
                <div className={styles["pricing-modal-container"]}>
                    <div className={styles["pricing-modal-background"]} onClick={() => resetValues()}></div>
                    <div className={styles["pricing-modal-box"]}>
                        <form onSubmit={addFunction}>
                            <div className={styles['pricing-modal-header']}>
                                <h4>Add API pricing</h4>
                                <img src="../images/times.svg" alt="times" onClick={() => resetValues()} />
                            </div>
                            <div className={styles['pricing-modal-body']}>
                                <div className={styles['small-input']}>
                                    <div>
                                        <label>Name</label>
                                        <input type="text" placeholder="Enter name" value={name} onChange={(e) => setName(e.target.value)} required />
                                    </div>
                                    <div>
                                        <label>Category</label>
                                        <input type="text" placeholder="Enter category" value={category} onChange={(e) => setCategory(e.target.value)} required />
                                    </div>
                                    <div>
                                        <label>Method</label>
                                        <select value={methodValue} onChange={(e) => setMethodValue(e.target.value)}>
                                            <option value="get">Get</option>
                                            <option value="post">Post</option>
                                        </select>
                                    </div>
                                    <div>
                                        <label>Service</label>
                                        <input type="text" placeholder="Enter service" value={services} onChange={(e) => setServices(e.target.value)} required />
                                    </div>
                                    <div>
                                        <label>Status</label>
                                        <select value={statusValue} onChange={(e) => setStatusValue(e.target.value)}>
                                            <option value="true">true</option>
                                            <option value="false">false</option>
                                        </select>
                                    </div>
                                </div>
                                <label>Description</label>
                                <input type="text" name="description" placeholder="Enter description" value={description} onChange={(e) => setDescription(e.target.value)} required />
                                <label>Parameteres name</label>
                                <input type="text" name="paramName" placeholder="Enter paramaters name" value={paramName} onChange={(e) => setParamName(e.target.value)} />
                                <label>Headers parameteres name</label>
                                <input type="text" name="headerParamName" placeholder="Enter header paramaters name" value={headerParamName} onChange={(e) => setHeaderParamName(e.target.value)} />
                                <label>End point</label>
                                <input type="text" placeholder="Enter endPoint" value={endPoint} onChange={(e) => setEndPoint(e.target.value)} required />
                                <label>Documentation link</label>
                                <input type="text" placeholder="Enter documentationLink" value={documentationLink} onChange={(e) => setDocumentationLink(e.target.value)} required />
                                <label>Curl code</label>
                                <textarea className={styles["textArea"]} defaultValue={curlCode} name="curlCode" placeholder="Enter curl code" onChange={(e) => setCurlCode(e.target.value)} rows="4" cols="50" required></textarea>
                                <label>Response code</label>
                                <textarea className={styles["textArea"]} defaultValue={responseCode} name="responseCode" placeholder="Enter response code" onChange={(e) => setResponseCode(e.target.value)} rows="4" cols="50" required></textarea>
                            </div>
                            <div className={styles['pricing-modal-footer']}>
                                <div>
                                    <button type="submit" className="btn primary-button"> Save </button>
                                </div>
                                <div></div>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            {editModal && (
                deleteApi
                ?<div className={styles["export-container"]}>
                    <div className={styles["export-background"]} onClick={() => setDeleteApi(false)}></div>
                    <div className={styles["export-box"]}>
                        <h4>Delete Account</h4>
                        <div>Are you sure you want to delete this api? <br/> After doing this you will loose all data for this api permanently.</div>
                        <div className={styles["export-buttons"]}>
                            <button className="btn secondary-button" onClick={() => setDeleteApi(false)}>Don't delete</button>
                            {
                                loading
                                ?<button className="btn primary-button" disabled>Deleting</button>
                                :<button className="btn primary-button" onClick={() => { deleteApiByObjectId(id) } }>{loading?"...wait":"Delete"}</button>
                            }
                        </div>
                    </div>
                </div>  
                :<div>
                    <div className={styles["pricing-modal-container"]}>
                        <div className={styles["pricing-modal-background"]} onClick={() => resetValues()}></div>
                        <div className={styles["pricing-modal-box"]}>
                            <form onSubmit={editFunction}>
                                <div className={styles['pricing-modal-header']}>
                                    <h4>Edit API pricing</h4>
                                    <img src="../images/times.svg" alt="times" onClick={() => resetValues()} />
                                </div>
                                <div className={styles['pricing-modal-body']}>
                                    <div className={styles['small-input']}>
                                        <div>
                                            <label>Name</label>
                                            <input type="text" placeholder="Enter name" value={name} onChange={(e) => setName(e.target.value)} />
                                        </div>
                                        <div>
                                            <label>Category</label>
                                            <input type="text" placeholder="Enter category" value={category} onChange={(e) => setCategory(e.target.value)} />
                                        </div>
                                        <div>
                                            <label>Service</label>
                                            <input type="text" placeholder="Enter service" value={services} onChange={(e) => setServices(e.target.value)} />
                                        </div>
                                        <div>
                                            <label>Method </label>
                                            <select value={methodValue} onChange={(e) => setMethodValue(e.target.value)}>
                                                <option value="get">Get</option>
                                                <option value="post">Post</option>
                                            </select>
                                        </div>
                                        <div>
                                            <label>Status</label>
                                            <select value={statusValue} onChange={(e) => setStatusValue(e.target.value)}>
                                                <option value="true">true</option>
                                                <option value="false">false</option>
                                            </select>
                                        </div>
                                    
                                    </div>
                                    <label>Description</label>
                                    <input type="text" name="description" placeholder="Enter description" value={description} onChange={(e) => setDescription(e.target.value)} required />
                                    <label>Parameteres name</label>
                                    <input type="text" name="paramName" placeholder="Enter paramaters name" value={paramName} onChange={(e) => setParamName(e.target.value)} />
                                    <label>Headers parameteres name</label>
                                    <input type="text" name="headerParamName" placeholder="Enter header paramaters name" value={headerParamName} onChange={(e) => setHeaderParamName(e.target.value)} />
                                    <label>End point</label>
                                    <input type="text" placeholder="Enter endPoint" value={endPoint} onChange={(e) => setEndPoint(e.target.value)} />
                                    <label>Documentation link</label>
                                    <input type="text" placeholder="Enter documentationLink" value={documentationLink} onChange={(e) => setDocumentationLink(e.target.value)} />
                                    <label>Curl code</label>
                                    <textarea className={styles["textArea"]} defaultValue={curlCode} name="curlCode" placeholder="Enter curl code" onChange={(e) => setCurlCode(e.target.value)} rows="4" cols="50" required></textarea>
                                    <label>Response code</label>
                                    <textarea className={styles["textArea"]} defaultValue={JSON.stringify(responseCode)} name="responseCode" placeholder="Enter response code" onChange={(e) => setResponseCode(e.target.value)} rows="4" cols="50" required></textarea>
                                </div>
                                <div className={styles['pricing-modal-footer']}>
                                    <div>
                                        <button type="submit" className="btn primary-button"> Save </button>
                                    </div>
                                    <div>
                                        <button type="click" onClick={()=>{ setDeleteApi(true) } } className="btn secondary-button"> Delete </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}

            <div className={styles["apiPrice-container"]}>
                <h4>API Pricing</h4>
                <div className={styles["admin-dashboard-body"]}>
                    {
                        loading
                        ?<Loading />
                        :<div className={styles["api-pricing"]}>
                            <div className={styles["search-box"]}>
                                <button className="btn primary-button float-right" onClick={()=>setAddModal(true)}> Add new </button>
                                <label>Select category</label>
                                <select value={selectCategory} onChange={(e) => changeCategory(e.target.value)}>
                                    <option value="">-- All --</option>
                                    {categoryArray.map((item, i) => (
                                        <option key={i} value={item} className={styles["categoryOption"]} > {item} </option>
                                    ))}
                                </select>
                            </div>
                            <div className={styles["pricing-head"]}>
                                <span>#</span>
                                <span>Name</span>
                                <span>Category</span>
                                <span>Created at</span>
                                <span>Updated at</span>
                                <span></span>
                            </div>
                            {list.slice(tableArrayStart, tableArrayEnd).map((item, i) => (
                                <div className={styles["pricing-body"]} key={i}>
                                    <p><span>#</span> {tableArrayStart + i + 1}</p>
                                    <p><span>Name:</span>{item.name}</p>
                                    <p><span>Category: </span>  {item.category}</p>
                                    <p><span>Created at: </span> {`${new Date(item.createdAt)}`.substr(0, 24)}</p>
                                    <p><span>Updated at: </span> {item.updateAt ? `${new Date(item.updateAt)}`.substr(0, 24) : "-"}</p>
                                    <p onClick={() => openEditModal(item)}>Edit</p>
                                </div>
                            ))}
                            {Math.ceil(list.length / 10) > 1 && (
                                <div className="pagination-box">
                                    {
                                        tablePage <= 1
                                        ?<span className="fa fa-angle-left pagination-arrow-disabled"></span>
                                        :<span className="fa fa-angle-left pagination-arrow" onClick={() => previousFunction(tablePage - 1)}></span>
                                    }
                                    <span> {tablePage} of {Math.ceil(list.length / 10)} pages </span>
                                    {
                                        tablePage !== Math.ceil(list.length / 10)
                                        ?<span className="fa fa-angle-right pagination-arrow" onClick={() => nextFunction(tablePage + 1)}></span>
                                        :<span className="fa fa-angle-right pagination-arrow-disabled"></span>
                                    }
                                </div>
                            )}
                        </div>
                    }
                </div>
            </div>
        </>
    )
}