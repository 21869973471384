import React, { useState, useEffect } from "react";
import oceanapi, { addApiSendBox, updateApiSendBox, deleteApiSendBox } from "../../Base/OceanConfig";
import superadminapi, { getApiDefaultSendBox } from "../../Base/SuperAdminConfig";
import Message from "../../Components/Message/Message";
import Loading from "../../Components/Loading/Loading";
import styles from "./testApi.module.css";

export default function TestApis() {
    const [defaultList, setDefaultList] = useState([]);
    const [list, setList] = useState([]);
    const [categoryArray, setCategoryArray] = useState([]);
    const [addModal, setAddModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [selectCategory, setSelectCategory] = useState("");
    const [id, setId] = useState("");
    const [name, setName] = useState("");
    const [category, setCategory] = useState("");
    const [service, setService] = useState("");
    const [endPoint, setEndPoint] = useState("");
    const [documentationLink, setDocumentationLink] = useState("");
    const [curlCode, setCurlCode] = useState("");
    const [responseCode, setResponseCode] = useState("");
    const [description, setDescription] = useState("");
    const [tablePage, setTablePage] = useState(1);
    const [tableArrayStart, setTableArrayStart] = useState(0);
    const [tableArrayEnd, setTableArrayEnd] = useState(10);
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState("");
    const [messageClass, setMessageClass] = useState("");
    const [methodValue, setMethodValue] = useState("get");
    const [paramName, setParamName] = useState("");
    const [headerParamName, setHeaderParamName] = useState("");
    const [verified, setVerified] = useState(false);
    const [hidden, setHidden] = useState(false);
    const [requiredFields, setRequiredFields] = useState("");
    const [deleteApi, setDeleteApi] = useState(false);

    const fetchPricing = () => {
        superadminapi.get(getApiDefaultSendBox)
        .then((res) => {
            if (res.data.code === 200) {
                setLoading(false);
                setDefaultList(res.data.apiData);
                setList(res.data.apiData);
                let categoryArr = [];
                for (let i = 0; i < res.data.apiData.length; i++) {
                    if (categoryArr.indexOf(res.data.apiData[i].category.toLowerCase()) === -1) {
                        categoryArr.push(res.data.apiData[i].category.toLowerCase());
                    }
                }
                setCategoryArray(categoryArr);
            } else {
                setLoading(false);
                setList([]);
            }
        })
        .catch((error) => {
            console.error(error);
            setLoading(false);
        });
    };

    useEffect(() => {
        fetchPricing();
        // eslint-disable-next-line 
    }, []);

    const strToObj = (obj) => {
        return new Promise((res, rej) => {
            try {
                res(JSON.parse(obj));
            } catch (err) {
                console.log(err)
                rej("not valid object");
            }
        });
    };

    const addFunction = async (e) => {
        try {
            e.preventDefault();
            const responseCodeObj = await strToObj((responseCode));
            oceanapi.post(addApiSendBox, {
                name: name,
                services:service,
                category: category,
                endPoint: endPoint,
                documentationLink: documentationLink,
                curlCode: curlCode,
                responseCode: responseCodeObj,
                method: methodValue,
                paramName: paramName,
                description: description,
                // slab1Price: slab1Price,
                // slab2Price: slab2Price,
                // slab3Price: slab3Price,
                // slab4Price: slab4Price,
                headerParamName: headerParamName,
                requiredFields: requiredFields,
                isHidden: hidden,
                isVerified: verified
            })
            .then((res) => {
                if (res.data.code === 200) {
                    setMessage(res.data.message);
                    setMessageClass("success");
                    fetchPricing();
                    resetValues();
                    setTimeout(() => {
                        setMessage("");
                    }, 800);
                } else {
                    setMessage(res.data.message);
                    setMessageClass("error");
                    setTimeout(() => {
                        setMessage("");
                    }, 5000);
                }
            })
            .catch((error) => {
                console.error(error);
                setMessage("Something went wrong. Try again.");
                setMessageClass("error");
                setTimeout(() => {
                    setMessage("");
                }, 5000);
            });
        } catch (err) {
            console.error(err);
            setMessage('please enter response code as object ex: {"key":"value"}');
            setMessageClass("error");
            e.preventDefault();
            setTimeout(() => {
                setMessage("");
            }, 5000);
        }
    };

    const editFunction = async (e) => {
        try {
            e.preventDefault();
            const responseCodeObj = typeof(responseCode) === "object" ? responseCode : await strToObj(responseCode);
            oceanapi.post(updateApiSendBox + id, {
                services:service,
                name: name,
                category: category,
                endPoint: endPoint,
                documentationLink: documentationLink,
                curlCode: curlCode,
                responseCode: responseCodeObj,
                method: methodValue,
                paramName: paramName === null ? "" : paramName,
                headerParamName: headerParamName === null ? "" : headerParamName,
                description: description,
                requiredFields: requiredFields,
                isHidden: hidden,
                isVerified: verified
                // slab1Price: slab1Price,
                // slab2Price: slab2Price,
                // slab3Price: slab3Price,
                // slab4Price: slab4Price,
            })
            .then((res) => {
                if(res.data.code === 200) {
                    setMessage(res.data.message);
                    setMessageClass("success");
                    fetchPricing();
                    resetValues();
                    setTimeout(() => {
                        setMessage("");
                    }, 1000);
                } else {
                    setMessage(res.data.message);
                    setMessageClass("error");
                    setTimeout(() => {
                        setMessage("");
                    }, 5000);
                }
            })
            .catch((error) => {
                console.error(error);
                setMessage("Something went wrong. Try again.");
                setMessageClass("error");
                setTimeout(() => {
                    setMessage("");
                }, 5000);
            });
        } catch (err) {
            console.log(err);

        }
    };

    const resetValues = () => {
        setAddModal(false);
        setEditModal(false);
        setId("");
        setName("");
        setCategory("");
        setService("")
        setEndPoint("");
        setDocumentationLink("");
        setCurlCode("");
        setResponseCode("");
        setMethodValue("get");
        setParamName("");
        setDescription("");
        setVerified(false);
        setHidden(false);
        setRequiredFields("");
    };

    const previousFunction = () => {
        if (tablePage > 1) {
            setTablePage(tablePage - 1);
            setTableArrayEnd(tableArrayStart);
            setTableArrayStart(tableArrayStart - 10);
        }
    };

    const nextFunction = () => {
        if (tablePage !== Math.ceil(list.length / 10)) {
            setTablePage(tablePage + 1);
            setTableArrayStart(tableArrayEnd);
            if (list.length >= tableArrayEnd + 10) {
                setTableArrayEnd(tableArrayEnd + 10);
            } else {
                setTableArrayEnd(list.length);
            }
        }
    };

    const changeCategory = (x) => {
        setSelectCategory(x);
        if(x) {
            setList(defaultList.filter((item) => item.category.toLowerCase() === x.toLowerCase()));
        } else {
            setList(defaultList);
        }
    };

    const openEditModal = (x) => {
        setEditModal(true);
        setId(x._id);
        setName(x.name);
        setCategory(x.category);
        setService(x.services);
        setEndPoint(x.endPoint);
        setDocumentationLink(x.documentationLink);
        setCurlCode(x.curlCode);
        setResponseCode(x.responseCode);
        setMethodValue(x.method);
        setParamName(x.paramName);
        setHeaderParamName(x.headerParamName);
        setDescription(x.description);
        setDescription(x.description);
        setDescription(x.description);
        setVerified(x.isVerified===undefined?verified:x.isVerified);
        setHidden(x.isHidden===undefined?hidden:x.isHidden);
        setRequiredFields(x.requiredFields===undefined?requiredFields:x.requiredFields);
    };

    const deleteApiByObjectId = (objectId) => {
        setLoading(true);
        oceanapi.delete(deleteApiSendBox + objectId).then(res => {
            if (res.data.code === 200) {
                resetValues();
                setMessage(res.data.message);
                setMessageClass("success");
                fetchPricing();
                setDeleteApi(false);
                setEditModal(false);
                setLoading(false);
                setTimeout(() => {
                    setMessage("");
                }, 800);
            }
            else {
                setLoading(false);
                setMessage(res.data.message);
                setMessageClass("error");
                setTimeout(() => {
                    setMessage("");
                }, 5000);
            }
        }).catch(err => {
            console.log(err);
        })
    }

    return (
        <>
            {message && <Message message={message} messageClass={messageClass} closeMessage={() => setMessage('')} />}
            {/* editFunctionadd */}
            {addModal && <div className="modal-container">
                <div className="modal-background" onClick={() => resetValues()}></div>
                <div className="modal-box">
                    <div className={styles["pricing-modal-box"]}>
                        <form onSubmit={addFunction}>
                            <div className={styles["pricing-modal-head"]}>
                                <h4>Add API</h4>
                                <img src="../images/cancel.svg" alt="cancel" onClick={() => resetValues()}/>
                            </div>
                            <div className={styles["pricing-modal-body"]}>
                                <div className={styles["small-input"]}>
                                    <div>
                                        <label>Name</label>
                                        <input type="text" placeholder="Enter name" value={name} onChange={(e) => setName(e.target.value)} required />
                                    </div>
                                    <div>
                                        <label>Category</label>
                                        <input type="text" placeholder="Enter category" value={category} onChange={(e) => setCategory(e.target.value)} required />
                                    </div>
                                    <div>
                                        <label>Service</label>
                                        <input type="text" placeholder="Enter service" value={service} onChange={(e) => setService(e.target.value)} required />
                                    </div>
                                    {/* <div>
                                        <label>Slab1 price</label>
                                        <input type="text" placeholder="Enter slab1Price" value={slab1Price} onChange={(e) => setSlab1Price(e.target.value)} required />
                                    </div>
                                    <div>
                                        <label>Slab2 price</label>
                                        <input type="text" placeholder="Enter slab2Price" value={slab2Price} onChange={(e) => setSlab2Price(e.target.value)} required />
                                    </div>
                                    <div>
                                        <label>Slab3 price</label>
                                        <input type="text" placeholder="Enter slab3Price" value={slab3Price} onChange={(e) => setSlab3Price(e.target.value)} required />
                                    </div>
                                    <div>
                                        <label>Slab4 price</label>
                                        <input type="text" placeholder="Enter slab4Price" value={slab4Price} onChange={(e) => setSlab4Price(e.target.value)} required />
                                    </div> */}
                                    <div>
                                        <label>Method</label>
                                        <select value={methodValue} onChange={(e) => setMethodValue(e.target.value)}>
                                            <option value="get">Get</option>
                                            <option value="post">Post</option>
                                        </select>
                                    </div>
                                    <div>
                                        <label>Is Verified</label>
                                        <select value={verified} onChange={(e) => setVerified(e.target.value)}>
                                            <option value="true">true</option>
                                            <option value="false">false</option>
                                        </select>
                                    </div>
                                    <div>
                                        <label>Is Hidden</label>
                                        <select value={hidden} onChange={(e) => setHidden(e.target.value)}>
                                            <option value="true">true</option>
                                            <option value="false">false</option>
                                        </select>
                                    </div>
                                </div>
                                <label>Description</label>
                                <input type="text" name="description" placeholder="Enter description" value={description} onChange={(e) => setDescription(e.target.value)} required />
                                <label>End point</label>
                                <input type="text" placeholder="Enter endPoint" value={endPoint} onChange={(e) => setEndPoint(e.target.value)} required />
                                <label>Documentation link</label>
                                <input type="text" placeholder="Enter documentationLink" value={documentationLink} onChange={(e) => setDocumentationLink(e.target.value)} required />
                                <label>Curl code</label>
                                <textarea className={styles["textArea"]} defaultValue={curlCode} name="curlCode" placeholder="Enter curl code" onChange={(e) => setCurlCode(e.target.value)} rows="4" cols="50" required></textarea>
                                <label>Response code</label>
                                <textarea className={styles["textArea"]} defaultValue={responseCode} name="responseCode" placeholder="Enter response code" onChange={(e) => setResponseCode(e.target.value)} rows="4" cols="50" required></textarea>
                                <label>Required fields</label>
                                <input type="text" name="requiredFields" placeholder="Enter required fields" value={requiredFields} onChange={(e) => setRequiredFields(e.target.value)} />
                                <label>Parameteres Name</label>
                                <input type="text" name="paramName" placeholder="Enter paramaters name" value={paramName} onChange={(e) => setParamName(e.target.value)} />
                                <label>Headers Parameteres Name</label>
                                <input type="text" name="headerParamName" placeholder="Enter header paramaters name" value={headerParamName} onChange={(e) => setHeaderParamName(e.target.value)} />
                            </div>
                            <div className={styles["pricing-modal-footer"]}>
                                <div>
                                    <button type="submit" className="btn primary-button"> Save </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>}
            {editModal && (
                deleteApi ?
                    <div className={styles["export-container"]}>
                        <div className={styles["export-background"]} onClick={() => setDeleteApi(false)}></div>
                        <div className={styles["export-box"]}>
                            <h4>Delete Account</h4>
                            <div>Are you sure you want to delete this api? <br /> After doing this you will loose all data for this api permanently.</div>
                            <div className={styles["export-buttons"]}>
                                <button className="btn secondary-button" onClick={() => setDeleteApi(false)}>Don't delete</button>
                                {
                                    loading
                                    ? <button className="btn primary-button" disabled>Deleting</button>
                                    : <button className="btn primary-button" onClick={() => { deleteApiByObjectId(id) }}>{loading ? "...wait" : "Delete"}</button>
                                }
                            </div>
                        </div>
                    </div>
                    :
                        <div className="modal-container">
                            <div className="modal-background" onClick={() => resetValues()}></div>
                            <div className="modal-box">
                                <div className={styles["pricing-modal-box"]}>
                                    <form onSubmit={editFunction}>
                                        <div className={styles["pricing-modal-head"]}>
                                            <h4>Edit API</h4>
                                            <img src="../images/cancel.svg" alt="cancel" onClick={() => resetValues()}/>
                                        </div>
                                        <div className={styles["pricing-modal-body"]}>
                                            <div className={styles["small-input"]}>
                                                <div>
                                                    <label>Name</label>
                                                    <input type="text" placeholder="Enter name" value={name} onChange={(e) => setName(e.target.value)} />
                                                </div>
                                                <div>
                                                    <label>Category</label>
                                                    <input type="text" placeholder="Enter category" value={category} onChange={(e) => setCategory(e.target.value)} />
                                                </div>
                                                <div>
                                                    <label>Service</label>
                                                    <input type="text" placeholder="Enter service" value={service} onChange={(e) => setService(e.target.value)} />
                                                </div>
                                                {/* <div>
                                                    <label>Slab1 price</label>
                                                    <input type="text" placeholder="Enter slab1Price" value={slab1Price} onChange={(e) => setSlab1Price(e.target.value)} />
                                                </div>
                                                <div>
                                                    <label>Slab2 price</label>
                                                    <input type="text" placeholder="Enter slab2Price" value={slab2Price} onChange={(e) => setSlab2Price(e.target.value)} />
                                                </div>
                                                <div>
                                                    <label>Slab3 price</label>
                                                    <input type="text" placeholder="Enter slab3Price" value={slab3Price} onChange={(e) => setSlab3Price(e.target.value)} />
                                                </div>
                                                <div>
                                                    <label>Slab4 price</label>
                                                    <input type="text" placeholder="Enter slab4Price" value={slab4Price} onChange={(e) => setSlab4Price(e.target.value)} />
                                                </div> */}
                                                <div>
                                                    <label>Method</label>
                                                    <select value={methodValue} onChange={(e) => setMethodValue(e.target.value)}>
                                                        <option value="get">Get</option>
                                                        <option value="post">Post</option>
                                                    </select>
                                                </div>
                                                <div>
                                                    <label>Is Verified</label>
                                                    <select value={verified} onChange={(e) => setVerified(e.target.value)}>
                                                        <option value="true">true</option>
                                                        <option value="false">false</option>
                                                    </select>
                                                </div>
                                                <div>
                                                    <label>Is Hidden</label>
                                                    <select value={hidden} onChange={(e) => setHidden(e.target.value)}>
                                                        <option value="true">true</option>
                                                        <option value="false">false</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <label>Description</label>
                                            <input type="text" name="description" placeholder="Enter description" value={description} onChange={(e) => setDescription(e.target.value)} required />
                                            <label>End point</label>
                                            <input type="text" placeholder="Enter endPoint" value={endPoint} onChange={(e) => setEndPoint(e.target.value)} />
                                            <label>Documentation link</label>
                                            <input type="text" placeholder="Enter documentationLink" value={documentationLink} onChange={(e) => setDocumentationLink(e.target.value)} />
                                            <label>Curl code</label>
                                            <textarea className={styles["textArea"]} defaultValue={curlCode} name="curlCode" placeholder="Enter curl code" onChange={(e) => setCurlCode(e.target.value)} rows="4" cols="50" required></textarea>
                                            <label>Response code</label>
                                            <textarea className={styles["textArea"]} defaultValue={JSON.stringify(responseCode)} name="responseCode" placeholder="Enter response code" onChange={(e) => setResponseCode(e.target.value)} rows="4" cols="50" required></textarea>
                                            <label>Required fields</label>
                                            <input type="text" name="requiredFields" placeholder="Enter required fields" value={requiredFields} onChange={(e) => setRequiredFields(e.target.value)} />
                                            <label>Parameteres Name</label>
                                            <input type="text" name="paramName" placeholder="Enter paramaters name" value={paramName} onChange={(e) => setParamName(e.target.value)} />
                                            <label>Headers Parameteres Name</label>
                                            <input type="text" name="headerParamName" placeholder="Enter header paramaters name" value={headerParamName} onChange={(e) => setHeaderParamName(e.target.value)} />
                                        </div>
                                        <div className={styles["pricing-modal-footer"]}>
                                            <div>
                                                <button type="submit" className="btn primary-button"> Save </button>
                                            </div>
                                            <div>
                                                <button type="click" onClick={() => { setDeleteApi(true) }} className="btn secondary-button">
                                                    Delete
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>)}
            {loading ? (
                <Loading />
            ) : (
                <>
                    <div className={styles["api-pricing"]}>
                        <div className={styles["search-box"]}>
                            <button
                                className="btn primary-button float-right mt-7"

                                onClick={() => setAddModal(true)}
                            >
                                Add new
                            </button>
                            <label>Select category</label>
                            <select
                                value={selectCategory}
                                onChange={(e) => { changeCategory(e.target.value); setTableArrayEnd(10); setTableArrayStart(0); setTablePage(1) }}
                            >
                                <option value="">-- All --</option>
                                {categoryArray.map((item, i) => (
                                    <option key={i} value={item} className={styles["categoryOption"]} >
                                        {item}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className={styles["pricing-head"]}>
                            <span>#</span>
                            <span>Method</span>
                            <span>Name</span>
                            <span>Description</span>
                            {/* <span>Slab 1</span>
                            <span>Slab 2</span>
                            <span>Slab 3</span>
                            <span>Slab 4</span> */}
                            <span>Category</span>
                            <span>Created at</span>
                            <span>Updated at</span>
                            <span></span>
                        </div>
                        {list.slice(tableArrayStart, tableArrayEnd).map((item, i) => (
                            <div className={styles["pricing-body"]} key={i}>
                                <p><span>#</span> {tableArrayStart + i + 1}</p>
                                <p className={styles[item.method]}><span>Method:</span> {item.method}</p>
                                <p><span>Name:</span> {item.name}</p>
                                <p><span>Description:</span> {item.description}</p>
                                {/* <p><span>Slab 1:</span> ₹{item.slab1Price}</p>
                                <p><span>Slab 2:</span> ₹{item.slab2Price}</p>
                                <p><span>Slab 3:</span> ₹{item.slab3Price}</p>
                                <p><span>Slab 4:</span> ₹{item.slab4Price}</p> */}
                                <p><span>Category:</span> {item.category}</p>
                                <p><span>Created at:</span> {item.createdAt ? `${new Date(item.createdAt)}`.substr(0, 24) : "-"}</p>
                                <p><span>Updated at:</span> {item.updatedAt ? `${new Date(item.updatedAt)}`.substr(0, 24) : "-"}</p>
                                <p onClick={() => openEditModal(item)}>Edit</p>
                            </div>
                        ))}
                        {Math.ceil(list.length / 10) > 1 && (
                            <div className="pagination-box">
                                {tablePage <= 1 ? (
                                    <span className="fa fa-angle-left pagination-arrow-disabled"></span>
                                ) : (
                                    <span
                                        className="fa fa-angle-left pagination-arrow"
                                        onClick={() => previousFunction(tablePage - 1)}
                                    ></span>
                                )}
                                <span>
                                    {" "}
                                    {tablePage} of {Math.ceil(list.length / 10)} pages{" "}
                                </span>
                                {tablePage !== Math.ceil(list.length / 10) ? (
                                    <span
                                        className="fa fa-angle-right pagination-arrow"
                                        onClick={() => nextFunction(tablePage + 1)}
                                    ></span>
                                ) : (
                                    <span className="fa fa-angle-right pagination-arrow-disabled"></span>
                                )}
                            </div>
                        )}
                    </div>
                </>
            )}
        </>
    );
}