import React from 'react'

const BtnArrowPrimary = ({btnName, action}) => {
  return (
    <div>
        <button className='bg-primaryColor py-3.5 px-11 rounded' onClick={() => action()}> 
            <span className='text-white font-bold'>{btnName}</span> <span className='inline-block ml-3.5'><img src="./images/landing_page/arrow.svg" alt="arrow" /></span>
        </button>
    </div>
  )
}

export default BtnArrowPrimary