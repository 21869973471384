import React, { useState } from 'react';
import adminapi, { adminAccessControl, adminStatusControl } from '../../../Base/AdminConfig';
import Message from '../../../Components/Message/Message';
import styles from "../user-accounts.module.css";

export default function Settings(props) {
    const userId = props.details.userId;
    const [ message, setMessage ] = useState('');
    const [ isFullAccess, setIsFullAccess ] = useState(props.details.fullAccess);
    const [ isActive, setIsActive ] = useState(props.details.active);
    const [ messageClass, setMessageClass ] = useState('');
    
    const handleChange = (x) => {
        adminapi.post(adminAccessControl, {
            userId: userId,
            fullAccess: x
        })
        .then((res) => {
            if(res.data.code === 200){
                setMessageClass('success');
                setMessage(res.data.message);
                setIsFullAccess(x);
                const userDetail = JSON.parse(sessionStorage.getItem('user-detail'));
                userDetail.fullAccess = x;
                sessionStorage.setItem('user-detail', JSON.stringify(userDetail));
                setTimeout(() => {
                    setMessage('')
                }, 5000);
            } else {
                setMessageClass('error');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            }
        })
        .catch((error) => {
            console.error(error);
            setMessageClass('error');
            setMessage('Something went wrong, Try again.');
            setTimeout(() => {
                setMessage('');
            }, 5000);
        })
    }

    const handleActive = (x) => {
        adminapi.post(adminStatusControl, {
            userId: userId,
            isActive: x
        })
        .then((res) => {
            if(res.data.code === 200){
                setMessageClass('success');
                setMessage(res.data.message);
                setIsActive(x);
                setTimeout(() => {
                    setMessage('')
                }, 5000);
            } else {
                setMessageClass('error');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            }
        })
        .catch((error) => {
            console.error(error);
            setMessageClass('error');
            setMessage('Something went wrong, Try again.');
            setTimeout(() => {
                setMessage('');
            }, 5000);
        })
    }

    return (<>
        {message && <Message message={message} messageClass={messageClass} closeMessage={() => setMessage('')} />}
        <div>
            <div className={styles['header-settings']}>
                Plan Status
            </div>
            <div>
                <div className={styles['settings-body']}>
                    <span>Status</span>
                    <div>
                        <span><input type='radio' name='status' value={true} onChange={e => { handleActive(true) }} checked={isActive ? 'checked' : ''} ></input> Active</span>
                        <span><input type='radio' name='status' value={false} onChange={e => { handleActive(false) }} checked={isActive ? '' : 'checked'} ></input> Inactive</span>
                    </div>
                </div>
                <div className={styles['settings-body']}>
                    <span>Access Control</span>
                    <div>
                        <span><input type='radio' name='access-control' value={true} onChange={e => { handleChange(true) }} checked={isFullAccess ? 'checked' : ''}></input> Full Access</span>
                        <span><input type='radio' name='access-control' value={false} onChange={e => { handleChange(false) }} checked={isFullAccess ? '' : 'checked'}></input> Partial Access</span>
                    </div>
                </div>
            </div>
        </div>
    </>);
}