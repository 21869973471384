import React, { useEffect, useState } from 'react';
import styles from "../user-accounts.module.css";
import oceanapi, { fetchProductTransactions } from '../../../Base/OceanConfig';
import ProductionExport from './Transactions/ProductionExport';

export default function ProductTransactions(props) {
    const [ transactiondata, setTransactionData ] = useState([]);
    const [ pageNumber, setPageNumber ] = useState(1);
    const [ records, setRecords ] = useState();
    const defaultPriceList =props.defaultPriceList

    const changeDescription = (x) => {
        const array = defaultPriceList.filter(item => item.services === x);
        if(array.length >= 1){
            return array[0].apiName
        } else {
            return "NA"
        }
    }

    const getProductiondata = () => {
        oceanapi.get(fetchProductTransactions + props.userId + `?pageNumber=${pageNumber}` )
        .then((res) => {
            if(res.data.code === 200){
                setTransactionData(res.data.records);
                setRecords(res.data.totalRecords);
            } else {
                setTransactionData([])
            }
        })
        .catch((error) => {
            console.log(error);
        })
    }

    useEffect(() => {
        getProductiondata();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber])

    return (<>
        <ProductionExport userId={props.userId} />
        <div className={styles['services-menu']}>
            <p><span>#</span></p>
            <p><span>Date</span></p>
            <p><span>PID</span></p>
            <p><span>Services</span></p>
            <p><span>Amount</span></p>
            <p><span>Balance</span></p>
            <p><span>Type</span></p>
        </div>
        {transactiondata.length !== 0
            ?<>
            {transactiondata.map((item, i) => (
                <div className={styles['transaction-data-menu']} key={i}>
                    <p><span>#</span> {((pageNumber - 1) * 50) + i + 1}</p>
                    <p><span>Date: </span>{`${new Date(item.createdAt)}`.substr(0, 24)}</p>
                    <p><span>PID:</span> {item.PID ? item.PID : 'NA'}</p>
                    <p><span>Services: </span>{item.amountType === 'Credit'?"Credit":changeDescription(item.services)}</p>
                    {
                        item.amountType === 'Credit'
                        ?<>
                            <p><span>Amount:</span> ₹{item.remainingAmount && item.remainingAmount.toLocaleString('en-IN')}</p>
                            <p><span>Balance:</span> NA</p>
                        </>
                        :<>
                            <p><span>Amount:</span> ₹{item.amount}</p>
                            <p><span>Balance:</span> ₹{item.remainingAmount && item.remainingAmount.toLocaleString('en-IN')}</p>
                        </>
                    }
                    <p className={styles[item.amountType === "Debit" ? "red" : "green"]}><span>Type: </span> {item.amountType}</p>
                </div> 
            ))}
            {Math.ceil(records/50) > 1 &&
                <div className="pagination-box">
                    {
                        pageNumber <= 1
                        ?<span className="fa fa-angle-left pagination-arrow-disabled"></span>
                        :<span className="fa fa-angle-left pagination-arrow" onClick={() => setPageNumber(pageNumber - 1)}></span>
                    }
                    <span> {pageNumber} of {Math.ceil(records/50)} pages </span>
                    {
                        pageNumber === Math.ceil(records/50)
                        ?<span className="fa fa-angle-right pagination-arrow-disabled"></span>
                        :<span className="fa fa-angle-right pagination-arrow" onClick={() => setPageNumber(pageNumber + 1)}></span>
                    }
                </div>
            }
            </>
            :<div className="no-records">
                <p>No transactions available</p>
            </div>
        }
    </>)
}             