import React,{useContext, useState, useEffect} from 'react'
import { ColorContext } from '../../../../Components/ColorContext/ColorContext';
import Message from '../../../../Components/Message/Message';
import Loading from '../../../../Components/Loading/Loading';
import adminapi, {userWhitlistIp} from '../../../../Base/AdminConfig';
// import IPDetails from './IPDetails/IPDetails';
import styles from "./whitelistip.module.css"

export default function WhitelistIp(props) {
    // const { css } = useContext(ColorContext);
    const [ loading, setLoading ] = useState(true);
    const [ data, setData ] = useState([]);
    const [ message, setMessage ] = useState("");
    const [ messageClass, setMessageClass ] = useState("");
    const { userName } = useContext(ColorContext);

    useEffect(()=>{
        fetchIpList();
        // eslint-disable-next-line
    },[])

    const fetchIpList =()=>{
        adminapi.get(userWhitlistIp +`${props.userId}/${userName}`)
        .then((res)=>{
            setLoading(false);
            if(res.data.code === 200)
            {
                setData(res.data.result.reverse());
            }
        })
        .catch((error) => {
            setLoading(false);
            console.error(error);
            setMessageClass('error');
            setMessage('Something went wrong, Try again.');
            setTimeout(() => {
                setMessage('');
            }, 5000);
        })
    }

    //comment  for testing

  return (
    <>
    {message && <Message message={message} messageClass={messageClass} closeMessage={() => setMessage('')}/>}

    {/* {detail && <IPDetails hitResult={result} closeModal={()=>setDetail(false)} />} */}
    <div className="admin-dashboard">
        <div className="admin-dashboard-body">
            <div className={styles['whitelist-box']}>
                <div className={styles['whitelist-head']}>
                        <p>#</p>
                        <p>Updated at</p>
                        <p>IP</p>
                        <p>Comments</p>
                        {/* <p>Details</p> */}
                </div>            
                {
                loading ?
                <Loading />
                :<>
                    {data.length >0?
                    data.map((info, i)=>{
                        return (
                        <div className={styles['whitelist-body']} key={i}>       
                            <p><span>#</span> {i + 1} </p>
                            <p><span>Updated at: </span> {`${new Date(info.updateAt)}`.substr(0, 24)}</p>
                            <p><span>IP: </span> {info.ip}</p>
                            <p><span>Comments: </span> {info.comments}</p>

                            {/* set the value of setDetails to true when we get dynamic data for view details */}
                            
                            {/* <p disabled style={css.color} >View Details</p>        */}
                        </div>)
                    })
                    :
                    <div className={styles['no-records']}>
                        <p>No whitelisted IPs found</p>
                    </div>                    
                    }
                </>
                }
                
            </div>
        </div>
    </div>
    </>
  )
}
