import React from 'react';
import styles from './steps.module.css';

export default function Steps(props){
    const step = props.step;
    const signup = props.signup;

    return (<>
        <div className={styles['registration-steps-container']}>
            <p className={styles['registration-steps-background']}>
                <span className={styles[step >= 1?'active':'']}></span>
                <span className={styles[step >= 2?'active':'']}></span>
                <span className={styles[step >= 3?'active':'']}></span>
                <span className={styles[step >= 4?'active':'']}></span>
                <span className={styles[step >= 5?'active':'']}></span>
                <span className={styles[step >= 6?'active':'']}></span>
                <span className={styles[step >= 7?'active':'']}></span>
            </p>
            <ul className={styles['registration-steps']}>
                <li className={styles[step >= 1?'active':'']}>
                    <span></span>
                    <p>{signup ? 'Basic details' : 'Login'}</p>
                </li>
                <li className={styles[step >= 2?'active':'']}>
                    <span></span>
                    <p>Pricing & Payment</p>
                </li>
                <li className={styles[step >= 3?'active':'']}>
                    <span></span>
                    <p>Upload domain details</p>
                </li>
                <li className={styles[step >= 4?'active':'']}>
                    <span></span>
                    <p>Upload logo</p>
                </li>
                <li className={styles[step >= 5?'active':'']}>
                    <span></span>
                    <p>SMTP Details</p>
                </li>
                <li className={styles[step >= 6?'active':'']}>
                    <span></span>
                    <p>Get your link</p>
                </li>
            </ul>
        </div>
    </>);
}