import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAdminUser } from '../../Utils/Common';
import adminapi, { fetchAdminCredits, fetchAdminProductCredits } from '../../Base/AdminConfig';
import styles from './navbar.module.css';
import Message from '../Message/Message';
import { ColorContext } from '../ColorContext/ColorContext';

export default function Navbar(){
    const navigate = useNavigate();
    const userId = getAdminUser();
    const { css } = useContext(ColorContext);
    const [ message, setMessage ] = useState('');
    const [ messageClass, setMessageClass ] = useState('');
    const [ apiCredits, setApiCredits ] = useState(0);
    const [ productCredits, setProductCredits ] = useState(0);
    const [ loading, setLoading ] = useState(true);
    const [ apiCreditLoader, setApiCreditLoader ] = useState(false);
    const [ productCreditLoader, setProductCreditLoader ] = useState(false);

    const getCredits = (x) => {
        if(x){
            setApiCreditLoader(true);
        } else {
            setLoading(true);
        }
        adminapi.get(fetchAdminCredits + userId)
        .then((res) => {
            setLoading(false);
            if(res.data.code === 200){
                setApiCredits(res.data.response.amount?res.data.response.amount:0);
                if(x){
                    setMessageClass('success');
                    setMessage("API wallet refreshed");
                    setTimeout(() => {
                        setMessage('')
                    }, 2000);
                }
                if(!x){
                    getProductCredits();
                }
                setApiCreditLoader(false);
            } else if(res.data.code === 401){
                navigate('/logout');
            } else {
                setApiCredits(0);
            }
        }).catch((error) => {
            console.log(error);
            setLoading(false);
            if(error.response.status === 401){
                navigate('/logout');
            }
        })
    }
    
    useEffect(() => {
        getCredits();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getProductCredits = (x) => {
        if(x){
            setProductCreditLoader(true);
        } else {
            setLoading(true);
        }
        adminapi.get(fetchAdminProductCredits + userId)
        .then((res) => {
            setLoading(false);
            if(res.data.code === 200){
                setProductCredits(res.data.response.amount?res.data.response.amount:0);
                if(x){
                    setMessageClass('success');
                    setMessage("Product wallet refreshed");
                    setTimeout(() => {
                        setMessage('')
                    }, 2000);
                }
                setProductCreditLoader(false);
            } else {
                setProductCredits(0);
            }
        }).catch((error) => {
            console.log(error);
            setLoading(false);
        })
    }

    return (<>
      {message && <Message message={message} messageClass={messageClass} closeMessage={() => setMessage('')} />}
        <div className={styles['navbar']}>
            {/* <div className={styles['wallet']} style={css.border}>
                <p>API wallet</p>
                <span>₹{apiCredits.toLocaleString("en-IN")}</span>
                <span className={`fa fa-refresh ${apiCreditLoader && 'fa-spin'}`}  onClick={() => getCredits(true)}></span>
            </div> */}
            <div className={styles["wallet"]} style={css.border}>
                <span className={`fa fa-refresh ${apiCreditLoader && 'fa-spin'}`} onClick={() =>  getCredits(true)}></span>
                <h6>API Wallet</h6>
                <h5>₹{loading?"__":<>{apiCredits.toLocaleString('en-IN')}</>}</h5>
            </div>
            {/* <div className={styles['wallet']} style={css.border}>
                <p>Product wallet</p>
                <span>₹{productCredits.toLocaleString("en-IN")}</span>
                <span className={`fa fa-refresh ${productCreditLoader && 'fa-spin'}`}  onClick={() => getProductCredits(true)}></span>
            </div> */}
            <div className={styles["wallet"]} style={css.border}>
                <span className={`fa fa-refresh ${productCreditLoader && 'fa-spin'}`} onClick={() => getProductCredits(true)}></span>
                <h6>Product Wallet</h6>
                <h5>₹{loading?"__":<>{productCredits.toLocaleString('en-IN')}</>}</h5>
            </div>
        </div>
    </>)
}