import React, { useEffect, useState} from 'react'
import {useParams, Navigate } from 'react-router-dom';
import oceanapi, {decryptEmail } from '../../Base/OceanConfig';
import styles from "./login.module.css";


export default function ForgotPasswordMailVerify() {
    const token = useParams().token; 
    const [password, setPassword]=useState(false)
    const [status, setStatus] = useState(null);

    const decryptMail =()=>{
        oceanapi.post(decryptEmail, {
            "mailStr":token
        })
        .then(res => {
            if(res.data.code === 200){
                
                sessionStorage.setItem("userId",res.data.userId);
                sessionStorage.setItem("parentName",res.data.parentName);
                setPassword(true)    
            }
            else if (res.data.code === 400) {
                setStatus("token-expired")
            }
            else {
               console.log("error",res.data)
            }
        })
    }
    useEffect(()=>{
        decryptMail()
        // eslint-disable-next-line 
    },[])

  return (
    <>
    <div className={styles["verify-mail"]}>
        {
            status === 'token-expired' && 
            <>
                <img src="../images/times.gif" alt="loading" />
                <h4>Session expired ! Please try again</h4>
            </>
        }
    </div>
     {password &&   <Navigate to="/set-new-password" />}
    </>
  )
}
