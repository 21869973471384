import React, { useState } from 'react';
import { getAdminUser } from '../../../../Utils/Common';
import oceanapi, { smtpDetails } from '../../../../Base/OceanConfig';
import Message from '../../../../Components/Message/Message';
import style from '../../signup.module.css';

export default function SMTP(props){
    const userId = getAdminUser();
    const userName = sessionStorage.getItem("userName");
    const [ port, setPort ] = useState('');
    const [ host, setHost ] = useState('');
    const [ smtpEmail, setSmtpEmail ] = useState('');
    const [ smtpPassword, setSmtpPassword ] = useState('');
    const [ active, setActive ] = useState(true);
    const [ companyName, setCompanyName ] = useState('');
    const [ from, setFrom ] = useState('');
    const [ secureConnection, setSecureConnection ] = useState(true);
    const [ loading, setLoading ] = useState(false);
    const [ message, setMessage ] = useState('');
    const [ messageClass, setMessageClass ] = useState('');

    const smtpData = (e) => {
        e.preventDefault();
        setLoading(true);
        oceanapi.post(smtpDetails, {
            userId: userId,
            host: host,
            port: port,
            secureConnection: secureConnection,
            user: smtpEmail,
            userName: userName,
            companyName: companyName,
            pass: smtpPassword,
            from: from,
            active: active
        })
        .then((res) => {
            setLoading(false);
            if(res.data.code === 200){
                props.changeStep(6);
            } else {
                setMessageClass('error');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('')
                }, 5000);
            }
        })
        .catch((error) => {
            console.error(error);
            setLoading(false);
            setMessageClass('error');
            setMessage("Something went wrong, Try again!.");
            setTimeout(() => {
                setMessage('')
            }, 5000);
        })
    }
    
    return (<>
        {message && <Message message={message} messageClass={messageClass} closeMessage={() => setMessage('')} />}
        <h5 className={style['step-heading']}>SMPT Details</h5>
        <div className={style['smtp-details']}>
            <form onSubmit={smtpData}>
                <div>
                    <label>Host</label>
                    <input type='text' placeholder='Enter Name' value={host} onChange={e => setHost(e.target.value)} required/>
                </div>
                <div>
                    <label>Port</label>
                    <input type='text' placeholder='Enter Port' value={port} onChange={e => setPort(e.target.value)} required/>
                </div>
                <div>
                    <label>User email</label>
                    <input type='text' placeholder='Enter email' value={smtpEmail} onChange={e => setSmtpEmail(e.target.value)} required/>
                </div>
                <div>
                    <label>Password</label>
                    <input type='text' placeholder='Enter password' value={smtpPassword} onChange={e => setSmtpPassword(e.target.value)} required/>
                </div>
                <div>
                    <label>From</label>
                    <input type='text' placeholder='email' value={from} onChange={e => setFrom(e.target.value)} required/>
                </div>
                <div>
                    <label>companyName </label>
                    <input type='text' placeholder='Company Name ' value={companyName } onChange={e => setCompanyName (e.target.value)} required/>
                </div>
                <div>
                    <label>Secure connection</label><br/>
                    <input type="radio" name="option" checked={secureConnection && 'checked'} onClick={() => setSecureConnection(true)}/> Yes &nbsp; &nbsp;
                    <input type="radio" name="option" checked={!secureConnection && 'checked'} onClick={() => setSecureConnection(false)}/> No
                </div>
                <div>
                    <label>Active</label><br/>
                    <input type="radio" name="active" checked={active && 'checked'} onClick={() => setActive(true)}/> Yes &nbsp; &nbsp;
                    <input type="radio" name="active" checked={!active && 'checked'} onClick={() => setActive(false)}/> No
                </div>
                <div className={style['smtp-buttons']}>
                    {
                        loading
                        ?<button className='btn primary-button' disabled>Wait...</button>
                        :<>
                            <button type="submit" className='btn primary-button'>Submit <img src="./images/arrow.svg" alt="arrow"/></button>
                            <button className='btn secondary-button' onClick={() => props.changeStep(6)}>Skip <img src="./images/arrow.svg" alt="arrow"/></button>
                        </>
                    }
                </div>
            </form>
        </div>
    </>);
}