import React, { useState } from 'react';
import style from '../../signup.module.css';

export default function Pricing(props){
    const plan = [
        {
            id: 1,
            heading: 'Standard',
            icon: './images/standard.png',
            subHeading: 'Start generating and emailing new leads, and measuring your success — for free',
            monthlyPrice: 10000,
            yearlyPrice: 8000,
            color: "#ED9630",
            features: [
                {
                    id: 1,
                    name: "Reseller Whitelabelled API Platform",
                    highlighted: false
                },
                {
                    id: 2,
                    name: "Access to 100+ Verification APIs",
                    highlighted: false
                },
                {
                    id: 3,
                    name: "Whitelabel Background Check Solution: Consent and Non-Consent: TruBGC",
                    highlighted: true
                },
                {
                    id: 4,
                    name: ">25% Discount on Standard API pricing",
                    highlighted: false
                },
                {
                    id: 5,
                    name: "Customized Pricing Option for Clients",
                    highlighted: false
                },
                {
                    id: 6,
                    name: "5000 Wallet Credits every month",
                    highlighted: true
                },
                {
                    id: 7,
                    name: "Standard Email and Phone Support",
                    highlighted: false
                },
            ]
        },
        {
            id: 2,
            heading: 'Premium',
            icon: './images/premium.png',
            subHeading: 'Start generating and emailing new leads, and measuring your success — for free',
            monthlyPrice: 20000,
            yearlyPrice: 17000,
            color: "#4733C6",
            features: [
                {
                    id: 1,
                    name: "Everything in Standard Plan",
                    highlighted: false
                },
                {
                    id: 2,
                    name: "Whitelabel Bank Statement Analyser with Stats- TruBank",
                    highlighted: true
                },
                {
                    id: 3,
                    name: "40% Discount on Standard pricing for all products",
                    highlighted: false
                },
                {
                    id: 4,
                    name: ">25% Discount on Standard API pricing",
                    highlighted: false
                },
                {
                    id: 5,
                    name: "Whitelabelled Digital and Adhaar-Based Signing Solution- TruSign",
                    highlighted: false
                },
                {
                    id: 6,
                    name: "10000 Wallet Credits every month",
                    highlighted: false
                },
                {
                    id: 7,
                    name: "Premium Support- 19*7",
                    highlighted: false
                }
            ]
        },
        {
            id: 3,
            heading: 'Pro',
            icon: './images/pro.png',
            subHeading: 'Start generating and emailing new leads, and measuring your success — for free',
            monthlyPrice: 30000,
            yearlyPrice: 21000,
            color: "#A932E0",
            features: [
                {
                    id: 1,
                    name: "Everything in Premium Plan",
                    highlighted: false
                },
                {
                    id: 2,
                    name: "Whitelabel Credit Score Check Solution",
                    highlighted: true
                },
                {
                    id: 3,
                    name: "Whitelabel Court Case Check Solution with Lawyer Signature",
                    highlighted: true
                },
                {
                    id: 4,
                    name: "55% Discount on Standard Pricing",
                    highlighted: false
                },
                {
                    id: 5,
                    name: "15000 Wallet Credits every month",
                    highlighted: true
                },
                {
                    id: 6,
                    name: "24*7 Email and Phone Support",
                    highlighted: false
                },
                {
                    id: 7,
                    name: "Dedicated Account Manager",
                    highlighted: false
                }
            ]
        }
    ]
    const [ planType, setPlanType] = useState('monthly');

    return (<>
        <h5 className={style['step-heading']}>Pricing & payment</h5>
        <h6 className={style['step-subheading']}>Configure a cost estimate that fits your unique business or personal needswith Invincible</h6>
        <div className={style['pricing-payment']}>
            {
                plan.map((item) => (
                    <div key={item.id} style={{border:`1px solid ${item.color}`}}>
                        <h3 className={style['price-heading']}><img src={item.icon} alt={item.heading} /> {item.heading}</h3>
                        <p className={style['price-subheading']}>{item.subHeading}</p>
                        <div className={style['plan-details']}>
                            <p>Start at</p>
                            <h4>Rs {planType === 'yearly' ? item.yearlyPrice.toLocaleString("en-IN") : item.monthlyPrice.toLocaleString("en-IN")} <span>/mo</span></h4>
                            <p>Billed monthly</p>
                        </div>
                        <div className={style['plan-validity']}>
                            <div style={{borderColor: `${planType === 'monthly' ? item.color : '#E7E7E7'}`}} className={style[planType === 'monthly' ? 'selected' : '']} onClick={() => setPlanType('monthly')}>
                                <h6>Pay monthly</h6>
                                <p>Commit monthly</p>
                            </div>
                            <div style={{borderColor: `${planType === 'yearly' ? item.color : '#E7E7E7'}`}} className={style[planType === 'yearly' ? 'selected' : '']} onClick={() => setPlanType('yearly')}>
                                <h6>Pay yearly</h6>
                                <p>Commit monthly</p>
                                <span>Save 10%</span>
                            </div>
                        </div>
                        <div className={style['plan-features']}>
                            <h5>Includes:</h5>
                            {item.features.map((x) => (
                                <p key={x.id} className={style[x.highlighted?'highlighted':'']}><img src='./images/right-tick.png' alt=''/> {x.name}</p>
                            ))}
                        </div>
                        <div className={style['plan-buttons']}>
                            <button style={{color: '#FFFFFF',border: `1px solid ${item.color}`,backgroundColor: `${item.color}`}} onClick={() => props.changeStep(3)}>Buy now</button>
                            <button style={{color: `${item.color}`,border: `1px solid ${item.color}`,backgroundColor: '#FFFFFF'}}>Talk to sales</button>
                        </div>
                    </div>
                ))
            }
        </div>
    </>);
}