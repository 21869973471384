import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './sidebar.module.css';

export default function Sidebar(props) {
	const location = useLocation().pathname;

    return (<>
        <div className={styles["sidebar"]}>
            <ul>
                <li className={styles[location === "/superadmin/dashboard" ? 'active' : '']}>
                    <Link to="/superadmin/dashboard" onClick={() => props.handleShowMenu()}>
                        <i className="fa fa-dashboard"></i>
                        Dashboard
                    </Link>
                </li>
                <li className={styles[location === "/superadmin/clients" ? 'active' : '']}>
                    <Link to="/superadmin/clients" onClick={() => props.handleShowMenu()}>
                        <i className="fa fa-user"></i>
                        Clients
                    </Link>
                </li>
                <li className={styles[location === "/superadmin/test-apis" ? 'active' : '']}>
                    <Link to="/superadmin/test-apis" onClick={() => props.handleShowMenu()}>
                        <i className="fa fa-code"></i>
                        Test API's
                    </Link>
                </li>
                <li className={styles[location === "/superadmin/apis" ? 'active' : '']}>
                    <Link to="/superadmin/apis" onClick={() => props.handleShowMenu()}>
                        <i className="fa fa-file-code-o"></i>
                        API's
                    </Link>
                </li>
                <li className={styles[location === "/superadmin/plans" ? 'active' : '']}>
                    <Link to="/superadmin/plans" onClick={() => props.handleShowMenu()}>
                        <i className="fa fa-file-text"></i>
                        Plans
                    </Link>
                </li>
                <li>
                    <Link to="/superadmin/logout">
                        <i className="fa fa-sign-out"></i>
                        Logout
                    </Link>
                </li>
            </ul>
        </div>
    </>)
}