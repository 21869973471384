import React, { useState, useEffect } from 'react';
import oceanapi, { fetchApiLogs } from '../../../Base/OceanConfig';
import Loading from '../../../Components/Loading/Loading';
import styles from "../user-accounts.module.css";

export default function ApiLogs(props){
    const userId = props.userId;
    const [ logs, setLogs ] = useState([]);
    const [ record, setRecord ] = useState('');
    const [ loading, setLoading ] = useState(true);
    const [ pageNumber, setPageNumber ] = useState(1);
    const defaultPriceList =props.defaultPriceList

    const changeDescription = (x) => {
        const array = defaultPriceList.filter(item => item.services === x);
        if(array.length >= 1){
            return array[0].apiName
        } else {
            return "NA"
        }
    }

    const fetchFunction = () => {
        oceanapi.get(fetchApiLogs + userId + `?pageNumber=${pageNumber}`)
        .then((res) => {
            setLoading(false);
            if(res.data.code === 200){
                setLogs(res.data.records);
                setRecord(res.data.totalRecords);
            } else {
                setLogs([]);
            }
        })
        .catch((error) => {
            setLoading(false);
            console.error(error);
        })
    }

    useEffect(() => {
        fetchFunction();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber])
    
    return (<>
        <div className={styles['api-logs-head']}>
            <p><span>#</span></p>
            <p><span>Date</span></p>
            <p><span>Services</span></p>
            <p><span>Status Code</span></p>
            <p><span>Amount</span></p>
        </div>
        {
            loading
            ?<Loading />
            :<>
                {
                    logs.length > 0
                    ?<>
                        {logs.map((item, i) => (
                            <div className={styles['api-logs-entries']} key={i}>
                                <p><span>#</span>{((pageNumber - 1) * 50) + i + 1}</p>
                                <p><span>Date: </span> {`${new Date(item.createdAt)}`.substr(0, 24)}</p>
                                <p><span>Services: </span>{changeDescription(item.services)}</p>
                                <p className={styles[item.status === 200 ? "green" : "red"]}> <span>Status: </span> {item.status}</p>
                                <p><span>Amount: </span> {item.amount}</p>
                            </div>
                        ))}
                        {Math.ceil(record/50) > 1 &&
                            <div className="pagination-box">
                                {
                                    pageNumber <= 1
                                    ?<span className="fa fa-angle-left pagination-arrow-disabled"></span>
                                    :<span className="fa fa-angle-left pagination-arrow" onClick={() => setPageNumber(pageNumber - 1)}></span>
                                }
                                <span> {pageNumber} of {Math.ceil(record/50)} pages </span>
                                {
                                    pageNumber === Math.ceil(record/50)
                                    ?<span className="fa fa-angle-right pagination-arrow-disabled"></span>
                                    :<span className="fa fa-angle-right pagination-arrow" onClick={() => setPageNumber(pageNumber + 1)}></span>
                                }
                            </div>
                        }
                    </>
                    :<div className="no-records">
                        <p>No logs available</p>
                    </div>
                }
            </>
        }
    </>);
}
