import React, { useState, useEffect, useContext } from 'react';
import AWS from 'aws-sdk';
import { getAdminUser } from '../../../Utils/Common';
import oceanapi, { fetchThemeDetails, updateTheme } from '../../../Base/OceanConfig';
import Message from '../../../Components/Message/Message';
import styles from "../my-account.module.css";
import { ColorContext } from '../../../Components/ColorContext/ColorContext';
const S3_BUCKET = process.env.REACT_APP_BUCKET;
const REGION = process.env.REACT_APP_REGION;

AWS.config.update({
    accessKeyId: process.env.REACT_APP_BUCKET_KEY_ID,
    secretAccessKey: process.env.REACT_APP_BUCKET_ACCESS_KEY
})

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET},
    region: REGION,
})

export default function Theme(props){
    const css = props.css;
    const subdomain = props.subdomain;
    const { setLogo, setPrimaryColor, setSecondaryColor, changeFavicon } = useContext(ColorContext);
    const userId = getAdminUser();
    const [ logoFile, setLogoFile ] = useState('');
    const [ faviconFile, setFaviconFile ] = useState('');
    const [ image, setImage ] = useState('');
    const [ color1, setColor1 ] = useState('');
    const [ color2, setColor2 ] = useState('');
    const [ color3, setColor3 ] = useState('');
    const [ title, setTitle ] = useState('');
    const [ description, setDescription ] = useState('');
    const [ favicon, setFavicon ] = useState('');
    const [ progress, setProgress ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ message, setMessage ] = useState('');
    const [ messageClass, setMessageClass ] = useState('');

    useEffect(() => {
        fetchTheme();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
   
    const fetchTheme = () => {
        oceanapi.get(fetchThemeDetails + subdomain)
        .then((res) => {
            if(res.data.code === 200){
                setLogo(res.data.result.logo);
                setPrimaryColor(res.data.result.theme.primaryColor);
                setSecondaryColor(res.data.result.theme.secondColor);
                changeFavicon(res.data.result.favIcon);
                setImage(res.data.result.logo);
                setColor1(res.data.result.theme.primaryColor);
                setColor2(res.data.result.theme.secondColor);
                setColor3(res.data.result.theme.thirdColor);
                setTitle(res.data.result.title);
                setDescription(res.data.result.description);
                setFavicon(res.data.result.favIcon);
            } else {
                setImage('');
                setColor1('');
                setColor2('');
                setColor3('');
                setTitle('');
                setDescription('');
                setFavicon('');
            }
        })
        .catch((error) => {
            console.error(error);
        })
    }

    const hiddenFileInput = React.useRef(null);
  
    const handleClick = event => {
        hiddenFileInput.current.click();
    };

    const uploadFileInput = React.useRef(null);
  
    const uploadClick = event => {
        uploadFileInput.current.click();
    };

    const checkFileSize = (file, name) => {
        if(file){
            const fileName = file.name.replace(/ /g,"_");
            if(name === 'logo') {
                if(file.size/100000 > 10){
                    setMessageClass('error');
                    setMessage('File size is too large. Please select a file smaller than 1MB.');
                    setTimeout(() => {
                        setMessage('')
                    }, 5000);
                } else {
                    uploadFunction(file, fileName, name);
                }
            } else if(name === 'favicon') {
                if(file.size/10000 > 10){
                    setMessageClass('error');
                    setMessage('File size is too large. Please select a file smaller than 10KB.');
                    setTimeout(() => {
                        setMessage('')
                    }, 5000);
                } else {
                    uploadFunction(file, fileName, name);
                }
            }
        } else {
            setMessageClass('error');
            setMessage('Please select a file');
            setTimeout(() => {
                setMessage('')
            }, 5000);
        }
    }

    const uploadFunction = (file, fileName, name) => {
        let x = Math.random().toString(36).slice(2, 7).toUpperCase();
        const params = {
            ACL: 'public-read',
            ContentType: '.png, .jpg, .jpeg',
            Body: file,
            Bucket: S3_BUCKET,
            Key: x + fileName
        };
        
        myBucket.putObject(params)
        .on('httpUploadProgress', (evt) => {
            setProgress(true);
            if(evt.loaded === evt.total){
                setTimeout(() => {
                    setProgress(false);
                }, 1000)
                if(name === 'logo') {
                    setImage(`https://${S3_BUCKET}.s3.ap-south-1.amazonaws.com/` + params.Key);
                    setLogoFile(file);
                } else if(name === 'favicon') {
                    setFavicon(`https://${S3_BUCKET}.s3.ap-south-1.amazonaws.com/` + params.Key);
                    setFaviconFile(file);
                }
            }
        })
        .send((error) => {
            if (error) 
            console.error(error)
        })
    }

    const updateThemeAdmin = (e) => {
        e.preventDefault();
        setLoading(true);
        oceanapi.post(updateTheme, {
            userId : userId,
            subdomain : subdomain,
            title,
            description,
            logo: image,
            theme: {
                primaryColor: color1,
                secondColor: color2,
                thirdColor : color3
            },
            favIcon: favicon
        })
        .then((res) => {
            setLoading(false);
            if(res.data.code === 200){
                fetchTheme();
                setMessageClass('success');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('')
                }, 5000);
            } else {
                setMessageClass('error');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('')
                }, 5000);
            }
        })
        .catch((error) => {
            console.error(error);
            setLoading(false);
            setMessageClass('error');
            setMessage("Something went wrong, Try again!.");
            setTimeout(() => {
                setMessage('')
            }, 5000);
        })
    }

    return(<>
        {message && <Message message={message} messageClass={messageClass} closeMessage={() => setMessage('')} />}
        {progress && <div className='modal-container'>
            <div className='modal-background'></div>
            <div className='modal-box'>
                <div className={styles['progress-modal']}>
                    <span className="fa fa-refresh fa-spin"></span>
                    <h4>Uploading, Please wait...</h4>
                </div>
            </div>
        </div>}
        <form onSubmit={updateThemeAdmin}>
            <h5 className={styles['my-account-details-heading']}>Theme details</h5>
            <div className='row'>
                <div className='col-xs-12 col-md-12'>
                    <div className={styles['theme-logo']}>
                        <div>
                            <label>Upload logo</label>
                        </div>
                        <div>
                            <label>Preview</label>
                        </div>
                        <div className={styles['select-logo']}>
                            <img src="./images/logo-upload.png" alt="" />
                            {
                                logoFile
                                ?<p><span onClick={handleClick}>Choose another file</span> {logoFile.name}</p>
                                :<p><span onClick={handleClick}>Choose file</span> No file chosen</p>
                            }
                            <input type='file' placeholder='Enter logo url' ref={hiddenFileInput} style={{display:'none'}} accept=".png, .jpg, .jpeg" onChange={(e) => checkFileSize(e.target.files[0], 'logo')}/>
                        </div>
                        <div className={styles['logo-container']}>
                            <img src={image} alt="" />
                        </div>
                    </div>
                </div>
                <div className='col-xs-12 col-md-4'>
                    <label>Primary color</label>
                    <div className={styles['theme-color']}>
                        <input type='color' value={color1} onChange={(e) => setColor1(e.target.value)} required/>
                        <input type='text' placeholder='Enter primary color' value={color1} onChange={(e) => setColor1(e.target.value)} required/>
                    </div>
                </div>
                <div className='col-xs-12 col-md-4'>
                    <label>Secondary color</label>
                    <div className={styles['theme-color']}>
                        <input type='color' value={color2} onChange={(e) => setColor2(e.target.value)} required/>
                        <input type='text' placeholder='Enter secondary color' value={color2} onChange={(e) => setColor2(e.target.value)} required/>
                    </div>
                </div>
                <div className='col-xs-12 col-md-4'>
                    <label>Tertiatry color</label>
                    <div className={styles['theme-color']}>
                        <input type='color' value={color3} onChange={(e) => setColor3(e.target.value)} required/>
                        <input type='text' placeholder='Enter tertiary color' value={color3} onChange={(e) => setColor3(e.target.value)} required/>
                    </div>
                </div>
            </div>
            <div className='row my-3'>
                <div className='col-xs-12 col-md-6'>
                    <label>Title</label>
                    <input type='text' placeholder='Enter Title' value={title} onChange={e => setTitle(e.target.value)} />
                </div>
                <div className='col-xs-12 col-md-6'>
                    <label>Description</label>
                    <input type='text' placeholder='Enter Description' value={description} onChange={e => setDescription(e.target.value)} />
                </div>
                <div className='col-xs-12 col-md-12'>
                    <div className={styles['favicon-uploader']}>
                        <div>
                            <label>Upload favicon</label>
                        </div>
                        <div>
                            <label>Preview</label>
                        </div>
                        <div className={styles['uploading-box']}>
                            <img src="./images/logo-upload.png" alt="" />
                            {
                                faviconFile
                                ?<p><span onClick={uploadClick}>Choose another file</span> {faviconFile.name}</p>
                                :<p><span onClick={uploadClick}>Choose file</span> No file chosen</p>
                            }
                            <input type='file' placeholder='Enter logo url' ref={uploadFileInput} style={{display:'none'}} accept=".png, .jpg, .jpeg" onChange={(e) => checkFileSize(e.target.files[0], 'favicon')}/>
                        </div>
                        <div className={styles['preview-favicon']}>
                            <img src={favicon} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div className='text-right mt-4'>
                {
                    loading
                    ?<button className="btn" style={css.primaryButton} disabled>wait ...</button>
                    :<button type="submit" className="btn" style={css.primaryButton}>Save</button>
                }
            </div>
        </form>
    </>);
}