import React from 'react';
import styles from './message.module.css';

export default function Message(props){
    return (
        <div className={styles["message-container"]}>
            {props.messageClass === 'error' && <div className={styles["error-message-box"]}>
                <div>
                    <img src="../images/circle-times-o.svg" alt="circle-times-o" />
                    <div>
                        <h4>Error!</h4>
                        <h5>{props.message}</h5>
                    </div>
                    <p onClick={props.closeMessage}>Close</p>
                </div>
            </div>}
            {props.messageClass === 'success' && <div className={styles["success-message-box"]}>
                <div>
                    <img src="../images/circle-check-o.svg" alt="circle-check-o" />
                    <div>
                        <h4>Success</h4>
                        <h5>{props.message}</h5>
                    </div>
                    <p onClick={props.closeMessage}>Close</p>
                </div>
            </div>}
        </div>
    );
}