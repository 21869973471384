import React,{useContext, useState, useEffect} from 'react'
import { ColorContext } from '../../Components/ColorContext/ColorContext';
import Message from '../../Components/Message/Message';
import adminapi,{addWhitlabelIp, getIpList, whitelistIpFilter} from '../../Base/AdminConfig';
// import IPDetails from './IPDetails/IPDetails';
import styles from "./whitelistip.module.css"

export default function WhitelistIp() {
    const { css } = useContext(ColorContext);
    const [addModel, setAddModel] = useState(false);
    const [ ip, setIp ] = useState();
    const [ comments, setComments ] = useState();
    const [ userId, setUserId ] = useState();
    const [ data, setData ] = useState([]);
    // const [ detail, setDetail ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ message, setMessage ] = useState("");
    const [ messageClass, setMessageClass ] = useState("");
    const [ filterModal, setFilterModal ] = useState(false);
    const [ filterUserId, setFilterUserId ] = useState("");
    const [ filterIp, setFilterIp ] = useState("");
    const [ filterDate, setFilterDate ] = useState("");
    const [ filterData, setFilterData ] = useState(false);
    const [ filterList, setFilterList ] = useState("");
    const { userName } = useContext(ColorContext);

    useEffect(()=>{
        fetchIpList();
        // eslint-disable-next-line
    },[])

    const fetchIpList =()=>{
        adminapi.get(getIpList +userName)
        .then((res)=>{
            if(res.data.code === 200)
            {
                setData(res.data.result.reverse());
            }
        })
        .catch((error) => {
            setLoading(false);
            console.error(error);
            setMessageClass('error');
            setMessage('Something went wrong, Try again.');
            setTimeout(() => {
                setMessage('');
            }, 5000);
        })
    }

    const addIP = (e) => {   
        e.preventDefault();

        const userIdFormat = /^\d+$/
        if(!userId.match(userIdFormat)){
          
          setMessageClass('error');
          setMessage("User ID contains only numeric values.");
          setTimeout(() => {
              setMessage('');
          }, 5000);
          return 

        }

        const ipformat = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
        if (!ip.match(ipformat))
        {
          setMessageClass('error');
          setMessage("Please enter a valid IP address!");
          setTimeout(() => {
              setMessage('');
          }, 5000);
          return 
        }
        
        setLoading(true);
        adminapi.post(addWhitlabelIp, {
            userId: userId,
            userName:userName,
            ip: ip,
            comments: comments
        })
        .then((res) => {
            setLoading(false);
            if(res.data.code === 200){
                setAddModel(false);
                // fetchIP();
                setMessageClass('success');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('');
                }, 5000);
                fetchIpList();
                setUserId();
                setComments();
                setIp();
            } else {
                setMessageClass('error');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            }
        })
        .catch((error) => {
            setLoading(false);
            console.error(error);
            setMessageClass('error');
            setMessage('Something went wrong, Try again.');
            setTimeout(() => {
                setMessage('');
            }, 5000);
        })
    }


    const filterFunction = (e) => {
      e.preventDefault();
      adminapi.get(whitelistIpFilter + `?userId=${filterUserId}&ip=${filterIp}&updateAt=${filterDate}`)
      .then(res => {
          setFilterData(true);
          if(res.data.code === 200){
          setFilterList(res.data.result.records);
          setFilterModal(false);
          } else {
              setFilterList([]);
          }
      })
      .catch((error) => {
          console.error(error);
      })
    }

    const clearFilter = () => {
      setFilterList([]);
      setFilterModal(false);
      setFilterData(false);
      setFilterDate('');
      setFilterIp('');
      setFilterUserId('');
    }

  return (
    <>
    {message && <Message message={message} messageClass={messageClass} closeMessage={() => setMessage('')}/>}
    {filterModal && 
            <div className={styles['filter-container']}>
                <div className={styles['filter-background']} onClick={() => setFilterModal(false)}></div>
                <div className={styles['filter-form']}>   
                    <div className={styles['filter-icon']}>
                        <span style={css.color}><i class="fa fa-filter"></i>Filter<span><img src="../images/cancel.svg" alt="times" onClick ={() => setFilterModal(false)}/></span></span>
                        <br/>
                        <form onSubmit={filterFunction}>
                            <label>User Id</label>
                            <input type="text" placeholder="User ID" value={filterUserId} onChange={(e) => setFilterUserId(e.target.value)}/>
                            <label>IP</label>
                            <input type="text" placeholder="IP" value={filterIp} onChange={(e) => setFilterIp(e.target.value)}/>
                            <label>Date</label>
                            <input type="date" placeholder="Date" value={filterDate} onChange={(e) => setFilterDate(e.target.value)}/>
                            <p className={styles["clear-filter"]} style={css.color} onClick={() => clearFilter()}>Clear</p>
                            { <button type="submit" className="btn mt-3 px-5" style={css.primaryButton} >Apply</button>}
                        </form>
                    </div>
                </div>
            </div>
      }

    {addModel && 
    <div className={styles["modal-container"]}>
        <div className={styles["modal-background"]} onClick={() => setAddModel(false)}></div>
        <div className={styles["modal-box"]}>
            <form onSubmit={addIP}>
                <div className={styles['image-text']}>
                    <div className={styles["modal-header"]}>
                        Whitelist new IP
                    </div>
                    <div className={styles['cross-image']}>
                        <img src = "../images/cancel.svg" alt=''  onClick={() => setAddModel(false)}/>
                    </div>
                </div>
                <div className={styles["modal-body"]}>
                    <label>User ID</label>
                    <input type="text" value={userId} onChange={(e) => setUserId(e.target.value)} placeholder="Enter User ID" required/>
                    <label>IP</label>
                    <input type="text" value={ip} onChange={(e) => setIp(e.target.value)} placeholder="Enter IP"  required/>
                    <label>Comments</label>
                    <input type="text"  value={comments} onChange={(e) => setComments(e.target.value)} placeholder="Enter Comments" required/>
                </div>
                <div className={styles["modal-footer"]}>
                    <div> 
                    {
                        loading
                            ?<button className="btn" style={css.primaryButton} disabled>Wait...</button>
                            :<button className="btn" style={css.primaryButton} >Add</button>
                    }
                    </div>
                    <div>
                        <button className="btn" style={css.secondaryButton} onClick={() => setAddModel(false)}>Cancel</button>
                    </div>
                </div>
            </form>
        </div>
    </div>}
    {/* {detail && <IPDetails hitResult={result} closeModal={()=>setDetail(false)} />} */}
    <div className="admin-dashboard">
        <h1 className="admin-dashboard-heading">Whitelist IP</h1>
        <div className="admin-dashboard-body">
            <div className={styles["whitelist-filter"]}>
              <div className={styles['whitelist-add']}>
                  <button className="btn" style={css.primaryButton} onClick={()=>setAddModel(true)} > Add new </button>
              </div>
              <div onClick={() => setFilterModal(true)} className={styles['filter-action']} style={css.color}><span><i class="fa fa-filter"></i></span> Filter</div>
            </div>
            <div className={styles['whitelist-box']}>
                <div className={styles['whitelist-head']}>
                        <p>#</p>
                        <p>Updated at</p>
                        <p>User ID</p>
                        <p>IP address</p>
                        <p>Comments</p>
                        {/* <p>Details</p> */}
                </div>
                {filterData?
                <>
                {filterList.length >0?
                  filterList.map((info, i)=>{
                      return (
                      <div className={styles['whitelist-body']} key={i}>       
                          <p>{i + 1} </p>
                          <p>{`${new Date(info.updateAt)}`.substr(0, 24)}</p>
                          <p>{info.userId}</p>
                          <p>{info.ip}</p>
                          <p>{info.comments}</p>

                          {/* set the value of setDetails to true when we get dynamic data for view details */}
                          
                          {/* <p disabled style={css.color} onClick={()=>setDetail(false)} >View Details</p>        */}
                      </div>)
                  })
                  :
                  <div className={styles['no-records']}>
                      <p>No whitelisted IPs found</p>
                  </div>
                  
                  }
                </>
                :<>
                  {data.length >0?
                  data.map((info, i)=>{
                      return (
                      <div className={styles['whitelist-body']} key={i}>       
                          <p><span>#</span>{i + 1} </p>
                          <p><span>Updated at : </span>{`${new Date(info.updateAt)}`.substr(0, 24)}</p>
                          <p><span>User ID : </span>{info.userId}</p>
                          <p><span>IP address : </span>{info.ip}</p>
                          <p><span>Comments : </span>{info.comments}</p>

                          {/* set the value of setDetails to true when we get dynamic data for view details */}
                          
                          {/* <p disabled style={css.color} onClick={()=>setDetail(false)} >View Details</p>        */}
                      </div>)
                  })
                  :
                  <div className={styles['no-records']}>
                      <p>No whitelisted IPs found</p>
                  </div>
                  
                  }
                </>
                }
                
            </div>
        </div>
    </div>
    </>
  )
}
