import React, { useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import styles from "./layout.module.css";

export default function Layout({ children }) {
  const [showMenu, setShowMenu] = useState(false);
  const handleShowMenu = () => {
    setShowMenu(!showMenu);
  };
  return (
    <>
      <div className={styles["dashboard-layout"]}>
        <div className={styles["dashboard-navbar"]}>
          <div className={styles["dashboard-logo"]}>
            <img src="../images/logo-dark.png" alt="" />
            <div
              className={styles["menubar"]}
              onClick={() => setShowMenu(!showMenu)}
            >
              <i
                className={showMenu ? "fa fa-times" : "fa fa-bars"}
                aria-hidden="true"
              ></i>
            </div>
          </div>
        </div>
        <div className={styles["dashboard-body"]}>
          <div className="max-md:hidden">
            <Sidebar handleShowMenu={handleShowMenu} />
          </div>
          {showMenu && (
            <div className="hidden max-md:block absolute left-0 top-0 w-full h-screen grid justify-items-center items-center">
              <div
                className="absolute left-0 top-0 bg-[#00000060] z-[999] w-full h-screen"
                data-testid="background"
                onClick={() => setShowMenu(false)}
              ></div>
              <div className="text-center absolute z-[9999] bg-white w-full  h-full text-center">
                <div className="p-[20px] flex justify-between">
                  <img
                    src="../images/logo-dark.png"
                    alt=""
                    className="w-[200px]"
                  />
                  <div
                    className={styles["menubar"]}
                    onClick={() => setShowMenu(!showMenu)}
                  >
                    <i
                      className={showMenu ? "fa fa-times" : "fa fa-bars"}
                      aria-hidden="true"
                    ></i>
                  </div>
                </div>
                <Sidebar handleShowMenu={handleShowMenu} />
              </div>
            </div>
          )}
          <div className={styles["dashboard-content"]}>{children}</div>
        </div>
      </div>
    </>
  );
}
