import React, { useState, useContext } from 'react';
import { ColorContext } from '../../Components/ColorContext/ColorContext';
import Basic from './Components/Basic';
import Theme from './Components/Theme';
import SMTP from './Components/SMTP';
import TermAndConditions from './Components/TermAndConditions';
import Razorpay from './Components/Razorpay';
import Setting from './Components/Setting';
import styles from "./my-account.module.css";

export default function MyAccount(){
    const { css } = useContext(ColorContext);
    const { subdomain } = useContext(ColorContext);
    const { userName } = useContext(ColorContext);
    const [ tabName, setTabName ] = useState('basic');

    return(
        <div className="admin-dashboard">
            <h1 className="admin-dashboard-heading">My Account</h1>
            <div className="admin-dashboard-body">
                <div className={styles['my-account-details-form']}>
                    <ul className={styles['tabs']}>
                        <li style={tabName === 'basic' ? css.secondaryColor : null} onClick={() => setTabName('basic')}>Basic</li>
                        <li style={tabName === 'theme' ? css.secondaryColor : null} onClick={() => setTabName('theme')}>Theme</li>
                        <li style={tabName === 'smtp' ? css.secondaryColor : null} onClick={() => setTabName('smtp')}>SMTP</li>
                        <li style={tabName === 'term-and-condition' ? css.secondaryColor : null} onClick={() => setTabName('term-and-condition')}>Signup Customization</li>
                        <li style={tabName === 'razorpay' ? css.secondaryColor : null} onClick={() => setTabName('razorpay')}>Razorpay</li>
                        <li style={tabName === 'setting' ? css.secondaryColor : null} onClick={() => setTabName('setting')}>Settings</li>
                    </ul>
                    {tabName === 'basic' && <Basic css={css} userName={userName} subdomain={subdomain} />}
                    {tabName === 'theme' && <Theme css={css} subdomain={subdomain} />}
                    {tabName === 'smtp' && <SMTP css={css} />}
                    {tabName === 'term-and-condition' && <TermAndConditions css={css} userName={userName} subdomain={subdomain}/>}
                    {tabName === 'razorpay' && <Razorpay css={css} subdomain={subdomain} />}
                    {tabName === 'setting' && <Setting css={css} userName={userName} />}
                </div>    
            </div>
        </div>
    );
}