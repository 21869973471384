import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import ApiLogs from './Components/ApiLogs';
import Credits from './Components/Credits';
import APITransactions from './Components/APITransactions';
import ApiPrice1 from "./Components/ApiPrice1";
import Settings from "./Components/Settings"
import oceanapi, { trulogAddressUrl,} from '../../Base/OceanConfig';
import adminapi, { getDefaultPrices } from '../../Base/AdminConfig';
import styles from "./user-accounts.module.css";
import ProductTransactions from './Components/ProductTransactions';
import ProductLogs from './Components/ProductLogs';
import WhitelistIp from './Components/WhiteListIp/WhitelistIp';
import IPHits from './Components/IpHits/IpHits';
import { ColorContext } from '../../Components/ColorContext/ColorContext';

export default function UserDetail() {
    const { css } = useContext(ColorContext);
    const [ trulogAddressName, setTrulogAddressName ] = useState('');
    const [ trulogAddress, setTrulogAddress ] = useState('');
    const [ defaultPriceList, setDefaultPriceList ] = useState([]);
    const [ transactions, setTransaction ] = useState(true);
    const [ credits, setCredits ] = useState(false);
    const [ settings, setSettings ] = useState(false);
    const [ apiLogs, setApiLogs ] = useState (false);
    const [ price, setPrice ] = useState(false);
    const [ ip, setIp ] = useState(false);
    const [ whitelist, setWhitelist ] = useState(false);
    const [ productTransactions, setProductTransactions] = useState(false);
    const [ productLogs, setProductLogs] = useState(false);
    const userDetail = JSON.parse(sessionStorage.getItem('user-detail'));

    const getAddress = () => {
        oceanapi.get(trulogAddressUrl + userDetail.userId)
        .then((res) => {
            if(res.data.code === 200){
                setTrulogAddress(res.data.result);
                setTrulogAddressName(res.data.result.addressName)
            } else {
                // Do nothing
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    const fetchDefaultPrices = () => {
        adminapi.get(getDefaultPrices)
        .then((res) => {
            if(res.data.code === 200){
                setDefaultPriceList(res.data.result);
            }
        })
        .catch((error) => {
            console.error(error);
        })
    }

    useEffect(() => {
        getAddress();
        fetchDefaultPrices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const changeTab = (v1, v2, v3, v4, v5, v6,v7,v8,v9) => {
        setTransaction(v1);
        setApiLogs(v2);
        setProductTransactions(v3);
        setProductLogs(v4);
        setPrice(v5);
        setCredits(v6);
        setIp(v7);
        setWhitelist(v8);
        setSettings(v9);
    }

    return (
        <div className="admin-dashboard">
            <h1 className="admin-dashboard-heading">User Detail</h1>
            <div className="admin-dashboard-body">
                <p className={styles['back-link']}><Link to='/user-accounts' style={css.color}><i className="fa fa-angle-double-left"></i> Back</Link></p>
                <div className={styles['user-detail-main-box']}>
                    <div className={styles['image-content-box']}>
                        <div>
                            <img src='../images/admin/user-account-logo.svg' alt='user-logo' />
                        </div>
                        <div className={styles['user-information']}>
                            <p>User id: <span>{userDetail.userId}</span></p>
                            <p>Name: <span>{userDetail.fullName}</span></p>
                            <p>Email: <span>{userDetail.email}</span></p>
                            <p>Phone no: <span>{userDetail.phone}</span></p>
                            {trulogAddressName && <p>Address name: <span>{trulogAddressName}</span></p>}
                            {trulogAddress.address1 && <p>Address: <span>
                                {trulogAddress.address1?<>{trulogAddress.address1 + ", "}</>:null}
                                {trulogAddress.address2?<>{trulogAddress.address2 + ", "}</>:null}
                                {trulogAddress.address3?trulogAddress.address3:null}
                            </span></p>}
                        </div>
                    </div>
                    <div className={styles['menu-points']}>
                        <p onClick={() => changeTab(true, false, false, false, false, false, false, false, false)} style={transactions?css.active:null}>API Transactions</p>
                        <p onClick={() => changeTab(false, true, false, false, false, false, false, false, false)} style={apiLogs?css.active:null}>API Logs</p>
                        <p onClick={() => changeTab(false, false, true, false, false, false, false, false, false)} style={productTransactions?css.active:null}>Product Transactions</p>
                        <p onClick={() => changeTab(false, false, false, true, false, false, false, false, false)} style={productLogs?css.active:null}>Product Logs</p>
                        <p onClick={() => changeTab(false, false, false, false, true, false, false, false, false)} style={price?css.active:null}>API Price</p>
                        <p onClick={() => changeTab(false, false, false, false, false, true, false, false, false)} style={credits?css.active:null}>Credits</p>
                        <p onClick={() => changeTab(false, false, false, false, false, false, true, false, false)} style={ip?css.active:null}>IP Details</p>
                        <p onClick={() => changeTab(false, false, false, false, false, false, false, true, false)} style={whitelist?css.active:null}>Whitelist IP's</p>
                        <p onClick={() => changeTab(false, false, false, false, false, false, false, false, true)} style={settings?css.active:null}>Settings</p>
                    </div>
                    {transactions && <APITransactions userId={userDetail.userId} defaultPriceList={defaultPriceList} />}
                    {apiLogs && <ApiLogs userId={userDetail.userId} defaultPriceList={defaultPriceList} />}
                    {productTransactions && <ProductTransactions userId={userDetail.userId} defaultPriceList={defaultPriceList} />}
                    {productLogs && <ProductLogs userId={userDetail.userId} defaultPriceList={defaultPriceList} />}
                    {price && <ApiPrice1 userId={userDetail.userId} css={css} defaultPriceList={defaultPriceList} />}
                    {credits && <Credits userId={userDetail.userId} />}
                    {ip && <IPHits userId={userDetail.userId} />}
                    {whitelist && <WhitelistIp userId={userDetail.userId}/>}
                    {settings && <Settings details={userDetail} /> }
                </div>
            </div>
        </div>
    );
}