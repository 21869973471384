import React, { useState, useEffect, useContext } from 'react';
import { getAdminUser } from '../../../Utils/Common';
import Loading from '../../../Components/Loading/Loading';
import oceanapi, { fetchUserApiTransactions} from '../../../Base/OceanConfig';
import { ColorContext } from '../../../Components/ColorContext/ColorContext';
import Message from '../../../Components/Message/Message';
import adminapi, {searchApiTransaction} from '../../../Base/AdminConfig';
import styles from '../user-transactions.module.css';

export default function APITransactions(props) {
    const adminUserId = getAdminUser();
    const [ transactionsList, setTransactionsList ] = useState([]);
    const [ loading, setLoading ] = useState(true);
    const [ totalPages, setTotalPages ] = useState(1);
    const [ pageNumber, setPageNumber ] = useState(1); 
    const { css } = useContext(ColorContext);
    const [ filterModel, setFilterModel] = useState(false);
    const [ filterData, setFilterData ] = useState(false);
    const [ filterList, setFilterList ] = useState([]);
    const [ userId, setUserId ] =useState('');
    const [ email, setEmail ] =useState('');
    const [ pid, setPid ] =useState('');
    const [ date, setDate ] =useState('');
    const [ messageClass, setMessageClass ] =useState('');
    const [ message, setMessage ] = useState("");

    const list =props.list;
    

    const fetchFewTransactionsFunction = (number) => {
        oceanapi.get(fetchUserApiTransactions + `?parentId=${adminUserId}&pageNumber=${number ? number : pageNumber}`)
        .then((res) => {
            setLoading(false);
            if(res.data.code === 200){
                setTransactionsList(res.data.records);
                setTotalPages(Math.ceil(res.data.totalRecords/res.data.pageSize))
                if(number){
                    setPageNumber(number);
                }
            } else {
                setTransactionsList([]);
                setTotalPages(1);
            }
        })
        .catch((error) => {
            console.error(error);
        })
    }

    const changeDescription = (x) => {
        const array = list.filter(item => item.services === x);
        if(array.length >= 1){
            return array[0].apiName
        } else {
            return "NA"
        }
    }

    useEffect(() => {
        fetchFewTransactionsFunction();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber])

    const changePage = (x) => {
        if(x === "next"){
            fetchFewTransactionsFunction(pageNumber + 1);
        } else if(x === "previous") {
            fetchFewTransactionsFunction(pageNumber - 1);
        }
    }

    const filterFunction = (e) => {
        e.preventDefault();
        adminapi.get(searchApiTransaction + `userId=${userId}&email=${email}&PID=${pid}&createdAt=${date}`)
        .then(res => {
            setFilterData(true);
            if(res.data.code === 200){
            setFilterList(res.data.result.records);
            setFilterModel(false);
            setMessageClass('success');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('');
                }, 3000);
            } else {
                setFilterList([]);
            }
        })
        .catch((error) => {
            console.error(error);
        })
      }

    const clearFilter =()=>{
        setUserId('');
        setPid('');
        setEmail('');
        setDate('');
        setFilterData(false);
        setFilterList([]);
        setFilterModel(false);
        fetchFewTransactionsFunction(1);

    }

    return (<>
        {message && <Message message={message} messageClass={messageClass} closeMessage={() => setMessage('')}/>}
        {filterModel && 
            <div className={styles['filter-container']}>
                <div className={styles['filter-background']} onClick={() => setFilterModel(false)}></div>
                <div className={styles['filter-form']}>   
                    <div className={styles['filter-icon']}>
                        <span style={css.color}><i class="fa fa-filter"></i>Filter<span><img src="../images/cancel.svg" alt="times" onClick ={() => setFilterModel(false)}/></span></span>
                        <br/>
                        <form onSubmit={filterFunction}>
                            <label>User ID</label>
                            <input type="text" placeholder="User ID" value={userId} onChange={(e) => setUserId(e.target.value)}/>
                            <label>Email</label>
                            <input type="text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)}/>
                            <label>PID</label>
                            <input type="text" placeholder="PID" value={pid} onChange={(e) => setPid(e.target.value)}/>
                            <label>Date</label>
                            <input type="date" placeholder="Date" value={date} onChange={(e) => setDate(e.target.value)}/>
                            <p className={styles["clear-filter"]} style={css.color} onClick={() => clearFilter()}>Clear</p>
                            { <button type="submit" className="btn mt-3 px-5" style={css.primaryButton} >Apply</button>}
                        </form>
                    </div>
                </div>
            </div>
        }
        {
            loading
            ?<Loading />
            :<div>
                <div className={styles['filter-action']}>  
                    <div style={props.color} onClick={()=>setFilterModel(true)}><span><i class="fa fa-filter"></i></span> Filter</div>
                </div>
                <div className={styles['transactions-box']}>
                    <div className={`${styles['transactions-head']} ${styles['gridHead']}`} >
                        <p>#</p>
                        <div>
                            <p>Date</p>
                            <p>User ID</p>
                            <p>Email</p>
                            <p>Services</p>
                            <p>PID</p>
                            <p>Type</p>
                            <p>Amount</p>
                        </div>
                        
                    </div>
                    {
                        filterData ?
                        <>{ filterList.length > 0 ?
                            <>
                            {filterList.map((item, i) => (
                                <div className={`${styles['transactions-body']} ${styles['gridBody']}`} key={i} >
                                    <p><span>#</span> {((pageNumber - 1) * 50) + i + 1}</p>
                                    <div>
                                        <p><span>Date:</span> {`${new Date(item.createdAt)}`.substr(0, 24)}</p>
                                        <p><span>User ID:</span> {item.userId}</p>
                                        <p><span>Email:</span> {item.email}</p>
                                        <p><span>Services:</span>  {item.amountType === "Credit"? "Credit":changeDescription(item.services)}</p>
                                        <p><span>PID:</span> {item.PID}</p>
                                        <p className={styles[item.status === 200 ? "green" : "red"]}><span>Type:</span> {item.amountType}</p>
                                        <p><span>Amount:</span> ₹{item.amount}</p>
                                    </div>
                                </div>
                            ))}
                            </>
                            :
                            <div className='no-records'>
                                <p><span>No transactions available</span></p>
                            </div>
                        }
                        </>
                        :
                        transactionsList.length >= 1
                        ?<>
                            {transactionsList.map((item, i) => (
                                <div className={`${styles['transactions-body']} ${styles['gridBody']}`} key={i} >
                                    <p><span>#</span> {((pageNumber - 1) * 50) + i + 1}</p>
                                    <div>
                                        <p><span>Date:</span> {`${new Date(item.createdAt)}`.substr(0, 24)}</p>
                                        <p><span>User ID:</span> {item.userId}</p>
                                        <p><span>Email:</span> {item.email}</p>
                                        <p><span>Services:</span> {item.amountType === "Credit"? "Credit":changeDescription(item.services)}</p>
                                        <p><span>PID:</span> {item.PID}</p>
                                        <p className={styles[item.amountType === "Credit" ? "green" : "red"]}><span>Type:</span> {item.amountType}</p>
                                        <p><span>Amount:</span> ₹{item.amountType === "Credit" ? item.remainingAmount?.toLocaleString('en-IN') : item.amount}</p>
                                    </div>
                                </div>
                            ))}
                            {totalPages > 1 &&
                                <div className="pagination-box">
                                    {
                                        pageNumber <= 1
                                        ?<span className="fa fa-angle-left pagination-arrow-disabled"></span>
                                        :<span className="fa fa-angle-left pagination-arrow" onClick={() => changePage('previous')}></span>
                                    }
                                    <span> {pageNumber} of {totalPages} pages </span>
                                    {
                                        pageNumber === totalPages
                                        ?<span className="fa fa-angle-right pagination-arrow-disabled"></span>
                                        :<span className="fa fa-angle-right pagination-arrow" onClick={() => changePage('next')}></span>
                                    }
                                </div>
                            }
                        </>
                        :<div className='no-records'>
                            <p><span>No transactions available</span></p>
                        </div>
                    }
                </div>
            </div>        
        }
    </>);
}