import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAdminUser } from '../../Utils/Common';
import oceanapi, { checkSubDomain, fetchThemeDetails, getdetailsmtp } from '../../Base/OceanConfig';
import Steps from './Components/Steps/Steps';
import Login from './Components/CompleteDetails/Login';
import Pricing from './Components/CompleteDetails/Pricing';
import UpdateDomain from './Components/CompleteDetails/UpdateDomain';
import UpdateTheme from './Components/CompleteDetails/UpdateTheme';
import SMTP from './Components/CompleteDetails/SMTP';
import Congratulations from './Components/CompleteDetails/Congratulations';
import style from './signup.module.css';

export default function CompleteDetails() {
    const navigate = useNavigate();
    const userId = getAdminUser();
    const subdomain = sessionStorage.getItem("subdomain");
    const [ step, setStep ] = useState(1);
    const gotoLogin = () => navigate('/auth');
    const gotoSignup = () => navigate('/signup');
    const changeStep = (x) => setStep(x);

    useEffect (() => {
        if(userId){
            if(subdomain){
                checkSubDomainFunction();
            } else {
                setStep(2);
            }
        } else {
            setStep(1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkSubDomainFunction = () => {
        oceanapi.get(checkSubDomain + userId)
        .then((res) => {
            if(res.data.code === 200){
                fetchTheme();
            } else {
                setStep(3);
            }
        })
        .catch((error) => {
            console.error(error);
        })
    }
   
    const fetchTheme = () => {
        oceanapi.get(fetchThemeDetails + subdomain)
        .then((res) => {
            if(res.data.code === 200){
                getsmtpDetails();
            } else {
                setStep(4);
            }
        })
        .catch((error) => {
            console.log(error);
        })
    }

    const getsmtpDetails = () => {
        oceanapi.get(getdetailsmtp + userId)
        .then((res) => {
            if(res.data.code === 200){
                setStep(6);
            } else {
                setStep(5);
            }
        })
        .catch((error) => {
            console.error(error);
        })
    }

    return (<>
        <section id={style['banner-image']}>
            <div className='container'>
                <div className={style['already-account']}>
                    <div>
                        <img src='./images/logo.png' alt='' />
                    </div>
                    <div>
                        {
                            step === 1
                            ?<p>Don't have an account? <span onClick={() => gotoSignup()}>Signup</span></p>
                            :<p>Go to <span onClick={() => gotoLogin()}>Dashboard</span></p>
                        }
                    </div>
                </div> 
                <div className={style['own-platform']}>
                    <h4>Want to own the platform ?</h4>
                    <h6>Get your own whitelabel platform within less than <span>10 minutes</span></h6>
                </div>
            </div>
        </section>
        <section id={style['signup-form']}>
            <div className='container'>
                <div className={style['details']}>
                    <h2 className={style['whitelabel-heading']}>Signup for whitelabel</h2>
                    {/* Pass step number in props */}
                    <Steps step={step} />
                    {step === 1 && <Login changeStep={changeStep} checkSubDomainFunction={checkSubDomainFunction} />}
                    {step === 2 && <Pricing changeStep={changeStep} />}
                    {step === 3 && <UpdateDomain changeStep={changeStep} />}
                    {step === 4 && <UpdateTheme changeStep={changeStep} />}
                    {step === 5 && <SMTP changeStep={changeStep} />}
                    {step === 6 && <Congratulations />}
                </div>
            </div>
        </section>
    </>);
}