import React, { useEffect } from 'react';
import { removeAdminToken } from '../../Utils/Common';
import styles from "./logout.module.css";

export default function Logout() {
    useEffect(() => {
        removeAdminToken();
        window.location.replace('/auth');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return ( 
        <div className={styles["logout-page"]}>
            <div>
                <h2>logging out</h2>
                <h5>Please wait</h5>
            </div>
        </div>
    );
}
