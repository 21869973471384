import React from 'react'

const FocusCardPrimary = ({imgLink,cardHeading,cardContent}) => {
  return (
    <div className='px-4 pt-4 pb-4 w-64 h-full  bg-primaryColor text-white rounded-md shadow-customCard'>
        <div className="">
            <img src={imgLink} alt="" />
        </div>
        <p className='text-base  font-semibold mt-8 mb-2'>
            {cardHeading}
        </p>
        <p className='text-base font-medium'>
            {cardContent}
        </p>
</div>)
}

export default FocusCardPrimary;