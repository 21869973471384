import React from "react";

const BtnArrowSecondary = ({btnName,action}) => {
  return (
    <div>
      <button className="bg-bgBtnArrowSecondaryColor py-3.5 px-11 rounded" onClick={action} >
        <span className="text-primaryColor font-bold">{btnName}</span>{" "}
        <span className="inline-block ml-3.5">
          <img src="./images/landing_page/arrow-pink.svg" alt="arrow" />
        </span>
      </button>
    </div>
  );
};

export default BtnArrowSecondary;
