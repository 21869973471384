import React, { useState, useEffect, useContext } from 'react';
import oceanapi, { adminPlan, } from '../../Base/OceanConfig';
import adminapi, {getDefaultPrices} from '../../Base/AdminConfig';
import { ColorContext } from '../../Components/ColorContext/ColorContext';
import Loading from '../../Components/Loading/Loading';
import styles from "./my-plan.module.css";

export default function MyPlan(){
    const { userName } = useContext(ColorContext);
    const tabName='api-price';
    const [ planDetails, setPlanDetails ] = useState('');
    const [ defaultPriceList, setDefaultPriceList ] = useState([]);
    const [ loading, setLoading ] = useState(true);
    let count = 1;

    const style = {
        gridColumns: {
            gridTemplateColumns: '40px 1fr 3fr 1fr 50px'
        },
        childGridColumns: {
            gridTemplateColumns: '40px 1fr 50px'
        }
    }

    useEffect(() => {
        if(userName){
            fetchDefaultFunction();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userName])

    const fetchDefaultFunction = () => {
        adminapi.get(getDefaultPrices)
        .then((res) => {
            setLoading(false);
            if(res.data.code === 200){
                setDefaultPriceList(res.data.result);
                fetchPlanDetails();
            } else {
                setDefaultPriceList([]);
            }
        })
        .catch((error) => {
            setLoading(false);
            console.error(error);
        })
    }

    const fetchPlanDetails = () => {
        oceanapi.get(adminPlan + userName)
        .then((res) => {
            setLoading(false);
            if(res.data.code === 200){
                setPlanDetails(res.data.result);
            } else {
                setPlanDetails('no-plan');
            }
        })
        .catch((error) => {
            setLoading(false);
            console.log(error);
        })
    }

    const getCategory = (x) => {
        const filterArray = defaultPriceList.filter(item => item.services === x);
        if(filterArray.length > 0){
            return filterArray[0].category;
        } 
    }

    const getApiName = (x) => {
        const filterArray = defaultPriceList.filter(item => item.services === x);
        if(filterArray.length > 0){
            count ++;
            return filterArray[0].apiName;
        }
    }

    const getPid = (x) => {
        const filterArray = defaultPriceList.filter(item => item.services === x);
        if(filterArray.length > 0){
            return filterArray[0].PID;
        } 
    }
    
    return (<>
        <div className="admin-dashboard">
            <h1 className="admin-dashboard-heading">My Plan</h1>
            <div className="admin-dashboard-body">
                {
                    loading
                    ?<Loading />
                    :<>
                        {
                            planDetails === 'no-plan'
                            ?<>No plan selected yet</>
                            :<>
                                <p className={styles['plan-details']}><span>Type:</span> {planDetails.type}</p>
                                <p className={styles['plan-details']}><span>Last modified:</span> {`${new Date(planDetails.updateAt?planDetails.updateAt:planDetails.createdAt)}`.substr(0, 24)}</p>
                                <ul className={styles['price-tab']}>
                                    {/* <li style={tabName === 'api-price' ? css.active : null} onClick={() => setTabName('api-price')}>API price</li> */}
                                    {/* <li style={tabName === 'product-price' ? css.active : null} onClick={() => setTabName('product-price')}>Product price</li> */}
                                </ul>
                                {tabName === 'api-price' && <>
                                    <div className={styles['plan-head']} style={style.gridColumns}>
                                        <p>#</p>
                                        <p>Category</p>
                                        <p>API name</p>
                                        <p>PID</p>
                                        <p>Price</p>
                                    </div>
                                    {
                                        planDetails.type === 'default' && <>
                                            {
                                                planDetails.default && <>
                                                    {Object.keys(planDetails.default).map((item, i) => (<>
                                                        {getCategory(item) && <div className={styles['plan-body']} key={i} style={style.gridColumns}>
                                                            <p><span>#</span> {count}</p>
                                                            <p><span>Category:</span> {getCategory(item)}</p>
                                                            <p><span>API name:</span> {getApiName(item)}</p>
                                                            <p><span>PID</span> {getPid(item)}</p>
                                                            <p><span>Price:</span> {planDetails.default[item]}</p>
                                                        </div>}
                                                    </>))}
                                                </>
                                            }
                                        </>
                                    }
                                    {
                                        planDetails.type === 'standard' && <>
                                        {
                                            planDetails.standard && <>
                                                {Object.keys(planDetails.standard).map((item, i) => (<>
                                                    {getCategory(item) && <div className={styles['plan-body']} key={i} style={style.gridColumns}>
                                                        <p><span>#</span> {i + 1}</p>
                                                        <p><span>Category:</span> {getCategory(item)}</p>
                                                        <p><span>API name:</span> {getApiName(item)}</p>
                                                        <p><span>PID</span> {getPid(item)}</p>
                                                        <p><span>Price:</span> {planDetails.standard[item]}</p>
                                                    </div>}
                                                </>))}
                                            </>
                                        }
                                        </>
                                    }
                                    {
                                        planDetails.type === 'premium' && <>
                                        {
                                            planDetails.premium && <>
                                                {Object.keys(planDetails.premium).map((item, i) => (<>
                                                    {getCategory(item) && <div className={styles['plan-body']} key={i} style={style.gridColumns}>
                                                        <p><span>#</span> {i + 1}</p>
                                                        <p><span>Category:</span> {getCategory(item)}</p>
                                                        <p><span>API name:</span> {getApiName(item)}</p>
                                                        <p><span>PID</span> {getPid(item)}</p>
                                                        <p><span>Price:</span> {planDetails.premium[item]}</p>
                                                    </div>}
                                                </>))}
                                            </>
                                        }
                                        </>
                                    }
                                    {
                                        planDetails.type === 'pro' && <>
                                        {
                                            planDetails.pro && <>
                                                {Object.keys(planDetails.pro).map((item, i) => (<>
                                                    {getCategory(item) && <div className={styles['plan-body']} key={i} style={style.gridColumns}>
                                                        <p><span>#</span> {i + 1}</p>
                                                        <p><span>Category:</span> {getCategory(item)}</p>
                                                        <p><span>API name:</span> {getApiName(item)}</p>
                                                        <p><span>PID:</span> {getPid(item)}</p>
                                                        <p><span>Price:</span> {planDetails.pro[item]}</p>
                                                    </div>}
                                                </>))}
                                            </>
                                        }
                                        </>
                                    }
                                </>}
                                {tabName === 'product-price' && <>
                                    {planDetails && Object.keys(planDetails.products).map((item, i) => (
                                        <div key={i}>
                                            <div className={styles['product-name']}>{item}</div>
                                            <div className={styles['plan-head']} style={style.childGridColumns}>
                                                <p>#</p>
                                                <p>Service name</p>
                                                <p>Price</p>
                                            </div>
                                            {planDetails && Object.keys(planDetails.products[item]).map((x, j) => (
                                                <div key={j}>
                                                    <div className={styles['plan-body']} style={style.childGridColumns}>
                                                        <p><span>#</span> {j + 1}</p>
                                                        <p><span>Service name:</span> {x}</p>
                                                        <p><span>Price:</span> {planDetails.products[item][x]}</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                </>}
                            </>
                        }
                    </>
                }
            </div>
        </div>
    </>);
}
